import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Heading,
  Container,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";
import { getPolicy } from "../api-services/index";

const PrivacyPolicy = () => {
  const [aboutData, setAboutData] = useState(null); // State for API data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    const fetchAboutContent = async () => {
      try {
        const data = await getPolicy(); // Fetch about content
        if (data?.success) {
          setAboutData(data?.data?.content); // Store data in state
        }
      } catch (error) {
        setError("Failed to load content"); // Set error if the request fails
      } finally {
        setLoading(false); // Disable loading once data is fetched
      }
    };

    fetchAboutContent();
  }, []);

  return (
    <Container maxW="container.xl" py="80px" mt="30px">
      <Heading as="h2" mb={4}>
        Privacy Policy
      </Heading>
      <Box
        pt="15px"
        textAlign="justify"
        dangerouslySetInnerHTML={{ __html: aboutData }} // Dynamically render HTML content
      />
      {/* {sections.map((section, index) => (
        <Box key={index} mb={5}>
          <Heading as="h4" mb={2}>
            {section.title}
          </Heading>
          <UnorderedList spacing={2}>
            {section.points.map((point, idx) => (
              <ListItem key={idx}>
                <Text>{point}</Text>
              </ListItem>
            ))}
          </UnorderedList>
        </Box>
      ))} */}
    </Container>
  );
};

export default PrivacyPolicy;
