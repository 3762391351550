import {
  retrieveUserDetails,
  saveUserDetails,
} from "../middleware/localstorageconfig";
import {
  GET_ADMIN_SUCCESS,
  UPDATE_ADMIN_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  GET_ADMIN_REQUEST,
  GET_ADMIN_FAILURE,
  UPDATE_ADMIN_REQUEST,
  UPDATE_ADMIN_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  GET_FAQ_SUCCESS,
  FORGOT_PASS_SUCCESS,
  SIGNUP_RESET,
  LOGIN_RESET,
} from "./actionTypes";

const retrieveData = retrieveUserDetails("adminauth");
const adminData = retrieveUserDetails("adminData");

// console.log(adminData,"1admindaa")
const initialState = {
  isLoggedIn: retrieveData?.token || "",
  user: adminData.data || {},
  usernameToken: retrieveData?.usernameToken || "",
  adminLayer: adminData?.adminLayer || [],
  error: null,
  message: "",
  isAuthenticated: adminData ? true : false,
  loading: false,
  isSignUp: false,
  forgotPassData: {},
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        loading: false,
        user: action.payload.data,
        error: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoggedIn: false,
        loading: false,
        loginError: action.payload,
      };
    case LOGIN_RESET: // Reset the login state
      return {
        ...state,
        loginError: null, // Reset the error
        loading: false, // Optional: reset loading state
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        error: null,
      };
    case GET_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ADMIN_SUCCESS:
      const updatedData1 = { ...adminData, data: action.payload };
      // saveUserDetails("adminData",updatedData1)
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
        loading: false,
      };
    case GET_ADMIN_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case UPDATE_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_ADMIN_SUCCESS:
      const updatedData = { ...adminData, data: action.payload };
      saveUserDetails("adminData", updatedData);
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    case UPDATE_ADMIN_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case SIGNUP_REQUEST:
      return {
        ...state,
        error: action.payload,
        loading: true,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        isSignUp: true,
        user: action.payload.data,
        error: null,
        loading: false,
      };
    case SIGNUP_FAILURE:
      return {
        ...state,
        isSignUp: false,
        error: action.payload,
        loading: false,
      };
    case SIGNUP_RESET: // Reset the signup state
      return {
        ...state,
        error: null, // Reset the error
        loading: false, // Optional: reset loading state
      };
    case GET_FAQ_SUCCESS:
      return {
        ...state,
        faqData: action.payload,
      };
    case FORGOT_PASS_SUCCESS:
      return {
        ...state,
        forgotPassData: action.payload,
      };
    default:
      return state;
  }
};
