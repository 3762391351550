import React, { useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  Input,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  submitBankDetails,
  fetchBankDetails,
} from "../redux/users-redux/actions";
import SpinnerComponent from "./Spinner";
import PopoverConfirm from "./PopoverConfirm";

const BankDetailsModal = ({ isOpen, onClose }) => {
  console.log(onClose);
  ;
  const dispatch = useDispatch();
  const { bankDetails, bankLoading } = useSelector((state) => state.users);

  // Validation schema
  const validationSchema = Yup.object({
    account_holder_name: Yup.string().required(
      "Account holder name is required"
    ),
    bank_name: Yup.string().required("Bank name is required"),
    account_name: Yup.string().required("Account number is required"),
    ifsc_code: Yup.string().required("IFSC code is required"),
  });

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchBankDetails());
    }
  }, [isOpen, dispatch]);

  // useEffect(() => {
  //   console.log(props);
  // }, [isOpen, dispatch]);

  if (bankLoading) {
    return <SpinnerComponent />;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Bank Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              account_holder_name: bankDetails?.account_holder_name || "",
              bank_name: bankDetails?.bank_name || "",
              account_name: bankDetails?.account_name || "",
              ifsc_code: bankDetails?.ifsc_code || "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              actions.setSubmitting(true);

              // If form is valid, show confirmation popover
              actions.validateForm().then((errors) => {
                if (Object.keys(errors).length === 0) {
                  dispatch(submitBankDetails(values));
                  actions.resetForm();
                  onClose();

                  // ;
                  // No validation errors, show the PopoverConfirm
                  // PopoverConfirm({
                  //   onConfirm: () => {
                  //     dispatch(submitBankDetails(values));
                  //     actions.resetForm();
                  //     onClose();
                  //   },
                  //   message:
                  //     "Are you sure you want to save these bank details?",
                  // });
                } else {
                  // If there are errors, do not show the confirmation popover
                  actions.setSubmitting(false);
                }
              });
            }}
          >
            {(props) => (
              <Form>
                <Stack spacing={3}>
                  <FormControl
                    isInvalid={
                      props.errors.account_holder_name &&
                      props.touched.account_holder_name
                    }
                  >
                    <FormLabel htmlFor="account_holder_name">
                      Account Holder Name
                    </FormLabel>
                    <Field name="account_holder_name">
                      {({ field }) => (
                        <Input
                          {...field}
                          id="account_holder_name"
                          placeholder="Enter Account Holder Name"
                        />
                      )}
                    </Field>
                    <FormErrorMessage>
                      {props.errors.account_holder_name}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={
                      props.errors.bank_name && props.touched.bank_name
                    }
                  >
                    <FormLabel htmlFor="bank_name">Bank Name</FormLabel>
                    <Field name="bank_name">
                      {({ field }) => (
                        <Input
                          {...field}
                          id="bank_name"
                          placeholder="Enter Bank Name"
                        />
                      )}
                    </Field>
                    <FormErrorMessage>
                      {props.errors.bank_name}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={
                      props.errors.account_name &&
                      props.touched.account_name
                    }
                  >
                    <FormLabel htmlFor="account_name">
                      Account Number
                    </FormLabel>
                    <Field name="account_name">
                      {({ field }) => (
                        <Input
                          {...field}
                          id="account_name"
                          type="text" // Changed to text to allow full control
                          placeholder="Enter Account Number"
                          inputMode="numeric" // Ensures numeric keyboard on mobile devices
                          onInput={(e) => {
                            // Allow only digits (0-9)
                            e.target.value = e.target.value.replace(/\D/g, "");
                          }}
                        />
                      )}
                    </Field>
                    <FormErrorMessage>
                      {props.errors.account_name}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      props.errors.ifsc_code && props.touched.ifsc_code
                    }
                  >
                    <FormLabel htmlFor="ifsc_code">IFSC Code</FormLabel>
                    <Field name="ifsc_code">
                      {({ field }) => (
                        <Input
                          {...field}
                          id="ifsc_code"
                          placeholder="Enter IFSC Code"
                        />
                      )}
                    </Field>
                    <FormErrorMessage>
                      {props.errors.ifsc_code}
                    </FormErrorMessage>
                  </FormControl>

                  {/* <Button
                    mt={4}
                    colorScheme="teal"
                    isLoading={props.isSubmitting}
                    onClick={() => props.handleSubmit()}
                  >
                    Save Bank Details
                  </Button> */}
                  <PopoverConfirm
                    onConfirm={props.handleSubmit} // This will trigger submission when confirmed
                    message="Are you sure you want to save these bank details?"
                    isDisabled={!props.isValid} // Disable the popover if the form is invalid
                  >
                    <Button
                      mt={4}
                      colorScheme="teal"
                      isLoading={props.isSubmitting}
                      isDisabled={!props.isValid} // Disable button if the form is invalid
                    >
                      Save Bank Details
                    </Button>
                  </PopoverConfirm>
                </Stack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default BankDetailsModal;
