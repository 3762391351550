import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Heading,
  Divider,
  SimpleGrid,
  Container,
  Text,
} from "@chakra-ui/react";
import { allServices, fetchWishList, topProviders } from "../../api-services";
import WishListProviderCard from "./WishListProviderCards";
import WishListCompanyCard from "./WishListCompanyCards";
import SpinnerComponent from "../../components/Spinner";
import NodataFound from "../../components/NoDataFound";
import AllServicesList from "../../components/AllServicesList";

const WishList = ({ isOpen, onClose }) => {
  const [wishlistfreelancer, setWishlistFreelancer] = useState([]);
  const [wishlistComapany, setWishlistComapany] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isServiceOpen, setIsServiceOpen] = useState(true);
  const [services, setServices] = useState([]);
  const [error, setError] = useState(null);
  const [providers, setProviders] = useState([]);
  const { footerColor, bodyTextColor, primaryText, mediumGray, secondryText } =
    useSelector((state) => state.theme);

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // const fetchData = async () => {
  //   try {
  //     const res = await fetchWishList();
  //     setWishlist(res?.response || []);
  //     setLoading(false);
  //   } catch (err) {
  //     console.log(err.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  // Group 1: Objects with `total_employees` present
  const withTotalEmployees = wishlistComapany?.filter((item) =>
    item.is_favourite === 1
  );

  // Group 2: Objects without `total_employees`
  const withoutTotalEmployees = wishlistfreelancer?.filter(
    (item) => item.is_favourite === 1
  );
  
  console.log('withoutTotalEmployees', withoutTotalEmployees);

  // const withoutTotalEmployees = wishlistComapany?.filter(
  //   (item) => item.is_favourite === 1
  // );



  const fetchServices = async () => {
    try {
      const data = await allServices();
      setServices(data?.response);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchProviders = async () => {
    try {
      setLoading(true);
      const providerType = "freelancer" ;
      const { response } = await topProviders(providerType , 1);
      setWishlistFreelancer(response?.data || []);
      
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };


  const fetchCompany = async () => {
    try {
      setLoading(true);
      const providerType = "company";
      const { response } = await topProviders(providerType, 1);
      setWishlistComapany(response?.data || []);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    fetchProviders();
    fetchServices();
    fetchCompany()
  }, [isServiceOpen]);

  if (loading) return <SpinnerComponent />;

  // If no data in both groups, show the NodataFound component along with text
  // if (withTotalEmployees.length === 0 && withoutTotalEmployees.length === 0) {
  //   return (
  //     <Box className="wishlistPage" my="40px" pt="60px">
  //       <Container maxW="container.xl" p={4} color={footerColor}>
  //         <Text
  //           color={bodyTextColor}
  //           fontWeight="600"
  //           fontSize="24px"
  //           mb="20px"
  //         >
  //           Wishlist
  //         </Text>
  //         <Divider />
  //         <Box py="30px">
  //           <Text
  //             color={bodyTextColor}
  //             fontSize="20px"
  //             textAlign="center"
  //             mb="20px"
  //           ></Text>
  //           <NodataFound
  //             message={"No favorite providers or companies found."}
  //           />
  //         </Box>
  //       </Container>
  //     </Box>
  //   );
  // }


  const getItemList = () => {
    fetchProviders();
    fetchServices();
  };
  const refresh = () => {
    getItemList();
  };

  return (
    <>
      <Box className="forget-password" as="footer" my="40px">
        <Container maxW="container.xl" p={4} color={footerColor}>
          <Box>
            <Text
              color={bodyTextColor}
              fontWeight="600"
              fontSize="24px"
              mt="40px"
            >
              Wishlist
            </Text>
            <Divider />
            {/* Providers Section */}
            <Box py="30px">
              <Heading
                as="h4"
                className="section_title line linegolden"
                mb="40px"
                color={bodyTextColor}
              >
                Favorite Provider
              </Heading>
              <Box>
                {withoutTotalEmployees.length > 0 && !isServiceOpen ? (
                  <SimpleGrid columns={{ base: 1, md: 3, lg: 4 }} gap="10px">
                    {withoutTotalEmployees.map((provider, index) => (
                      <WishListProviderCard
                        key={provider.id}
                        provider={provider}
                        bodyTextColor={bodyTextColor}
                        primaryText={primaryText}
                        footerColor={footerColor}
                        mediumGray={mediumGray}
                        secondryText={secondryText}
                        onClick={getItemList}
                        refresh={refresh}
                      />
                    ))}
                  </SimpleGrid>
                ) : (
                  <NodataFound message={"No favorite providers found."} />
                )}
              </Box>
            </Box>
            <Divider />
            {/* Companies Section */}
            <Box py="30px">
              <Heading
                as="h4"
                className="section_title line linegolden"
                mb="40px"
                color={bodyTextColor}
              >
                Favorite Premiums
              </Heading>
              <Box>
                {withTotalEmployees.length > 0 ? (
                  <SimpleGrid columns={{ base: 1, md: 3, lg: 4 }} gap="10px">
                    {withTotalEmployees.map((company, index) => (
                      <WishListCompanyCard
                        key={company.id}
                        company={company}
                        bodyTextColor={bodyTextColor}
                        primaryText={primaryText}
                        footerColor={footerColor}
                        mediumGray={mediumGray}
                        secondryText={secondryText}
                        refresh={refresh}
                      />
                    ))}
                  </SimpleGrid>
                ) : (
                  <NodataFound message={"No favorite premiums found."} />
                )}
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      {isServiceOpen && (
        <AllServicesList
          isOpen={isServiceOpen}
          onClose={() => setIsServiceOpen(false)}
          // paymentMethod={(e) => selectedPaymentType(e)}
          // selectedProviderData={"freelancer_prices"}
          services={services.length > 0 ? services : []}
        />
      )}
    </>
  );
};

export default WishList;
