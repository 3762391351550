import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
  Text,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { changePassword } from "../api-services/auth";

const ChangePasswordModal = ({ isOpen, onClose }) => {
  const toast = useToast();

  // States to toggle password visibility
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Old password is required"),
      newPassword: Yup.string()
        .min(6, "New password must be at least 6 characters")
        .required("New password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm your new password"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const data = {
        old_password: values.oldPassword,
        password: values.newPassword,
        password_confirmation: values.newPassword,
      };

      try {
        const res = await changePassword(data);
        if (res?.success === false) {
          toast({
            title: "Error",
            description: res?.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Success",
            description: res?.message,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          setTimeout(() => {
            onClose();
          }, 2000);
        }
      } catch (err) {
        toast({
          title: "Error",
          description: err?.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Change Password</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={formik.handleSubmit}>
          <ModalBody>
            {/* Old Password */}
            <FormControl
              id="oldPassword"
              mb={4}
              isInvalid={
                formik.touched.oldPassword && formik.errors.oldPassword
              }
            >
              <FormLabel>Old Password</FormLabel>
              <InputGroup>
                <Input
                  type={showOldPassword ? "text" : "password"}
                  {...formik.getFieldProps("oldPassword")}
                  placeholder="Enter your old password"
                />
                <InputRightElement>
                  <Button
                    variant="ghost"
                    onClick={() => setShowOldPassword(!showOldPassword)}
                  >
                    {showOldPassword ? <ViewOffIcon /> : <ViewIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {formik.touched.oldPassword && formik.errors.oldPassword ? (
                <Text color="red.500">{formik.errors.oldPassword}</Text>
              ) : null}
            </FormControl>

            {/* New Password */}
            <FormControl
              id="newPassword"
              mb={4}
              isInvalid={
                formik.touched.newPassword && formik.errors.newPassword
              }
            >
              <FormLabel>New Password</FormLabel>
              <InputGroup>
                <Input
                  type={showNewPassword ? "text" : "password"}
                  {...formik.getFieldProps("newPassword")}
                  placeholder="Enter your new password"
                />
                <InputRightElement>
                  <Button
                    variant="ghost"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  >
                    {showNewPassword ? <ViewOffIcon /> : <ViewIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {formik.touched.newPassword && formik.errors.newPassword ? (
                <Text color="red.500">{formik.errors.newPassword}</Text>
              ) : null}
            </FormControl>

            {/* Confirm Password */}
            <FormControl
              id="confirmPassword"
              isInvalid={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
            >
              <FormLabel>Confirm New Password</FormLabel>
              <InputGroup>
                <Input
                  type={showConfirmPassword ? "text" : "password"}
                  {...formik.getFieldProps("confirmPassword")}
                  placeholder="Confirm your new password"
                />
                <InputRightElement>
                  <Button
                    variant="ghost"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? <ViewOffIcon /> : <ViewIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {formik.touched.confirmPassword &&
              formik.errors.confirmPassword ? (
                <Text color="red.500">{formik.errors.confirmPassword}</Text>
              ) : null}
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              type="submit"
              isLoading={formik.isSubmitting}
            >
              Submit
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default ChangePasswordModal;
