import React from "react";
import { Box, Image, Container, Text, VStack, Button } from "@chakra-ui/react";
import nodata from "../assets/images/no-data.png";

const NodataFound = ({
  message,
  onRetry,
  notFoundImg,
  notFoundMargin,
  is404,
}) => {
  return (
    <Box
      as="section"
      py="40px"
      bg="gray.50"
      display="flex"
      alignItems="center"
      justifyContent="center"
      minH="60vh"
    >
      <Container
        maxW="container.md"
        textAlign="center"
        boxShadow="lg"
        borderRadius="md"
        bg="white"
        p="6"
        py="10"
        marginTop={notFoundMargin || ""}
      >
        <VStack spacing={6}>
          <Image
            src={notFoundImg ?? nodata}
            maxW="250px"
            objectFit="cover"
            alt="No data found"
            transition="transform 0.3s"
            _hover={{ transform: "scale(1.05)" }}
          />
          <Text color="gray.600" fontSize="2xl" fontWeight="bold">
            {message || "No Data Found"}
          </Text>
          <Text fontSize="lg" color="gray.500">
            {is404
              ? "We couldn’t find any data at the moment. Please check back later or try again."
              : ""}
          </Text>
          {onRetry && (
            <Button
              colorScheme="teal"
              variant="solid"
              size="md"
              onClick={onRetry}
            >
              Retry
            </Button>
          )}
        </VStack>
      </Container>
    </Box>
  );
};

export default NodataFound;
