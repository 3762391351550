import React, { useEffect, useState } from "react";
import { Box, Image } from "@chakra-ui/react";
import { IoIosCamera } from "react-icons/io";
import { CgProfile } from "react-icons/cg";

const ProfileImage = ({ profileDetailsState, onImageChange }) => {
  const [image, setImage] = useState("");
  const userType = localStorage.getItem("user_type");

  useEffect(() => {
    if (userType === "user") {
      setImage(profileDetailsState?.image);
    } else {
      setImage(profileDetailsState?.business_images?.[0]?.business_image);
    }
  }, []);

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    setImage(URL.createObjectURL(selectedFile)); // Create a local URL for the image preview
    onImageChange(selectedFile);
  };

  return (
    <Box
      className="profileBg flexCenter"
      w="100px"
      h="100px"
      bg="#ABABB0"
      borderRadius="100%"
      position="relative"
      border="2px solid rgb(10 51 49)"
      marginBottom="-80px"
    >
      {image ? (
        <Image
          src={image}
          alt="Profile"
          width="100%"
          height="100%"
          objectFit="cover"
          style={{ borderRadius: "50%" }} // To make the image circular
        />
      ) : (
        <CgProfile size="50" color="#ffffff" />
      )}
      <label htmlFor="upload-image">
        <IoIosCamera
          size="20"
          color="#ffffff"
          style={{
            position: "absolute",
            bottom: "0",
            right: "0",
            background: "#ABABB0",
            width: "30px",
            height: "30px",
            padding: "5px",
            borderRadius: "100%",
            border: "1px solid rgb(10 51 49)",
            cursor: "pointer",
          }}
        />
        <input
          id="upload-image"
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleImageChange}
        />
      </label>
    </Box>
  );
};

export default ProfileImage;
