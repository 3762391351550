import React, { useEffect, useState } from "react";
import AboutBanner from "../components/About/Banner";
import {
  Box,
  Text,
  Image,
  Heading,
  SimpleGrid,
  Container,
} from "@chakra-ui/react";
// import about from "../assets/images/about/about.jpg";
import about from "../assets/images/about/about-side.jpg";
import { getAbout } from "../api-services/index";
// Client

const About = () => {
  const [aboutData, setAboutData] = useState(null); // State for API data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    const fetchAboutContent = async () => {
      try {
        const data = await getAbout(); // Fetch about content
        console.log("data==========", data?.data?.content);
        if (data?.success) {
          setAboutData(data?.data?.content); // Store data in state
        }
      } catch (error) {
        setError("Failed to load content"); // Set error if the request fails
      } finally {
        setLoading(false); // Disable loading once data is fetched
      }
    };

    fetchAboutContent();
  }, []);
  return (
    <Box>
      <AboutBanner></AboutBanner>
      <Box>
        <Container maxW="container.xl">
          <Box className="section_spacingY">
            <SimpleGrid columns={{ base: 1, md: 2 }}>
              <Box>
                <Image src={about}></Image>
              </Box>
              <Box
                pl={{ base: "0", md: "70px" }}
                pt={{ base: "0", md: "40px" }}
              >
                <Heading
                  as="h2"
                  fontWeight="bold"
                  className="section_title line linegolden"
                >
                  About Us
                </Heading>
                <Box
                  pt="15px"
                  textAlign="justify"
                  dangerouslySetInnerHTML={{ __html: aboutData }} // Dynamically render HTML content
                />
                {/* <Text pt="15px" textAlign="justify">
                    Welcome to Trider, where excellence in service is our
                    commitment. Our mission is to provide every individual and
                    family with immediate access to a wide range of essential
                    services, whenever and wherever they need them. Whether you
                    are seeking bouncers, event support, relocation aid,
                    traditional or cultural assistance, multitasking help,
                    campaigning support, performance aid, or memorial
                    assistance, Trider is your trusted partner.
                  </Text>
                  <Text pt="15px" textAlign="justify">
                    At Trider, our user-friendly app empowers you to
                    effortlessly book the services you need. With a few simple
                    taps, your request is sent to our network of dedicated
                    professionals who are ready to deliver exceptional service.
                    Once your order is accepted, you can rest assured that it
                    will be handled with the utmost care and professionalism.
                  </Text>
                  <Text pt="15px" textAlign="justify">
                    The inspiration for Trider came from observing the
                    challenges people face in finding reliable local staff. We
                    were driven by the stories of individuals being misled by
                    the black market and felt a strong need to create a
                    trustworthy platform that eradicates these issues. Trider
                    stands as a beacon of reliability and quality, pioneering a
                    service that is unparalleled not just in India, but
                    globally.
                  </Text> */}
              </Box>
            </SimpleGrid>
            {/* <Text pt="15px" textAlign="justify">
              Our core values—integrity, respect, and accountability—are at the
              heart of everything we do. These principles ensure that every
              interaction you have with Trider is marked by transparency,
              dignity, and a steadfast commitment to excellence.
            </Text>
            <Text pt="15px" textAlign="justify">
              At Trider, we take immense pride in the work we do and the
              difference we make in our customers' lives. We are proud to be at
              the forefront of this revolutionary service industry, and we are
              excited to have you join us on this journey.
            </Text>
            <Text pt="15px" fontWeight="bold" textAlign="justify">
              Thank you for choosing Trider. We are honoured to serve you.
            </Text> */}
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default About;
