import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  Box,
  SimpleGrid,
  Heading,
  Container,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
} from "@chakra-ui/react";

import bannerBg from "../../../assets/images/services/bouncers-and-security-bg.jpg";
import BookingPage from "../BookingPage";
import { useSearchParams, useNavigate } from "react-router-dom";
import { topProviders } from "../../../api-services/booking";
import CompanyCard from "../CompanyCard";
import SpinnerComponent from "../../../components/Spinner";
import ProviderDetails from "../CompanyProfile";
import { fetchServiceDetails } from "../../../api-services";
import SearchComponent from "../../../components/SearchAndFilter";
const PopularCompany = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const serviceId = searchParams.get("serviceId");
  const { primaryText, footerColor, mediumGray, secondryText, pageBg } =
    useSelector((state) => state.theme);

  const bannerStyle = {
    backgroundImage: `url(${bannerBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100%",
    width: "100%",
  };

  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [count, setCount] = useState(1);

  const [isBookingModalOpen, setBookingModalOpen] = useState(false);
  const [isProfileModalOpen, setProfileModalOpen] = useState(false);
  const [selectedProviderId, setSelectedProviderId] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedProviderData, setSelectedProviderData] = useState(null);

  const handleOpenBookingModal = (id) => {
    setBookingModalOpen(true);
  };

  const handleCloseBookingModal = () => {
    setBookingModalOpen(false);
  };

  const handleOpenProviderModal = async (id, provider) => {
    // await fetchData();
    setProfileModalOpen(true);
    setSelectedProviderId(id);
    setSelectedProviderData(provider);
  };

  const handleCloseProviderModal = () => {
    setProfileModalOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Fetch freelancers and companies in parallel
        const companyData = await topProviders("company", count);
        const newCompanies = companyData?.response?.data || [];

        // Append new data to existing companies
        console.log("companyData?.response?.data?.success", companyData);
        if (companyData?.success === false) {
          setError(companyData?.message);
          return;
        }
        setCompanies((prevCompanies) => [...prevCompanies, ...newCompanies]);
        setFilteredData((prevCompanies) => [...prevCompanies, ...newCompanies]);
        // setCompanies(companyData?.response?.data || []);
      } catch (err) {
        if (err.message === "Request failed with status code 401") {
          navigate("/login");
        }
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [count]);

  const handleBookNowClick = () => {
    handleOpenBookingModal(true);
    handleCloseProviderModal();
  };
  // Handle search input
  const handleFilter = (filteredData) => {
    setFilteredData(filteredData);
  };

  if (loading && filteredData.length === 0) return <SpinnerComponent />;
  if (error && companies.length === 0) return <div>Error: {error}</div>;

  const uniqueFilteredData = filteredData.reduce((acc, current) => {
    const x = acc.find((item) => item.id === current.id);
    if (!x) {
      acc.push(current);
    }
    return acc;
  }, []);
  return (
    <Box bg={pageBg}>
      <Box className="pageBanner" style={bannerStyle}>
        <Box
          px="20px"
          color={primaryText}
          textAlign="center"
          zIndex="1"
          position="relative"
        >
          <Heading as="h1" py="4">
            Popular Premiums
          </Heading>
        </Box>
      </Box>
      <Container className="companyPagewr" maxW="container.xl" py="80px">
        <Heading mb="30px" fontWeight="700" color="#000">
          Book Your Service Premium
        </Heading>
        <Flex
          alignItems="center"
          gap="10px"
          borderRadius="10px"
          mb="20px"
          p="10px"
        >
          <SearchComponent
            data={companies}
            onFilter={handleFilter}
            searchType={"company"}
          />
        </Flex>
        <SimpleGrid columns={{ base: 1, md: 3, lg: 4 }} gap="10px">
          {uniqueFilteredData?.map((company, index) => (
            <CompanyCard
              key={company.id} // Ensure a unique key
              company={company}
              onClick={() => handleOpenProviderModal(company?.id, company)}
              primaryText={primaryText}
              footerColor={footerColor}
              mediumGray={mediumGray}
              secondryText={secondryText}
            />
          ))}
        </SimpleGrid>
        <Box textAlign="center">
          {!error ? (
            <Button
              className="viewAllBtn"
              onClick={() => setCount((prevCount) => prevCount + 1)}
              isLoading={loading}
            >
              Load more
            </Button>
          ) : (
            <Text color={"red"}>{error}</Text>
          )}
        </Box>

        <BookingPage
          isOpen={isBookingModalOpen}
          onClose={handleCloseBookingModal}
          providerId={selectedProviderId}
        />
        <Modal isOpen={isProfileModalOpen} onClose={handleCloseProviderModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Business Info</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <ProviderDetails providerData={selectedProviderData} />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="green" flex="1" onClick={handleBookNowClick}>
                Book Now
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Container>
    </Box>
  );
};

export default PopularCompany;
