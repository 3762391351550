import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Heading,
  Container,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepTitle,
  StepDescription,
  StepSeparator,
  Button,
  Stack,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Select,
  Checkbox,
  Textarea,
  Link,
  useDisclosure,
  FormErrorMessage,
  Image,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { CgProfile } from "react-icons/cg";
import { IoIosCamera } from "react-icons/io";
import { MdMyLocation } from "react-icons/md";
import { RiMenuAddLine } from "react-icons/ri";
import AddressDetailsModal from "../../../components/AddressDetails";
import { signupCompanyEmployeeAction } from "../../../redux/auth-redux/actions";
import {
  fetchProfileDetails,
  setProfileDetails,
} from "../../../redux/users-redux/actions";
import AadhaarCardSection from "../../../components/AadhaarCardSection";
import { allServices } from "../../../api-services";

import SelectServices from "../../../components/SelectServices";
import LocationProfile from "../../../components/LocationProfile";
import ProfileImage from "../../../components/ProviderProfileImage";

const useStepper = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = React.useState(0);
  const [formData, setFormData] = React.useState({});
  const [addressData, setAddressData] = React.useState({});
  const [addressString, setAddressString] = React.useState("");
  const { profileLoadingState, profileDetailsState } = useSelector(
    (state) => state?.users
  );

  const user_token = localStorage.getItem("token") ?? null;
  React.useEffect(() => {
    const fetchData = async () => {
      if (user_token) {
        await dispatch(fetchProfileDetails());
      }
    };
    fetchData();
    return () => {
      // Cleanup code here if required
    };
  }, [user_token, dispatch]);

  const fullAddressIfExists = profileDetailsState?.business_address
    ? `${profileDetailsState?.business_address?.address_line_one}, ${profileDetailsState?.business_address?.address_line_two}, ${profileDetailsState?.business_address?.city}, ${profileDetailsState?.business_address?.state}, ${profileDetailsState?.business_address?.pincode}`
    : "";

  React.useEffect(() => {
    if (fullAddressIfExists) {
      setAddressString(fullAddressIfExists);
    }
  }, [fullAddressIfExists]);
  const nextStep = async (values) => {
    if (activeStep !== 2) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    let merged;
    if (Object.keys(addressData)?.length) {
      merged = Object.assign(formData, addressData);
    } else if (fullAddressIfExists) {
      const obj = {
        address: profileDetailsState?.business_address?.address_line_one,
        address_line_two:
          profileDetailsState?.business_address?.address_line_two,
        city: profileDetailsState?.business_address?.city,
        state: profileDetailsState?.business_address?.state,
        pincode: profileDetailsState?.business_address?.pincode,
        latitude: profileDetailsState?.business_address?.latitude,
        longitude: profileDetailsState?.business_address?.longitude,
      };
      merged = Object.assign(formData, values, obj);
    } else {
      merged = Object.assign(formData, values);
    }

    setFormData(merged);
    // console.log("formData======", formData);
    // console.log("activeStep======", activeStep);
    if (activeStep === 2) {
      console.log("formData======2", formData);
      const companyId = profileDetailsState?.company_detail?.company_code;
      const res = await dispatch(
        signupCompanyEmployeeAction(formData, companyId)
      );
      if (res.success === true) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        navigate("/");
      }
    }
  };

  const prevStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return {
    activeStep,
    nextStep,
    prevStep,
    setAddressData,
    addressString,
    setAddressString,
    profileDetailsState,
  };
};

const steps = [
  { title: "Basic", description: "Basic Information" },
  { title: "Services", description: "Select Services" },
  { title: "Location", description: "Set Location" },
];

const CompanyEmployeeProfile = () => {
  const {
    activeStep,
    nextStep,
    prevStep,
    setAddressData,
    addressString,
    setAddressString,
    profileDetailsState,
  } = useStepper();
  const { primaryText, footerColor, activeBg } = useSelector(
    (state) => state.theme
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [profileImage, setProfileImage] = useState(profileDetailsState?.image);
  const [profileImageFile, setProfileImageFile] = useState(null);

  const handleAddressSubmit = (addressDetails, currentPosition) => {
    const mergedObj = Object.assign({}, addressDetails, currentPosition);
    setAddressData(mergedObj);
    const fullAddress = `${addressDetails.address}, ${addressDetails.address_line_two}, ${addressDetails.city}, ${addressDetails.state}, ${addressDetails.pincode}`;
    setAddressString(fullAddress);
    onClose();
  };

  const handleImageChange = (newImage) => {
    setProfileImageFile(newImage);
  };
  return (
    <Box className="bussinessProfile_page">
      <Box className="bussinessProfile" mb="60px">
        <Container maxW="container.xl" p={4} color={footerColor}>
          <Box className="profile">
            <Heading as="h4" color={primaryText} fontWeight="700">
              Create Premium Employee Profile
            </Heading>
            <Text
              as="span"
              color={primaryText}
              mb="30px"
              display="inline-block"
            >
              Add details about the service you are offering
            </Text>
            <ProfileImage
              profileDetailsState={profileDetailsState}
              onImageChange={handleImageChange}
            />
          </Box>
        </Container>
      </Box>
      <Box mb="40px">
        <Container maxW="container.xl" p={4} color={footerColor}>
          <Box mb="40px" className="stepser formWr">
            <Stepper index={activeStep} mb="6">
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepIndicator>
                    <StepStatus
                      complete={<CgProfile />}
                      incomplete={<RiMenuAddLine />}
                      active={<CgProfile />}
                    />
                  </StepIndicator>
                  <Box flexShrink="0">
                    <StepTitle>{step.title}</StepTitle>
                    <StepDescription>{step.description}</StepDescription>
                  </Box>
                  {index !== steps?.length - 1 && <StepSeparator />}
                </Step>
              ))}
            </Stepper>
            <StepContent
              activeStep={activeStep}
              nextStep={(values) => nextStep(values)}
              prevStep={prevStep}
              onOpen={onOpen}
              addressString={addressString}
              profileDetailsState={profileDetailsState}
              profileImageFile={profileImageFile}
            />
          </Box>
        </Container>
      </Box>
      <AddressDetailsModal
        isOpen={isOpen}
        onClose={onClose}
        onAddressSubmit={handleAddressSubmit}
        profileDetailsState={profileDetailsState}
      />
    </Box>
  );
};

const StepContent = ({
  activeStep,
  nextStep,
  prevStep,
  onOpen,
  addressString,
  profileDetailsState,
  profileImageFile,
}) => {
  switch (activeStep) {
    case 0:
      return (
        <Basic
          nextStep={nextStep}
          profileDetailsState={profileDetailsState}
          profileImageFile={profileImageFile}
        />
      );
    case 1:
      return (
        <SelectServices
          nextStep={(values) => nextStep(values)}
          prevStep={prevStep}
          profileDetailsState={profileDetailsState}
        />
      );
    case 2:
      return (
        <Location
          prevStep={prevStep}
          onOpen={onOpen}
          nextStep={(values) => nextStep(values)}
          addressString={addressString}
        />
      );
    default:
      return null;
  }
};

const Basic = ({ nextStep, profileDetailsState, profileImageFile }) => {
  const dispatch = useDispatch();
  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    // profile: Yup.mixed().required("Profile image is required"),
    full_name: Yup.string()
      .max(50, "Full name cannot exceed 50 characters")
      .required("Full name is required"),
    business_phone_number: Yup.string()
      .matches(
        /^(\+\d{1,3})?[0-9]{10}$/,
        "Must be a valid 10-digit mobile number"
      )
      .required("Business mobile number is required"),
    email: Yup.string()
      .email("Must be a valid email")
      .required("Email is required"),
    experience: Yup.string().required("Years of experience is required"),
    aadhaarFront: Yup.mixed().required("Front image is required"),
    aadhaarBack: Yup.mixed().required("Back image is required"),
    companyId: Yup.string().required("Premium Unique ID is required"),
  });
  const handleSubmit = (values) => {
    // Format values according to the expected structure
    const formattedValues = {
      business_profile: {
        business_name: values.full_name,
        business_phone_no: values.business_phone_number,
        email: values.email,
        year_of_exp: values.experience,
        front_aadhaar_card: values.aadhaarFront,
        back_aadhaar_card: values.aadhaarBack,
        business_images:values.businessImages
      },
      company_detail: {
        company_code: values?.companyId,
      },
    };

    // Merge formattedValues with existing profileDetailsState
    const mergedValues = {
      ...profileDetailsState,
      business_profile: {
        ...profileDetailsState?.business_profile,
        ...formattedValues.business_profile,
      },
      company_detail: {
        ...profileDetailsState?.company_detail,
        ...formattedValues.company_detail,
      },
    };
    // Dispatch the action with merged values
    dispatch(setProfileDetails(mergedValues));
    // Proceed to next step
    nextStep(formattedValues);
  };
  console.log("profileDetailsState", profileDetailsState);
  return (
    <Formik
      enableReinitialize
      initialValues={{
        // profile: profileDetailsState?.image ?? null, //this needs to check
        full_name: profileDetailsState?.business_profile?.business_name ?? "",
        business_phone_number:
          profileDetailsState?.business_profile?.business_phone_no ||
          profileDetailsState?.phone_number ||
          "",
        email:
          profileDetailsState?.email ??
          profileDetailsState?.business_profile?.email ??
          "",
        experience: profileDetailsState?.business_profile?.year_of_exp ?? "",
        // availability: profileDetailsState?.availability ?? [],
        aadhaarFront:
          profileDetailsState?.business_profile?.front_aadhaar_card ?? null,
        aadhaarBack:
          profileDetailsState?.business_profile?.back_aadhaar_card ?? null,
        businessImages: [],
        companyId: profileDetailsState?.company_detail?.company_code ?? "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        console.log(values);
        // values.image = profileImageFile;
        if (profileImageFile !== undefined) {
          values.businessImages.push(profileImageFile);
        }
        handleSubmit(values); // Call next step on form submission
      }}
    >
      {({ setFieldValue, values, errors, touched }) => (
        <Form>
          <Stack spacing={4}>
            <SimpleGrid columns={{ base: 1, md: 2 }} gap="40px">
              <FormControl isInvalid={touched.full_name && errors.full_name}>
                <FormLabel>Full Name</FormLabel>
                <Field name="full_name">
                  {({ field }) => <Input {...field} type="text" />}
                </Field>
                <FormErrorMessage>{errors.full_name}</FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={
                  touched.business_phone_number && errors.business_phone_number
                }
              >
                <FormLabel>Business Mobile Number</FormLabel>
                <Field name="business_phone_number">
                  {({ field }) => <Input {...field} type="tel" />}
                </Field>
                <FormErrorMessage>
                  {errors.business_phone_number}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={touched.email && errors.email}>
                <FormLabel>Email</FormLabel>
                <Field name="email">
                  {({ field }) => <Input {...field} type="email" />}
                </Field>
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={touched.companyId && errors.companyId}>
                <FormLabel>Premium Id</FormLabel>
                <Field name="companyId">
                  {({ field }) => <Input {...field} type="companyId" />}
                </Field>
                <FormErrorMessage>{errors.companyId}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={touched.experience && errors.experience}>
                <FormLabel>Years of Experience</FormLabel>
                <Field name="experience">
                  {({ field }) => (
                    <Select placeholder="Select experience" {...field}>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="5+">5+</option>
                    </Select>
                  )}
                </Field>
                <FormErrorMessage>{errors.experience}</FormErrorMessage>
              </FormControl>
            </SimpleGrid>
            <FormControl
              isInvalid={touched.aadhaarFront && errors.aadhaarFront}
            >
              <AadhaarCardSection
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                profileDetailsState={profileDetailsState}
              />
            </FormControl>
            <button type="submit" className="btnGreen">
              Next
            </button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

const Location = ({ prevStep, onOpen, nextStep, addressString }) => {
  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    location: Yup.string().required("Location is required"),
  });
  const { error, loading } = useSelector((state) => state.auth);
  return (
    <Formik
      enableReinitialize
      initialValues={{
        location: addressString || "", // Use the address data if available
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        console.log(values);
        nextStep(values);
        // Handle form submission logic here (like making an API call)
      }}
    >
      {({ errors, touched }) => (
        <Form>
          <Stack spacing={4}>
            <FormControl isInvalid={touched.location && errors.location}>
              <FormLabel>Search Location</FormLabel>
              <Field
                as={Input}
                name="location"
                type="text"
                placeholder="Enter your location"
              />
              <FormErrorMessage>{errors.location}</FormErrorMessage>
            </FormControl>

            <Link display="flex" alignItems="center" gap="5px" onClick={onOpen}>
              <MdMyLocation />
              Get Current Location with GPS
            </Link>
            <Text display="flex" alignItems="center" gap="5px" color="red.500">
              <p>{error?.message}</p>
            </Text>
            <Stack direction="row" spacing={4}>
              <Button onClick={prevStep} className="btnGreen">
                Back
              </Button>
              <Button type="submit" className="btnGreen" isLoading={loading}>
                {/* handle button with css to show loading */}
                Submit
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default CompanyEmployeeProfile;
