import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Box,
} from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import { useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

export function DateFilter({ dayFilter, filterFunc }) {
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);

  const onChange = (date) => {
    setStartDate(date);
  };

  const StartdateObj = startDate === null ? "StartDate" : new Date(startDate);

  const options = { month: "long", day: "numeric" };

  const formattedStartDate =
    StartdateObj === "StartDate"
      ? "Select start date"
      : StartdateObj.toLocaleDateString("en-US", options);

  const closeDatePicker = () => {
    setIsOpen(false);
    setStartDate(null);
  };

  const dateISOFunc = (arg) => {
    let timezoneOffset = arg.getTimezoneOffset() * 60000;
    let localISOTime = new Date(arg.getTime() - timezoneOffset)
      .toISOString()
      .split("T")[0];
    return localISOTime;
  };

  const getWeekRange = () => {
    const today = new Date();
    const dayOfWeek = today.getDay(); // Get current day (0 for Sunday, 6 for Saturday)
    const monday = new Date(today);
    monday.setDate(today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)); // Get Monday's date

    return {
      type: "week",
      date_from: dateISOFunc(monday), // Monday
      date_to: dateISOFunc(today), // Today
    };
  };

  const getToday = () => {
    const today = new Date();
    return {
      type: "date",
      date: dateISOFunc(today),
    };
  };

  const getYesterday = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return {
      type: "date",
      date: dateISOFunc(yesterday),
    };
  };

  const getThisMonth = () => {
    const today = new Date();
    return {
      type: "month",
      month: today.getMonth() + 1, // JavaScript months are 0-indexed
      year: today.getFullYear(),
    };
  };

  const getDayFilterLabel = () => {
    if (dayFilter.type === "date") {
      if (moment().isSame(dayFilter.date, "day")) {
        return "Today";
      } else if (moment().subtract(1, "day").isSame(dayFilter.date, "day")) {
        return "Yesterday";
      } else {
        return `Custom - ${moment(dayFilter.date).format("MMM DD, YYYY")}`;
      }
    } else if (dayFilter.type === "week") {
      return "This Week";
    } else if (dayFilter.type === "month") {
      return "This Month";
    } else if (dayFilter === "lifetime") {
      return "Lifetime";
    }
    return "All time";
  };

  return (
    <>
      <Menu>
        <MenuButton as={Button} leftIcon={<CalendarIcon />} variant="outline">
          {dayFilter ? getDayFilterLabel() : "Select Date Range"}
        </MenuButton>
        <MenuList>
          <MenuItem
            onClick={() => {
              filterFunc(getToday());
            }}
          >
            Today
          </MenuItem>
          <MenuItem
            onClick={() => {
              filterFunc(getYesterday());
            }}
          >
            Yesterday
          </MenuItem>
          <MenuItem
            onClick={() => {
              filterFunc(getWeekRange());
            }}
          >
            This Week
          </MenuItem>
          <MenuItem
            onClick={() => {
              filterFunc(getThisMonth());
            }}
          >
            This Month
          </MenuItem>
          <MenuItem
            onClick={() => {
              filterFunc({
                type: "lifetime",
              });
            }}
          >
            Lifetime
          </MenuItem>
          <MenuItem onClick={() => setIsOpen(true)}>Custom</MenuItem>
        </MenuList>
      </Menu>

      <Modal isOpen={isOpen} onClose={closeDatePicker}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Please select a start date</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box borderWidth="1px" p={2} borderRadius="md" textAlign="center">
              {formattedStartDate}
            </Box>
            <Box mt={4}>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                inline
                maxDate={new Date()}
              />
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              onClick={() => {
                const date = dateISOFunc(startDate);
                filterFunc({ type: "date", date });
                closeDatePicker();
              }}
              disabled={startDate === null}
            >
              Get
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default DateFilter;
