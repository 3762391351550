import { API_URL } from "./baseURL";
import axios from "axios";
import moment from "moment";

export const allServices = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${API_URL}/services?param=all`);
    return response?.data;
  } catch (error) {
    throw error;
  }
};
export const getAbout = async () => {
  try {
    const response = await axios.get(`${API_URL}/about-us-content`);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getTermCondition = async () => {
  try {
    const response = await axios.get(`${API_URL}/term-of-condition-content`);
    return response?.data;
  } catch (error) {
    throw error;
  }
};
export const getPolicy = async () => {
  try {
    const response = await axios.get(`${API_URL}/privacy-policy-content`);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const topProviders = async (type, count, dateParam) => {
  try {
    if (count === undefined) {
      count = 1;
    }
    // const token = localStorage.getItem("token");
    // const response = await axios.post(
    //   `${API_URL}/customer/businesses/type=${type}?page=${count}`,
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   }
    // );

    const token = localStorage.getItem("token");
    const serviceId = localStorage.getItem("serviceId");
    const date = dateParam && moment(dateParam); // Replace with your date string or variable
    const formattedDate = date && date?.format("YYYY-MM-DD HH:mm");

    const payload = dateParam !== undefined
      ? {
          // search_type: "business", //for get data by business name
          search_type: "service",
          search_value: Number(serviceId),
          date_time: formattedDate,
        }
      : {
          search_type: "service",
          search_value: Number(serviceId),
        };
    const response = await axios.post(
      `${API_URL}/customer/businesses/type=${type}?page=${count}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const fetchServiceDetails = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${API_URL}/customer/businesses/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};
export const manageHeart = async (business_id) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${API_URL}/customer/favourite_business`,
      {
        business_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(`Error managing heart: ${error.message}`);
  }
};

// wishlist
export const fetchWishList = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${API_URL}/customer/favourite_business`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error managing wishlist: ${error.message}`);
  }
};
// wishlist

export const getAddress = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${API_URL}/customer/address`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error managing heart: ${error.message}`);
  }
};

export const getBusinessSetting = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${API_URL}/business_setting`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error managing heart: ${error.message}`);
  }
};

export const getWallet = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${API_URL}/customer/wallet`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error managing heart: ${error.message}`);
  }
};

export const getProviderWallet = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${API_URL}/provider/wallet`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error managing heart: ${error.message}`);
  }
};

export const getDeleteAddress = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.delete(`${API_URL}/customer/address/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error managing heart: ${error.message}`);
  }
};

export const updateAddressApi = async (id, payload) => {
  try {
    const token = localStorage.getItem("token");
    let response = null;

    if (!id) {
      response = await axios.post(`${API_URL}/customer/address`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      response = await axios.put(`${API_URL}/customer/address/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return response.data;
  } catch (error) {
    throw new Error(`Error managing heart: ${error.message}`);
  }
};
export const postBooking = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${API_URL}/booking`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error managing heart: ${error.message}`);
  }
};

export const postPayment = async (payload, bookingId) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${API_URL}/bookingPayment/${bookingId}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(`Error managing heart: ${error.message}`);
  }
};

export const getCustomerBooking = async (type) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      `${API_URL}/customer/getBookingLists?param=${type}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(`Error managing heart: ${error.message}`);
  }
};

export const SearchProvider = async (payload, type) => {
  try {
    console.log("payload============", payload);

    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${API_URL}/customer/businesses/type=${type}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(`Error managing heart: ${error.message}`);
  }
};
export const getChatList = async () => {
  try {
    const token = localStorage.getItem("token");
    // "75|GS5BOJX8NMblXFG1fLclDGBLS3f1od7CbNhXitnZ9b2f4413";
    //"localStorage.getItem("token");
    const response = await axios.get(`${API_URL}/chatList`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error managing heart: ${error.message}`);
  }
};
export const getMessages = async (chatId) => {
  try {
    const token = localStorage.getItem("token");
    //"75|GS5BOJX8NMblXFG1fLclDGBLS3f1od7CbNhXitnZ9b2f4413";
    //"localStorage.getItem("token");
    const response = await axios.get(`${API_URL}/chat/${chatId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error managing heart: ${error.message}`);
  }
};

export const sendMessage = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${API_URL}/sendMessage`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error managing heart: ${error.message}`);
  }
};

export const withdrawRequest = async (payload) => {
  try {
    console.log("payload============", payload);

    const token = localStorage.getItem("token");
    const response = await axios.post(`${API_URL}/provider/withdraw`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error managing heart: ${error.message}`);
  }
};
