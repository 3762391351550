import { useSelector } from "react-redux";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Box,
  Flex,
  Text,
  Image,
  Heading,
  Container,
  SimpleGrid,
} from "@chakra-ui/react";
// import logo from '../assets/images/logo.png'
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
// import logo from "../TRIDER_squad.png";
import logo from "../trider-logo.png";
// import iconLogo from "../TRIDER_squad-transpaprent.png";

const Footer = () => {
  const { footerBg, primaryText, secondryText, footerColor, activeBg } =
    useSelector((state) => state.theme);
  const location = useLocation();
  useEffect(() => {
    console.log(location);

    window.scrollTo({
      top: 0,
      behavior: "smooth", // This makes the scroll smooth
    });
  }, [location?.pathname]);
  return (
    <Box>
      <Box
        className="footer"
        as="footer"
        // bg={footerBg}
        bg="#ebebeb"
        borderTop="5px solid #1E88E5"
      >
        <Container maxW="container.xl" p={4} color={footerColor} py="40px">
          <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 3 }} gap="30px">
            <Box flex="2">
              <Image
                src={logo}
                margin="0 auto"
                w={{ base: "35px", sm: "50px" }}
                mb="30px"
              />
              {/* <Image src={logo} maxH="140px" mb="30px" /> */}
              <Text color={footerColor} maxW="400px">
                For inquiries and support, please contact us. Our dedicated team
                is committed to providing tailored manpower solutions to meet
                your needs. We're here to assist you every step of the way.
              </Text>
            </Box>
            <Flex color={footerColor} flexDirection="column" flex="1">
              <Heading as="h5" pb="20px" color={primaryText}>
                Links
              </Heading>
              <Link style={{ paddingBottom: "8px" }} as={NavLink} to="/">
                home
              </Link>
              <Link style={{ paddingBottom: "8px" }} as={NavLink} to="/about">
                about us
              </Link>
              {/* {(localStorage.getItem("user_type") === "user" ||
                localStorage.getItem("user_type") === null) && (
                <Link style={{ paddingBottom: "8px" }}>Our Services</Link>
              )} */}
              <Link style={{ paddingBottom: "8px" }} to="/contact">
                Contact Us
              </Link>
              <Link style={{ paddingBottom: "8px" }} as={NavLink} to="/faq">
                FAQ
              </Link>
              <Link
                style={{ paddingBottom: "8px" }}
                as={NavLink}
                to="/privacy-and-policy"
              >
                Privacy policy
              </Link>
              <Link
                style={{ paddingBottom: "8px" }}
                as={NavLink}
                to="/terms-and-conditions"
              >
                terms of service
              </Link>
            </Flex>
            <Flex
              // color={footerColor}
              color="#000"
              flexDirection="column"
              flex="1"
              className="footerContact"
            >
              <Heading as="h5" pb="20px" color={primaryText}>
                Contact
              </Heading>
              <Text fontWeight="600" mb="10px">
                For any support or inquiries, please contact us:
              </Text>
              <Flex alignItems="center" gap="10px" pb="20px" flexShrink="0">
                <FaLocationDot flexShrink="0" />
                Trider Office, Second floor 201, HNo.120/A, Munipally Nilayam
                Road no.5 Madhavpuri hills Pjr enclave Chanda Nagar Hyderabad,
                Telangana, India
              </Flex>
              <Flex alignItems="center" gap="10px" pb="20px">
                <FaPhone size={18} flexShrink="0" />
                +91-8500483413
              </Flex>
              <Flex alignItems="center" gap="10px">
                <MdEmail size={18} flexShrink="0" />
                triderapplication@gmail.com
              </Flex>
            </Flex>
          </SimpleGrid>
        </Container>
        <Box py="5px" borderTop="5px solid #1E88E5" bg={activeBg}>
          <Text
            as="span"
            style={{ color: "#ffffff" }}
            textAlign="center"
            display="inline-block"
            width="100%"
            fontWeight="300"
          >
            © 2024 Trider . All Rights Reserved
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
