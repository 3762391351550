import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Grid,
  GridItem,
  useToast,
  IconButton,
  Flex,
  Box,
} from "@chakra-ui/react";
// import FreelancerCard from "../pages/customer/FreelancerCard"; // Adjust the path as needed
import CompanyCard from "../pages/customer/CompanyCard";
import ProviderDetails from "../pages/customer/CompanyProfile";
import { FaPlus, FaMinus } from "react-icons/fa6";

import { topProviders } from "../api-services/index";
const ProviderComponent = ({
  isOpen,
  onClose,
  type,
  selectProviders,
  totalProvider,
  viewSelected,
  favSelected,
  selectedDate,
}) => {
  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProviderData, setSelectedProviderData] = useState(null);
  const [isProfileModalOpen, setProfileModalOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState(
    type === "company" ? viewSelected?.[0] || [] : viewSelected || []
  );
  const [errorState, setErrorState] = useState(null);
  const {
    primaryText,
    footerColor,
    mediumGray,
    secondryText,
    pageBg,
    blackmedium,
  } = useSelector((state) => state.theme);

  const [empCount, setEmpCount] = useState(
    type === "company" ? viewSelected?.[3] || {} : {}
  );
  const toast = useToast();

  const IncEmpCount = (e, company) => {
    e.stopPropagation();
    const filteredPremiumObject = Object?.fromEntries(
      Object.entries(empCount)?.filter(([key, value]) => value !== 0)
    );
    const empValues = Object?.values(filteredPremiumObject);
    const empkeysId = Object?.keys(filteredPremiumObject); // ['130', '183']
    const totalEmp = empValues?.reduce((acc, curr) => acc + curr, 0);
    const currentCount = empCount[company.id] || 0;
    if (currentCount === 0 && totalProvider !== totalEmp) {
      setSelectedIds((prev) => [...prev, company.id]);
    }

    if (
      currentCount < company?.total_employees &&
      Object.values(empCount).reduce((a, b) => a + b, 0) < totalProvider
    ) {
      setEmpCount({
        ...empCount,
        [company.id]: currentCount + 1,
      });
    } else if (totalEmp === totalProvider) {
      toast({
        title: "Alert",
        description: `You can't select more than ( Manpower - ${totalProvider} ) of total employees .`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Warning",
        description: `Premium has ${company?.total_employees} employees available`,
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const DecEmpCount = (e, company) => {
    e.stopPropagation();
    setEmpCount((prevCounts) => {
      const currentCount = prevCounts[company.id] || 0;

      if (currentCount === 1) {
        setSelectedIds((prev) => prev.filter((id) => id !== company.id));
      }

      if (currentCount > 0) {
        return {
          ...prevCounts,
          [company.id]: currentCount - 1,
        };
      } else {
        toast({
          title: "Warning",
          description: `Premium has ${company?.total_employees} employees available`,
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
        return prevCounts;
      }
    });
  };

  useEffect(() => {
    const fetchProviders = async () => {
      try {
        setLoading(true);

        const providerType = type === "freelancer" ? "freelancer" : "company";
        const dateParam = type === "company" ? selectedDate : undefined;
        const { response } = await topProviders(providerType, 1, dateParam);
        setProviders(response?.data || []);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProviders();
  }, [type]);

  useEffect(() => {
    if (!isNaN(favSelected[0])) {
      setSelectedIds(favSelected);
    }
  }, [favSelected]);

  const handleOpenProviderModal = async (e, id, provider) => {
    setProfileModalOpen(true);
  };

  const handleSelectProvider = async (id, provider) => {
    setSelectedProviderData(provider);
    if (type === "freelancer") {
      handleCheckboxChange(id, provider?.total_employees);
    }
    if (type !== "freelancer") {
      handlePremiumCheckboxChange(id, provider?.total_employees);
    }
  };
  const handleCloseProviderModal = () => {
    setProfileModalOpen(false);
  };
  const handleBookNowClick = (id) => {
    // handleOpenBookingModal(true);
    handleCheckboxChange(selectedProviderData?.id);
    setProfileModalOpen(false);
  };

  const handleCheckboxChange = (companyId) => {

    // If the provider is already selected, allow deselection (unchecking)
    if (selectedIds.includes(companyId)) {
      setSelectedIds((prev) => prev.filter((id) => id !== companyId)); // Deselect the provider
      setErrorState(""); // Clear the error when deselecting
    }
    // If the total selected is less than total providers, allow new selections
    else if (selectedIds.length < totalProvider) {
      setSelectedIds((prev) => [...prev, companyId]); // Select the provider
      setErrorState(""); // Clear the error when selecting
    }
    // If trying to select more than total providers, show error
    else {
      setErrorState(totalProvider ? "" : `Please duration more than 0 `);
    }
  };

  const handlePremiumCheckboxChange = (companyId, count) => {

    const filteredObject = Object.fromEntries(
      Object.entries(empCount).filter(([key, value]) => value !== 0 && value != null)
    );
    const values = Object.values(filteredObject);
    const keys = Object.keys(filteredObject); // ['130', '183']
    const sum = values.reduce((acc, curr) => acc + curr, 0);

    const findCompanyIndex = keys.indexOf(companyId.toString());

    // If the provider is already selected, allow deselection (unchecking)
    if (selectedIds.includes(companyId)) {
      setSelectedIds((prev) => prev.filter((id) => id !== companyId)); // Deselect the provider
      if (sum > 0) {
        setEmpCount((prev) => ({
          ...prev, // Spread the previous state
          [companyId]: 0, // Update the specific companyId's count
        }));
      }
      setErrorState(""); // Clear the error when deselecting
    }
    // If the total selected is less than total providers, allow new selections
    else if (totalProvider > sum && count > 0) {
      setSelectedIds((prev) => [...prev, companyId]); // Select the provider
      if (findCompanyIndex === -1) {
        setEmpCount((prev) => ({
          ...prev, // Spread the previous state
          [companyId]: (prev[companyId] || 0) + 1, // Update the specific companyId's count
        }));
      }
      setErrorState(""); // Clear the error when selecting
    }
    // If trying to select more than total providers, show error
    else {
      setErrorState(totalProvider ? "" : `Please duration more than 0 `);
    }
  };

  // const handleCheckboxChange = (companyId) => {
  //   // If the provider is already selected, allow deselection (unchecking)
  //   if (selectedIds.includes(companyId)) {
  //     setSelectedIds((prev) => prev.filter((id) => id !== companyId)); // Deselect the provider
  //     setErrorState(""); // Clear the error when deselecting
  //   }
  //   // If the total selected is less than total providers, allow new selections
  //   else if (selectedIds.length < totalProvider) {
  //     setSelectedIds((prev) => [...prev, companyId]); // Select the provider
  //     setErrorState(""); // Clear the error when selecting
  //   }
  //   // If trying to select more than total providers, show error
  //   else {
  //     setErrorState(totalProvider ? "" : `Please duration more than 0 `);
  //   }
  // };

  const selectedHandler = () => {

    if (totalProvider === selectedIds?.length) {
      selectProviders(selectedIds);
    } else {
      setErrorState(
        totalProvider
          ? `Please select all ${totalProvider} providers before proceeding. `
          : `Please duration more than 0 `
      );
    }
  };

  const selectPremiumHandler = () => {
    // Filter out entries where value is 0
    const filteredObject = Object.fromEntries(
      Object.entries(empCount).filter(([key, value]) => value !== 0)
    );
    const values = Object.values(filteredObject);
    const keys = Object.keys(filteredObject); // ['130', '183']
    const integerArrayKeys = keys.map(Number);
    const integerArrayValues = values.map(Number);
    const sum = values.reduce((acc, curr) => acc + curr, 0);

    if (totalProvider === sum) {
      selectProviders([
        integerArrayKeys,
        integerArrayValues,
        sum,
        filteredObject,
      ]);
    } else {
      setErrorState(
        totalProvider
          ? `Please select all ${totalProvider} manpower before proceeding. `
          : `Please duration more than 0 `
      );
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent
          maxWidth={{ base: "95%", md: "80%" }}
          maxHeight="95vh"
          mt="auto"
          mb="auto"
        >
          <ModalHeader>
            Select a {type === "freelancer" ? "Standard" : "Premium"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {loading ? (
              <Text>Loading...</Text>
            ) : error ? (
              <Text color="red.500">Error: {error}</Text>
            ) : providers.length > 0 ? (
              <Grid
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                  lg: "repeat(3, 1fr)",
                  xl: "repeat(4, 1fr)",
                }}
                gap={4}
              >
                {" "}
                {/* Adjust the number of columns as needed */}
                {providers?.map((company) => (
                  <GridItem
                    key={company.id}
                    display="flex"
                    alignItems="center"
                    pos="relative"
                  >
                    <input
                      type="checkbox"
                      checked={selectedIds.includes(company.id)}
                      onChange={() =>
                        type === "freelancer"
                          ? handleCheckboxChange(
                              company.id,
                              company?.total_employees
                            )
                          : handlePremiumCheckboxChange(
                              company.id,
                              company?.total_employees
                            )
                      }
                      style={{
                        position: "absolute",
                        top: "20px",
                        zIndex: "1",
                        left: "20px",
                        height: "15px",
                        width: "15px",
                      }}
                    />
                    <CompanyCard
                      company={company}
                      type={type}
                      onClick={() => handleSelectProvider(company?.id, company)}
                      primaryText={primaryText}
                      footerColor={footerColor}
                      mediumGray={mediumGray}
                      secondryText={secondryText}
                      handleOpenModal={() => handleOpenProviderModal()}
                    />
                    {type === "freelancer" ? null : (
                      <Flex
                        gap="5px"
                        // alignItems="baseline"
                        alignItems="flex-start"
                        // borderBottom="1px solid #1f1f1f24"
                        w="100%"
                        pt="8px"
                        justifyContent="center"
                        pos="absolute"
                        bottom="19px"
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          width="50%"
                          alignItems="center"
                        >
                          <IconButton
                            colorScheme={secondryText}
                            color={secondryText}
                            variant="outline"
                            size="sm"
                            aria-label="Search database"
                            icon={<FaPlus />}
                            onClick={(e) => IncEmpCount(e, company)}
                          />
                          <Text color={blackmedium} fontWeight="600">
                            {empCount?.[company.id] || 0}
                          </Text>
                          <IconButton
                            colorScheme={secondryText}
                            color={secondryText}
                            variant="outline"
                            size="sm"
                            aria-label="Search database"
                            icon={<FaMinus />}
                            onClick={(e) => DecEmpCount(e, company)}
                          />
                        </Box>
                      </Flex>
                    )}
                  </GridItem>
                ))}
              </Grid>
            ) : (
              <Text>
                No {type === "freelancer" ? "freelancers" : "companies"}{" "}
                available
              </Text>
            )}
          </ModalBody>
          <ModalFooter>
            {errorState && <Text color="red.500">{errorState}</Text>}{" "}
            {/* Display error below modal footer */}
            <Button
              colorScheme="blue"
              onClick={
                type === "freelancer" ? selectedHandler : selectPremiumHandler
              }
            >
              Done
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* <Modal isOpen={isProfileModalOpen} onClose={handleCloseProviderModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Business Info</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ProviderDetails providerData={selectedProviderData} />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" flex="1" onClick={handleBookNowClick}>
              Select
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal> */}
    </>
  );
};

export default ProviderComponent;
