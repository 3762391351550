import React, { useState } from 'react';
import { useSelector } from "react-redux";
import {
  Box,
  Flex,
  Heading,
  Text,
  Image,
  Button,
  IconButton,
  VStack,
  HStack,
  Avatar,
  useColorModeValue,
  Container,
  Textarea,
  SimpleGrid,
  UnorderedList,
  ListItem,
  Icon,
} from '@chakra-ui/react';
import { StarIcon, ChatIcon } from '@chakra-ui/icons';
import { LuMapPin, LuPhoneCall } from "react-icons/lu";
import { MdOutlineMail } from "react-icons/md";
import { MdCurrencyRupee } from "react-icons/md";
import { GrServicePlay } from "react-icons/gr";
import { FaHandPointRight } from "react-icons/fa";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import silverBadge from "../../assets/images/provider/silver.png";
import goldBadge from "../../assets/images/provider/gold.png";
import platinumBadge from "../../assets/images/provider/platinum.png";
import diamondBadge from "../../assets/images/provider/diamond.png";

const ProviderProfile = ({ providerData }) => {
  const { activeBg, primaryText, rating, acitveColor, darkYellow, redDark, } = useSelector((state) => state.theme);

  const [value, setValue] = useState('');
  const [reviewsToShow, setReviewsToShow] = useState(4);

  const handleInputChange = (e) => {
    setValue(e.target.value);
  };

  const handleLoadMore = () => {
    setReviewsToShow((prev) => prev + 4);
  };

  // Sample review data
  const reviews = [
    { id: 1, name: "John Doe", text: "Great service! The provider was very professional and punctual.", rating: 4 },
    { id: 2, name: "Jane Smith", text: "Very satisfied with the service.", rating: 5 },
    { id: 3, name: "Mike Johnson", text: "Good experience overall.", rating: 4 },
    { id: 4, name: "Anna Williams", text: "Could be better.", rating: 3 },
    { id: 5, name: "David Brown", text: "Excellent service!", rating: 5 },
    { id: 6, name: "Emily Davis", text: "Not bad, but could improve.", rating: 3 },
    { id: 7, name: "Chris Lee", text: "Fantastic!", rating: 5 },
    { id: 8, name: "Sarah Wilson", text: "Will use this service again.", rating: 4 },
  ];

  return (
    <Container className='provider_Profile' maxW="container.md" mx="auto" pt="60px">
      <Flex flexDirection={{ base: "column", md: "row" }} pb="40px" my="60px" boxShadow=" rgba(0, 0, 0, 0.24) 0px 3px 8px">
        <Box w={{ base: '100%' }} flex="0 0 auto">

          <Flex justify="center" textAlign="center" bg={activeBg} py="20px">
            <Box>
              <Box position="relative" display="inline-block" mb="10px">
                <Avatar size="2xl" name="Provider Name" src="https://via.placeholder.com/150" />
                <Image position="absolute" right="-25px" bottom="-25px" maxW="70px" mx="auto" src={silverBadge} alt='badge'></Image>
              </Box>
              <Box>
                <Box>
                  <Text fontWeight="700" fontSize="28px" color={primaryText}>Premium Name</Text>
                  <Flex justifyContent="center">
                    <HStack spacing={1} alignItems="center">
                      <StarIcon color="yellow.400" />
                      <StarIcon color="yellow.400" />
                      <StarIcon color="yellow.400" />
                      <StarIcon color="yellow.400" />
                      <StarIcon color="gray.300" />
                    </HStack>
                    <Text color={primaryText} as="sapn">(123)</Text>
                  </Flex>
                </Box>
              </Box>
            </Box>
          </Flex>
          <Box p={5}>
            <Flex gap="10px" justifyContent="center" textAlign="center" mb="20px">
              <Box>
                <Text fontWeight="600" fontSize="20px">Address</Text>
                <Text fontWeight="400">Los Angeles, California</Text>
              </Box>
            </Flex>
            <Box  mb="20px" mt="15px">
              <Heading as="h5" mt="20px" fontWeight="700" fontSize="24px" color="#28283c">Our Services</Heading>
              <UnorderedList listStyle="none" mt="5px">
                <ListItem display="flex" gap="5px" alignItems="center" fontWeight="500" fontSize="15px" color="#000"><FaHandPointRight />Bouncers and Security</ListItem>
                <ListItem display="flex" gap="5px" alignItems="center" fontWeight="500" fontSize="15px" color="#000"><FaHandPointRight />Bouncers and Security</ListItem>
                <ListItem display="flex" gap="5px" alignItems="center" fontWeight="500" fontSize="15px" color="#000"><FaHandPointRight />Bouncers and Security</ListItem>
              </UnorderedList>
            </Box>
            <Box spacing={4} mb={6} mt="40px">
              <Text fontSize="20px" fontWeight="500" mb="5px">Write a Review</Text>
              <Textarea
                value={value}
                onChange={handleInputChange}
                placeholder='Write Your Review Here'
                size='lg'
              />
              <Box textAlign="right">
                <Button className='viewAllBtn' mt="10px">Submit</Button>
              </Box>
            </Box>
            <Box>
              <Heading fontSize="lg" mb={4}>
                Reviews
              </Heading>
              <VStack spacing={4} align="stretch">
                {reviews.slice(0, reviewsToShow).map((review) => (
                  <Box
                    key={review.id}
                    p={4}
                    boxShadow="sm"
                    borderRadius="md"
                    className='pastreviews'

                  >
                    <Flex gap="10px" alignItems="center">
                      <Avatar size="lg" name={review.name} src="https://via.placeholder.com/150" />
                      <Box>
                        <Text fontSize="md" fontWeight="bold">
                          {review.name}
                        </Text>
                        <HStack spacing={1} alignItems="center">
                          {Array(5).fill('').map((_, i) => (
                            <StarIcon
                              key={i}
                              color={i < review.rating ? 'yellow.400' : 'gray.300'}
                            />
                          ))}
                        </HStack>
                      </Box>
                    </Flex>
                    <Text mt={2}>{review.text}</Text>
                  </Box>
                ))}
              </VStack>

              {reviewsToShow < reviews.length && (
                <Box textAlign="center" mt={4}>
                  <Button className='viewAllBtn' onClick={handleLoadMore} mx="auto">
                    Load More 2
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Flex>
    </Container>
  );
};

export default ProviderProfile;
