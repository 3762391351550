import React from "react";
import { Box, Spinner } from "@chakra-ui/react";

const SpinnerComponent = () => {
  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      width="100vw"
      height="100vh"
      bg="rgba(0, 0, 0, 0.4)" // Semi-transparent background
      display="flex"
      alignItems="center"
      justifyContent="center"
      zIndex="9999" // Ensure it's on top of other content
      backdropFilter="blur(5px)" // Apply blur effect
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgGradient="linear(to-r, blue.400, green.400)" // Gradient background for spinner
        p={4}
        borderRadius="md"
      >
        <Spinner
          size="xl"
          color="white" // Spinner color
        />
      </Box>
    </Box>
  );
};

export default SpinnerComponent;
