import React, { useEffect, useState, useRef } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  useLoadScript,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { Box } from "@chakra-ui/react";

const GoogleMapComponent = ({ onLocationUpdate }) => {
  const mapStyles = {
    height: "400px",
    width: "100%",
  };

  const [markerPosition, setMarkerPosition] = useState({ lat: 0, lng: 0 });
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [info, setInfo] = useState({
    address_line_one: "",
    address_line_two: "",
    city: "",
    state: "",
    pincode: "",
    latitude: null,
    longitude: null,
  });
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [bounds, setBounds] = useState(null);

  const searchBoxRef = useRef(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, // your API key here
    libraries: ["places"], // Include places library
  });

  const success = (position) => {
    const currentLat = position.coords.latitude;
    const currentLng = position.coords.longitude;
    const userLocation = { lat: currentLat, lng: currentLng };
    setMarkerPosition(userLocation);
    setMapCenter(userLocation);
    reverseGeocode(userLocation); // Get address based on current location
  };

  const error = () => {
    console.error("Unable to retrieve your location");
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.error("Geolocation is not supported by your browser");
    }
  }, []); // Run this effect only once on mount

  const reverseGeocode = (position) => {
    if (!isLoaded) return; // Make sure maps API is loaded
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: position }, (results, status) => {
      if (status === "OK" && results[0]) {
        const addressComponents = results[0].address_components;
        let address = "";
        let city = "";
        let state = "";
        let pincode = "";
        let addressLineTwo = "";
        console.log("addressComponents============", addressComponents);
        // addressComponents.forEach((component) => {
        //   if (component.types.includes("street_number")) {
        //     address = component.long_name + " " + address;
        //   }
        //   if (component.types.includes("route")) {
        //     address += component.long_name;
        //   }
        //   if (component.types.includes("locality")) {
        //     city = component.long_name;
        //   }
        //   if (component.types.includes("administrative_area_level_1")) {
        //     state = component.long_name;
        //   }
        //   if (component.types.includes("postal_code")) {
        //     pincode = component.long_name;
        //   }
        //   if (
        //     component.types.includes("point_of_interest") ||
        //     component.types.includes("premise")
        //   ) {
        //     addressLineTwo = component.long_name; // Capture address_line_two
        //   }
        // });

        addressComponents.forEach((component) => {
          if (
            component.types.includes("street_number") ||
            component.types.includes("premise") ||
            component.types.includes("plus_code") ||
            component.types.includes("premise") ||
            component.types.includes("establishment") ||
            component.types.includes("lodging") ||
            component.types.includes("park") ||
            component.types.includes("point_of_interest")
          ) {
            address = component.long_name + " " + address;
          }
          if (component.types.includes("street_number")) {
            address = component.long_name + " " + address;
          }
          if (component.types.includes("route")) {
            address += component.long_name;
          }
          if (
            component.types.includes("neighborhood") ||
            component.types.includes("landmark")
          ) {
            addressLineTwo = component.long_name;
          }
          if (
            component.types.includes("sublocality") ||
            component.types.includes("sublocality_level_1")
          ) {
            if (addressLineTwo === "") {
              addressLineTwo = component.long_name; // Use as address_line_two if neighborhood is missing
            }
          }
          if (component.types.includes("locality")) {
            city = component.long_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
          }
          if (component.types.includes("postal_code")) {
            pincode = component.long_name;
          }
        });

        const updatedInfo = {
          address_line_one: address,
          address_line_two: addressLineTwo,
          city: city,
          state: state,
          pincode: pincode,
          latitude: position.lat,
          longitude: position.lng,
        };

        setInfo(updatedInfo);
        setShowInfoWindow(true);

        if (onLocationUpdate) {
          onLocationUpdate(updatedInfo);
        }
      } else {
        console.error("Geocoder failed due to: " + status);
      }
    });
  };

  const onMapClick = (e) => {
    const position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    setMarkerPosition(position);
    reverseGeocode(position);
  };

  const onMarkerClick = () => {
    setShowInfoWindow(!showInfoWindow);
  };

  const onSBLoad = (ref) => {
    searchBoxRef.current = ref;
  };

  const onPlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    if (places.length > 0) {
      const place = places[0];
      const position = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      setMarkerPosition(position);
      setMapCenter(position);
      reverseGeocode(position);
    }
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading Maps...</div>;
  }

  return (
    <Box position="relative">
      <StandaloneSearchBox
        onLoad={onSBLoad}
        onPlacesChanged={onPlacesChanged}
        bounds={bounds}
      >
        <input
          type="text"
          placeholder="Search for a location"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `240px`,
            height: `32px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
            position: "absolute",
            left: "50%",
            marginLeft: "-120px",
            zIndex: 1000, // Make sure it stays above the map
          }}
        />
      </StandaloneSearchBox>

      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={15}
        center={mapCenter} // Center the map based on user's location
        onClick={onMapClick}
      >
        <Marker position={markerPosition} onClick={onMarkerClick} />

        {showInfoWindow && (
          <InfoWindow
            position={markerPosition}
            onCloseClick={() => setShowInfoWindow(false)}
          >
            <div>
              <p>
                <strong>Address:</strong>{" "}
                {info.address_line_one || "Fetching address..."}
              </p>
              <p>
                <strong>Landmark:</strong>{" "}
                {info.address_line_two || "Fetching address line 2..."}
              </p>
              <p>
                <strong>City:</strong> {info.city || "Fetching city..."}
              </p>
              <p>
                <strong>State:</strong> {info.state || "Fetching state..."}
              </p>
              <p>
                <strong>Pincode:</strong>{" "}
                {info.pincode || "Fetching pincode..."}
              </p>
              <p>
                <strong>Latitude:</strong> {info.latitude}
              </p>
              <p>
                <strong>Longitude:</strong> {info.longitude}
              </p>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </Box>
  );
};

export default React.memo(GoogleMapComponent);
