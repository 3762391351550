import { createStore, applyMiddleware, combineReducers } from "redux";
import { thunk } from "redux-thunk"; // Import redux-thunk correctly
import themeReducer from "./reducer";
import { websiteReducer } from "./switch-web/reducer";
import { authReducer } from "./auth-redux/reducer";
import { usersReducer } from "./users-redux/reducer";
import { bookingsReducer } from "./bookings-redux/reducer";

// Combine reducers
const rootReducer = combineReducers({
  theme: themeReducer,
  website: websiteReducer, // Renamed key to match the reducer name
  auth: authReducer, // Renamed key to match the reducer name
  users: usersReducer,
  bookingsReducer,
});

// Create Redux store with redux-thunk middleware
const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
