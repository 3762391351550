import React, { useState, useEffect } from "react";
import {
  Box,
  VStack,
  Text,
  Container,
  Flex,
  Grid,
  useBreakpointValue,
  Image,
} from "@chakra-ui/react";
import "react-datepicker/dist/react-datepicker.css";
import "chart.js/auto";
import DateFilter from "./DateFilter";
import { getEarnings } from "../api-services/users";
import NodataFound from "./NoDataFound";
import { MdCurrencyRupee } from "react-icons/md";
import { useSelector } from "react-redux";

const EarningsChart = () => {
  const { secondryText } = useSelector((state) => state.theme);

  const [filter, setFilter] = useState({
    type: "lifetime",
  });

  const [earningsData, setEarningsData] = useState([]);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [error, setError] = useState(null);

  useEffect(() => {
    getEarningsData(filter);
  }, [filter]);

  const filterFormsFunc = (val) => {
    console.log("val======", val);

    setFilter(val);
  };

  const getEarningsData = async (val) => {
    setError(null);
    const data = await getEarnings(val);
    if (data?.success) {
      setEarningsData(data?.response);
      setTotalEarnings(data?.total_earning);
    }
    if (!data?.success) {
      setEarningsData([]);
      setTotalEarnings(0);
      const errormsg = data?.message === "User not found" ? "No Record Found" : data?.message
      setError(errormsg);
    }
  };
  return (
    <Container maxW="container.lg" py="60px" px={isMobile ? 4 : 8} mt="60px">
      <VStack spacing={6} align="stretch">
        <Box p={6} boxShadow="lg" borderRadius="lg" bg="white">
          <Flex align="center">
            <Text fontSize="3xl" fontWeight="bold" color="teal.500" w="80%">
              Total Earnings: Rs {totalEarnings}
            </Text>
            <DateFilter dayFilter={filter} filterFunc={filterFormsFunc} />
          </Flex>
        </Box>
        {earningsData?.map((item) => {
          return (
            <Box
              p={6}
              boxShadow="lg"
              borderRadius="lg"
              bg="white"
              key={item?.id}
              maxWidth="700px"
              mx={"auto"}
              w="100%"
            >
              {/* <Image src={item?.service_image} /> */}
              <Flex
                justifyContent="space-between"
                alignItems="center"
                mb="10px"
              >
                <Flex alignItems="center" gap="20px">
                  <Image
                    maxW="100%"
                    w="70px"
                    h="auto"
                    objectFit="cover"
                    src={item?.service_image || "default-image-url"} // Add a fallback image URL if needed
                    alt="Company Image"
                    transition="all .3s"
                    _hover={{
                      transform: "scale(1.1)",
                    }}
                  />
                  <Flex flexDirection="column">
                    <Text fontWeight="700">{item?.service_name}</Text>
                    <Text color={secondryText}>{item?.order_no}</Text>
                  </Flex>
                </Flex>
                <Flex alignItems="center" gap="3px">
                  <MdCurrencyRupee />
                  <Text color={secondryText}>{item?.amount}</Text>
                </Flex>
              </Flex>
              <Text>{item?.booking_date}</Text>
            </Box>
          );
        })}
        {earningsData?.length === 0 && <NodataFound message={error} />}
      </VStack>
    </Container>
  );
};

export default EarningsChart;
