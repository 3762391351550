import React, { useEffect } from "react";
// import HomePage from './pages/Home';
// import SignUp from './pages/SignUp';
// import Login from './pages/Login';
import AllRoute from "./allroute/AllRoute";
import Header from "./components/Header";
import "./App.css";
import Footer from "./components/Footer";

// AOs Animation
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
// import NotificationComponent from "./pages/NotificationComponent";
function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: false, // Trigger animations on both scroll down and scroll up
      offset: 120, // Adjust offset for earlier/later animation trigger
    });

    // Ensure AOS refreshes on page load to trigger animations
    window.addEventListener("load", AOS.refresh);

    return () => {
      window.removeEventListener("load", AOS.refresh);
    };
  }, []);

  return (
    <div>
      <Header></Header>
      <AllRoute></AllRoute>
      <Footer></Footer>
    </div>
  );
}

export default App;
