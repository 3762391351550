import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Heading,
  Container,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";
import { getTermCondition } from "../api-services/index";

const TermsAndConditions = () => {
  const [aboutData, setAboutData] = useState(null); // State for API data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    const fetchAboutContent = async () => {
      try {
        const data = await getTermCondition(); // Fetch about content
        if (data?.success) {
          setAboutData(data?.data?.content); // Store data in state
        }
      } catch (error) {
        setError("Failed to load content"); // Set error if the request fails
      } finally {
        setLoading(false); // Disable loading once data is fetched
      }
    };

    fetchAboutContent();
  }, []);

  // const sections = [
  //   {
  //     title: "1. Introduction",
  //     points: [
  //       'These Terms and Conditions ("Terms") govern your use of the Trider application ("App") and the services provided through it ("Services"). By accessing or using the App, you agree to comply with these Terms.',
  //       "If you do not agree with these Terms, please do not use the App.",
  //     ],
  //   },
  //   {
  //     title: "2. Eligibility",
  //     points: [
  //       "You must be at least 18 years old to use the App.",
  //       "By using the App, you represent and warrant that you have the legal capacity to enter into a binding agreement.",
  //     ],
  //   },
  //   {
  //     title: "3. Account Registration",
  //     points: [
  //       'To use certain features of the App, you must register for an account ("Account").',
  //       "You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.",
  //       "You are responsible for safeguarding your Account login information and for all activities that occur under your Account.",
  //     ],
  //   },
  //   {
  //     title: "4. Services",
  //     points: [
  //       "Trider provides a platform for users to book various services, including but not limited to bouncers, event support, relocation aid, traditional or cultural assistance, multitasking help, campaigning support, performance aid, and memorial assistance.",
  //       'Services are provided by independent service professionals ("Service Providers") who are not employees of Trider.',
  //       "Trider does not guarantee the availability, quality, or suitability of any Service Provider. You acknowledge and agree that your use of the Services is at your own risk.",
  //     ],
  //   },
  //   {
  //     title: "5. Booking and Payments",
  //     points: [
  //       "All bookings must be made through the App.",
  //       "You agree to pay all applicable fees and charges for the services you book through the App.",
  //       "Payment must be made through the payment methods available in the App.",
  //       "Trider uses third-party payment processors to process payments. You agree to comply with the terms and conditions of the third-party payment processors.",
  //     ],
  //   },
  //   {
  //     title: "6. Cancellations and Refunds",
  //     points: [
  //       "You may cancel a booking before it is accepted by a Service Provider without any charges.",
  //       "If you cancel a booking after it has been accepted, you may be charged a cancellation fee as specified in the App.",
  //       "Refunds are subject to Trider’s refund policy, which is available in the App.",
  //     ],
  //   },
  //   {
  //     title: "7. User Conduct",
  //     points: [
  //       "You agree to use the App and Services in compliance with all applicable laws and regulations.",
  //       "You agree not to use the App for any illegal or unauthorized purpose.",
  //       "You agree not to post or transmit any content that is harmful, offensive, or otherwise objectionable.",
  //       "You agree not to interfere with or disrupt the integrity or performance of the App or Services.",
  //       "You agree not to attempt to gain unauthorized access to the App or Services.",
  //     ],
  //   },
  //   {
  //     title: "8. Service Provider Conduct",
  //     points: [
  //       "Service Providers are required to adhere to Trider’s code of conduct and standards.",
  //       "Trider reserves the right to suspend or terminate the Account of any Service Provider who violates these Terms or engages in misconduct.",
  //     ],
  //   },
  //   {
  //     title: "9. Limitation of Liability",
  //     points: [
  //       "Trider is not responsible for any damages or losses arising from your use of the App or Services.",
  //       "To the fullest extent permitted by law, Trider disclaims all warranties, express or implied, including but not limited to, warranties of merchantability, fitness for a particular purpose, and non-infringement.",
  //       "Trider’s total liability to you for any claims arising out of or related to these Terms or your use of the App or Services is limited to the amount paid by you for the services in the 12 months preceding the event giving rise to the claim.",
  //     ],
  //   },
  //   {
  //     title: "10. Indemnification",
  //     points: [
  //       "You agree to indemnify and hold harmless Trider, its affiliates, and their respective officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including legal fees, arising out of or in any way connected with your use of the App or Services, your violation of these Terms, or your violation of any rights of another.",
  //     ],
  //   },
  //   {
  //     title: "11. Privacy",
  //     points: [
  //       "Your use of the App and Services is subject to Trider’s Privacy Policy, which is available in the App.",
  //     ],
  //   },
  //   {
  //     title: "12. Modifications to Terms",
  //     points: [
  //       "Trider reserves the right to modify these Terms at any time. Any changes will be effective immediately upon posting the updated Terms in the App.",
  //       "Your continued use of the App or Services after any changes to these Terms constitutes your acceptance of the revised Terms.",
  //     ],
  //   },
  //   {
  //     title: "13. Termination",
  //     points: [
  //       "Trider may terminate or suspend your Account and access to the App or Services at any time, without prior notice or liability, for any reason, including if you breach these Terms.",
  //       "Upon termination, your right to use the App and Services will immediately cease.",
  //     ],
  //   },
  //   {
  //     title: "14. Governing Law",
  //     points: [
  //       "These Terms are governed by and construed in accordance with the laws of India, without regard to its conflict of law principles.",
  //       "Any disputes arising out of or related to these Terms or your use of the App or Services will be subject to the exclusive jurisdiction of the courts of [City], India.",
  //     ],
  //   },
  //   {
  //     title: "15. Intellectual Property",
  //     points: [
  //       "The App and all content and materials within it, including but not limited to text, graphics, logos, and software, are the property of Trider or its licensors and are protected by intellectual property laws.",
  //       "You may not reproduce, distribute, modify, or create derivative works from any content or materials on the App without prior written consent from Trider.",
  //     ],
  //   },
  //   {
  //     title: "16. Feedback and Suggestions",
  //     points: [
  //       "If you provide any feedback or suggestions to Trider, you grant Trider a worldwide, perpetual, irrevocable, royalty-free license to use and incorporate such feedback and suggestions into the App and Services.",
  //     ],
  //   },
  //   {
  //     title: "17. Force Majeure",
  //     points: [
  //       "Trider will not be liable for any failure or delay in performing its obligations under these Terms due to circumstances beyond its reasonable control, including but not limited to acts of God, natural disasters, war, terrorism, strikes, and other labor disputes.",
  //     ],
  //   },
  //   {
  //     title: "18. Third-Party Links",
  //     points: [
  //       "The App may contain links to third-party websites or services that are not owned or controlled by Trider.",
  //       "Trider has no control over and assumes no responsibility for the content, privacy policies, or practices of any third-party websites or services. You acknowledge and agree that Trider is not responsible for any damages or losses caused by your use of any third-party websites or services.",
  //     ],
  //   },
  //   {
  //     title: "19. Dispute Resolution",
  //     points: [
  //       "Any disputes arising out of or related to these Terms or your use of the App or Services will be resolved through mediation or arbitration, as determined by Trider, before resorting to litigation.",
  //     ],
  //   },
  //   {
  //     title: "20. Miscellaneous",
  //     points: [
  //       "These Terms constitute the entire agreement between you and Trider regarding your use of the App and Services, superseding any prior agreements.",
  //       "If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will continue to be valid and enforceable.",
  //       "Trider’s failure to enforce any right or provision of these Terms will not be deemed a waiver of such right or provision.",
  //     ],
  //   },
  // ];

  return (
    <Container maxW="container.xl" py="80px" mt="30px">
      <Heading as="h2" mb={4}>
        Terms and Conditions
      </Heading>
      {/* {sections.map((section, index) => (
        <Box key={index} mb={5}>
          <Heading as="h4" mb={2}>
            {section.title}
          </Heading>
          <UnorderedList spacing={2}>
            {section.points.map((point, idx) => (
              <ListItem key={idx}>
                <Text>{point}</Text>
              </ListItem>
            ))}
          </UnorderedList>
        </Box>
      ))} */}
      <Box
        pt="15px"
        textAlign="justify"
        dangerouslySetInnerHTML={{ __html: aboutData }} // Dynamically render HTML content
      />
    </Container>
  );
};

export default TermsAndConditions;
