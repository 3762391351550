import { useSelector } from "react-redux";
import React, { useState } from "react";
import {
  Box,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex,
  Image,
  Heading,
  Stack,
  Input,
  FormLabel,
  FormControl,
  Textarea,
  Checkbox,
  Icon,
} from "@chakra-ui/react";
import profile from "../../assets/images/profile.png";
import { IoStar } from "react-icons/io5";
import { FaUser, FaArrowLeft } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const CustomerProfile = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isEditing, setIsEditing] = useState(false); // State to manage edit mode

  const { secondryText, headingColor, rating } = useSelector(
    (state) => state.theme
  );

  const toggleEdit = () => setIsEditing(!isEditing); // Toggle edit mode

  return (
    <>
      {/* <Button onClick={onOpen}>Profile Settings</Button> */}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Button variant="link" as={NavLink} to="/company-employees">
              <Icon as={FaArrowLeft} />
            </Button>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" align="center" mb={4}>
              <Image
                borderRadius="full"
                boxSize="100"
                src={profile}
                alt="Profile Picture"
                mb={4}
              />
              <Heading
                as="h4"
                size="lg"
                mb={2}
                color={headingColor}
                textTransform="uppercase"
              >
                Sarbjeet
              </Heading>
              <Flex alignItems="center" gap="5px">
                <Text color={secondryText} fontSize="18px" fontWeight="700">
                  4.5
                </Text>
                <Flex>
                  <IoStar color={rating} />
                  <IoStar color={rating} />
                  <IoStar color={rating} />
                  <IoStar color={rating} />
                  <IoStar color={rating} />
                </Flex>
              </Flex>
              <Text color={secondryText} mb={4}>
                123 Reviews
              </Text>
            </Flex>
            <Stack spacing={4} pb="30px">
              <FormControl>
                <FormLabel>Mobile Number</FormLabel>
                <Input
                  type="tel"
                  defaultValue="+91 9876543210"
                  isReadOnly={!isEditing}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  defaultValue="example@example.com"
                  isReadOnly={!isEditing}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Location</FormLabel>
                <Input
                  type="text"
                  defaultValue="Bangalore, India "
                  isReadOnly={!isEditing}
                />
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={toggleEdit}
              colorScheme={isEditing ? "blue" : "green"}
            >
              {isEditing ? "Save" : "Edit"}
            </Button>
            <Button onClick={onClose} ml={3}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CustomerProfile;
