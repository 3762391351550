import { messaging } from "../firebase"; // Import the messaging instance from your firebase.js file
import { getToken } from "firebase/messaging";

export const getFirebaseToken = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("Notification permission granted.");
      const token = await getFT(); // Await the result of getFT
      return token; // Return the token received from getFT
    } else {
      console.log("Notification permission denied.");
      return null; // Return null if permission is denied
    }
  } catch (error) {
    console.error("Error requesting notification permission:", error);
    return null; // Return null in case of an error
  }
};

// Get Firebase token
const getFT = async () => {
  try {
    const token = await getToken(messaging, {
      vapidKey:
        "BJLB7h2dCQlSPdwOKqp7uHBF2vOlcVXFjImSxyQsveD0zTndpLrZz9mGMPhi__I-nMLf-B0L0BWCd2TM4B-me_U", // VAPID key from Firebase Console
    });
    if (token) {
      console.log("Firebase token:", token);
      return token; // Return the token for further use
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
      return "xxxxxxxx"; // Return null if no token is available
    }
  } catch (error) {
    console.error("Error retrieving Firebase token:", error);
    return "xxxxxxxx"; // Return null in case of an error
  }
};
