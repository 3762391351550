import { Box, Text, Icon, Flex } from "@chakra-ui/react";
import { IoStar, IoStarOutline } from "react-icons/io5";

const Rating = ({ total_rating, reviewsCount }) => {
  // Convert total_rating to a number and ensure it's between 0 and 5
  const rating = Math.max(0, Math.min(parseFloat(total_rating), 5));
  const fullStars = Math.floor(rating); // Number of fully filled stars
  const hasHalfStar = rating % 1 >= 0.5; // Check if there’s a half star
  const emptyStars = 5 - Math.ceil(rating); // Number of empty stars

  return (
    <Flex alignItems="center">
      <Box>
        {/* Render filled stars */}
        {Array.from({ length: fullStars }).map((_, index) => (
          <Icon key={`full-${index}`} as={IoStar} color="yellow.400" />
        ))}

        {/* Render half star if needed */}
        {hasHalfStar && <Icon as={IoStar} color="yellow.400" />}

        {/* Render empty stars */}
        {Array.from({ length: emptyStars }).map((_, index) => (
          <Icon key={`empty-${index}`} as={IoStarOutline} color="gray.300" />
        ))}
      </Box>
      <Text ml={2}>({reviewsCount || 0}) Reviews</Text>
    </Flex>
  );
};
export default Rating;
