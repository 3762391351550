// firebase.js
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_API_KEY,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_APP_ID,
//   measurementId: process.env.REACT_APP_MEASUREMENT_ID,
// };

const firebaseConfig = {
  apiKey: "AIzaSyCrFdQVH0MrryatK2eDY19Yf_1YON31wvg",
  authDomain: "trider-c3efc.firebaseapp.com",
  projectId: "trider-c3efc",
  storageBucket: "trider-c3efc.appspot.com",
  messagingSenderId: "936885140370",
  appId: "1:936885140370:web:b2c7f38a1bb992c1075f4b",
  measurementId: "G-H3MXRCQ0LT",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging (FCM)
const messaging = getMessaging(firebaseApp);
console.log("messaging========", messaging);
export { messaging };
