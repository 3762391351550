import React, { useRef, useEffect, useState } from "react";
import {
  Flex,
  Box,
  Text,
  Avatar,
  Input,
  IconButton,
  Divider,
  Image,
  Container,
  Icon,
  Button,
} from "@chakra-ui/react";
import {
  FaPaperPlane,
  FaArrowLeft,
  FaCheck,
  FaCheckDouble,
  FaRedo,
} from "react-icons/fa";
import logo from "../trider-logo.png";
import backgroundImage from "../assets/images/chat-bg.png";
import { isToday, isYesterday, format } from "date-fns"; // For formatting date and time
import { getChatList, getMessages, sendMessage } from "../api-services/index";

const usersData = [
  {
    id: 1,
    name: "Rahul Patel",
    avatar: logo,
    userId: "78845694",
    lastMessage: "Hey, how are you?",
    lastMessageTime: new Date(new Date().setHours(new Date().getHours() - 2)), // 2 hours ago
  },
  {
    id: 2,
    name: "Ranu Patel",
    avatar: logo,
    userId: "88847895",
    lastMessage: "Let's meet tomorrow.",
    lastMessageTime: new Date(new Date().setDate(new Date().getDate() - 2)), // 2 days ago
  },
  // Add more users as needed
];
const formatLastMessageTime = (chatTime) => {
  const time = new Date(chatTime);
  const now = new Date();
  if (isToday(time)) {
    return format(time, "p"); // Shows time like "10:30 AM"
  } else if (isYesterday(time)) {
    return "Yesterday";
  } else if (now - time > 24 * 60 * 60 * 1000) {
    return format(time, "dd/MM/yyyy"); // Shows date in dd/MM/yyyy format
  }
};
const ChatPage = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [chatList, setChatList] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [filteredUsers, setFilteredUsers] = useState([]); // Loading state
  const userId =
    localStorage.getItem("userId") != null
      ? Number(localStorage.getItem("userId"))
      : 0;
  const boxRef = useRef(null);

  // Handle user click to open chat
  const handleUserClick = (user, e) => {
    setSelectedUser(user);
    // setMessages([]);
    fetchMessages(user);
  };

  // Send a new message and simulate opposition's reply
  const handleSendMessage = () => {
    if (inputValue) {
      const newMessage = {
        booking_id: selectedUser?.booking_id,
        receiver_id:
          selectedUser?.receiver_id === userId
            ? selectedUser?.sender_id
            : selectedUser?.receiver_id,
        message: inputValue,
        chat_id: selectedUser?.chatID,
      };
      chatMessage(newMessage);
      setInputValue("");
    }
  };
  const chatMessage = async (newMessage) => {
    try {
      setLoading(true);
      const data = await sendMessage(newMessage); // Fetch about content
      // if (data?.success) {
      //   setChatList(data?.response); // Store data in state
      //   setFilteredUsers(data?.response);
      // }
      fetchMessages(selectedUser);
    } catch (error) {
      setError("Failed to load content"); // Set error if the request fails
    } finally {
      setLoading(false); // Disable loading once data is fetched
    }
  };
  // Search filter
  // const filteredUsers = chatList?.filter((user) =>
  //   user?.receiver_name.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  // Format last message time

  // Format the status ticks
  const renderMessageStatus = (status) => {
    if (status === "sent") {
      return <FaCheck />;
    } else if (status === "delivered") {
      return <FaCheckDouble color="gray" />;
    } else if (status === "read") {
      return <FaCheckDouble color="green" />;
    }
  };

  useEffect(() => {
    const fetchChatList = async () => {
      try {
        const data = await getChatList(); // Fetch about content
        if (data?.success) {
          setChatList(data?.response); // Store data in state
          setFilteredUsers(data?.response.reverse());
        }
      } catch (error) {
        setError("Failed to load content"); // Set error if the request fails
      } finally {
        setLoading(false); // Disable loading once data is fetched
      }
    };

    fetchChatList();
  }, []);
  const fetchMessages = async (user) => {
    try {
      const data = await getMessages(user?.chatID); // Fetch about content
      if (data?.success) {
        setMessages(data?.response?.chat); // Store data in state
      }
    } catch (error) {
      setError("Failed to load content"); // Set error if the request fails
    } finally {
      setLoading(false); // Disable loading once data is fetched
    }
  };

  // Polling effect: refresh messages every 5 seconds
  useEffect(() => {
    let interval;
    if (selectedUser) {
      interval = setInterval(() => {
        fetchMessages(selectedUser);
        // scrollToBottom();
      }, 10000); // Fetch new messages every 10 seconds
    }
    return () => {
      if (interval) {
        clearInterval(interval); // Clear interval when unmounting
      }
    };
  }, [selectedUser]);

  const scrollToBottom = () => {
    if (boxRef.current) {
      // Scroll to the bottom of the Box
      boxRef.current.scrollTop = boxRef.current.scrollHeight;
    }
  };

  return (
    <Box bgImage={`url(${backgroundImage})`} bgSize="cover" bgPos="center">
      <Container maxW="1480px" p={4} py="100px">
        <Box
          className="chatPage"
          bg="#fff"
          boxShadow="0px 0px 5px 0px rgba(0,0,0,0.4)"
          h="80vh"
        >
          <Flex
            height={{ base: "100%", md: "100%" }}
            direction={["column", "column", "row"]}
          >
            <Box
              w={["100%", "100%", "30%"]}
              borderRight="1px solid lightgray"
              p="4"
              display={selectedUser && ["none", "none", "block"]}
            >
              {/* <Input
                placeholder="Search users..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                mb="4"
              /> */}
              <Text fontSize="xl" mb="4">
                Chat History
              </Text>
              <Divider mb="10px" />
              {filteredUsers.length > 0 ? (
                filteredUsers.map((user) => (
                  <Flex
                    key={user.id}
                    align="center"
                    p="2"
                    mb="2"
                    cursor="pointer"
                    _hover={{ bg: "gray.100" }}
                    onClick={() => handleUserClick(user)}
                    borderBottom="1px solid #00000008"
                  >
                    {user.sender_id === userId ? (
                      <Avatar src={user.receiver_image} mr="3" />
                    ) : (
                      <Avatar src={user.sender_image} mr="3" />
                    )}
                    <Box>
                      {user.sender_id === userId ? (
                        <Text fontWeight="bold">{user.receiver_name}</Text>
                      ) : (
                        <Text fontWeight="bold">{user.sender_name}</Text>
                      )}

                      <Text fontSize="sm" color="gray.500">
                        {user.lastMessage}
                      </Text>
                      <Text fontSize="xs" color="gray.400">
                        {formatLastMessageTime(user.lastMessageTime)}
                      </Text>
                    </Box>
                  </Flex>
                ))
              ) : (
                <Text>No users found</Text>
              )}
            </Box>

            <Box w={["100%", "100%", "70%"]} position="relative" h="100%">
              {selectedUser ? (
                <>
                  <Flex align="center" mb="4" bg="#f7f7f7" p="10px">
                    {selectedUser && (
                      <Icon
                        as={FaArrowLeft}
                        cursor="pointer"
                        display={["block", "block", "none"]}
                        onClick={() => setSelectedUser(null)}
                        mr="3"
                      />
                    )}
                    {selectedUser.sender_id === userId ? (
                      <Avatar src={selectedUser.receiver_image} mr="3" />
                    ) : (
                      <Avatar src={selectedUser.sender_image} mr="3" />
                    )}
                    {/* <Avatar src={selectedUser.receiver_image} mr="3" /> */}
                    {selectedUser.sender_id === userId ? (
                      <Text fontSize="xl">{selectedUser.receiver_name}</Text>
                    ) : (
                      <Text fontSize="xl">{selectedUser.sender_name}</Text>
                    )}
                  </Flex>

                  <Box
                    mb="4"
                    h="60vh"
                    overflowY="auto"
                    p="4"
                    borderRadius="md"
                    pt="50px"
                    pb="40px"
                    ref={boxRef}
                  >
                    {messages.length > 0 ? (
                      messages.map((message, index) => (
                        <>
                          <Flex
                            key={index}
                            justify={
                              message.sender_id === userId
                                ? "flex-end"
                                : "flex-start"
                            }
                          >
                            <Box
                              bg={
                                message.sender_id === userId
                                  ? "blue.100"
                                  : "gray.100"
                              }
                              p="2"
                              borderRadius="md"
                              mb="2"
                              position="relative"
                            >
                              <Text>{message.message}</Text>
                              <Box display="flex" gap="5px" alignItems="center">
                                <Text fontSize="xs" color="gray.500" mt="1">
                                  {formatLastMessageTime(message.created_at)}
                                </Text>

                                {message.sender_id === userId && (
                                  <Box>
                                    {renderMessageStatus(message.status)}
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </Flex>
                        </>
                      ))
                    ) : (
                      <Flex
                        alignItems="center"
                        justifyContent="center"
                        h="100%"
                      >
                        <Text>
                          No previous messages. Start a new conversation.
                        </Text>
                      </Flex>
                    )}
                  </Box>

                  <Flex
                    position="absolute"
                    width="95%"
                    bottom="20px"
                    mx="10px"
                    bg="#fff"
                  >
                    <Input
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      placeholder="Type a message..."
                      mr="2"
                    />
                    <Button style={{ marginRight: "10px" }}>
                      <IconButton
                        icon={<FaRedo />}
                        onClick={() => handleUserClick(selectedUser)}
                        aria-label="Refresh messages"
                      />
                    </Button>
                    <Button isLoading={loading}>
                      <IconButton
                        icon={<FaPaperPlane />}
                        onClick={handleSendMessage}
                        aria-label="Send message"
                      />
                    </Button>
                  </Flex>
                </>
              ) : (
                <Flex
                  direction="column"
                  align="center"
                  justify="center"
                  h="100%"
                >
                  <Image src={logo} margin="0 auto" w="100px" mb="30px" />
                  <Text fontSize="2xl">Welcome to trider</Text>
                  <Text>Select a user to start a conversation</Text>
                </Flex>
              )}
            </Box>
          </Flex>
        </Box>
      </Container>
    </Box>
  );
};

export default ChatPage;
