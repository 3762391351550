import {
  GET_FAQ_SUCCESS,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_REQUEST,
  CREATE_CONTACT_FAILURE,
  SUBMIT_BANK_DETAILS_REQUEST,
  SUBMIT_BANK_DETAILS_SUCCESS,
  SUBMIT_BANK_DETAILS_FAILURE,
  FETCH_BANK_DETAILS_SUCCESS,
  FETCH_BANK_DETAILS_FAILURE,
  SUBMIT_PROFILE_DETAILS_REQUEST,
  FETCH_PROFILE_DETAILS_SUCCESS,
  FETCH_PROFILE_DETAILS_FAILURE,

} from "./actionTypes";

const initialState = {
  faqData: [],
  success: false,
  error: null,
  message: "",
  loading: false,
  bankDetails: null,
  bankLoading: false,
  profileLoadingState: false,
  profileDetailsState: null,
};

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FAQ_SUCCESS:
      return {
        ...state,
        faqData: action.payload || [],
      };
    case CREATE_CONTACT_REQUEST:
      return {
        ...state,
        success: false,
        error: null, // Reset error when making a new request
      };
    case CREATE_CONTACT_SUCCESS:
      return {
        ...state,
        success: true,
        error: null, // Clear error on success
      };
    case CREATE_CONTACT_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload, // Set error message from action payload
      };
    // Bank Details Handling
    case SUBMIT_BANK_DETAILS_REQUEST:
      return {
        ...state,
        bankLoading: true, // Set loading state to true
        success: false,
        error: null, // Reset error when making a new request
      };
    case SUBMIT_BANK_DETAILS_SUCCESS:
      return {
        ...state,
        bankLoading: false, // Set loading state to false
        success: true,
        bankDetails: action.payload, // Store submitted bank details
        error: null, // Clear error on success
      };
    case SUBMIT_BANK_DETAILS_FAILURE:
      return {
        ...state,
        bankLoading: false, // Set loading state to false on failure
        success: false,
        error: action.payload, // Set error message from action payload
      };
    case FETCH_BANK_DETAILS_SUCCESS: // Handle success fetching bank details
      return {
        ...state,
        bankLoading: false, // Set loading state to false
        success: true,
        bankDetails: action.payload, // Update bank details in state
      };
    case FETCH_BANK_DETAILS_FAILURE: // Handle error fetching bank details
      return {
        ...state,
        error: action.payload, // Set error message from action payload
      };
    //Freelancer Details handling;
    case SUBMIT_PROFILE_DETAILS_REQUEST:
      return {
        ...state,
        profileLoadingState: true, // Set loading state to true
        success: false,
        error: null, // Reset error when making a new request
      };
    case FETCH_PROFILE_DETAILS_SUCCESS: // Handle success fetching freelancer details
      return {
        ...state,
        profileLoadingState: false, // Set loading state to false
        success: true,
        profileDetailsState: action.payload, // Update freelancer details in state
      };
    case FETCH_PROFILE_DETAILS_FAILURE: // Handle error fetching freelancer details
      return {
        ...state,
        error: action.payload, // Set error message from action payload
      };
    default:
      return state;
  }
};
