import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  Text,
  Link,
} from "@chakra-ui/react";
import { MdMyLocation } from "react-icons/md"; // Assuming you are using this icon for location
import * as Yup from "yup"; // Yup for validation

const LocationProfile = ({ prevStep, onOpen, nextStep, addressString }) => {
  const [location, setLocation] = useState(addressString || "");
  const [error, setError] = useState({});
  const { loading, authError } = useSelector((state) => state.auth); // Assuming auth error comes from redux state

  // Yup validation schema
  const validationSchema = Yup.object().shape({
    location: Yup.string().required("Location is required"),
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form using Yup
    validationSchema
      .validate({ location })
      .then((valid) => {
        nextStep(valid); // Move to the next step if valid
      })
      .catch((validationError) => {
        setError({ location: validationError.message }); // Show validation error
      });
  };
  // Update location when addressString changes
  useEffect(() => {
    setLocation(addressString || ""); // Set location whenever addressString changes
  }, [addressString]);
  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={4}>
        <FormControl isInvalid={!!error.location}>
          <FormLabel>Search Location</FormLabel>
          <Input
            name="location"
            type="text"
            placeholder="Enter your location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
          <FormErrorMessage>{error.location}</FormErrorMessage>
        </FormControl>

        <Link display="flex" alignItems="center" gap="5px" onClick={onOpen}>
          <MdMyLocation />
          Get Current Location with GPS
        </Link>
        <Text display="flex" alignItems="center" gap="5px" color="red.500">
          <p>{authError?.message}</p> {/* Redux error message */}
        </Text>

        <Stack direction="row" spacing={4}>
          <Button onClick={prevStep} className="btnGreen">
            Back
          </Button>
          <Button type="submit" className="btnGreen" isLoading={loading}>
            Submit
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default LocationProfile;
