import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Input,
  Box,
  Image,
  Text,
  Flex,
  Collapse,
} from "@chakra-ui/react";
import React from "react";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { useSelector } from "react-redux";
import { useState } from "react";

import { FaChevronDown } from "react-icons/fa6";
import { Link, useNavigate, useLocation } from "react-router-dom";

function MobileMenu({ services }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const btnRef = React.useRef();
  const { activeBg, colorBlack, acitveBorder, primaryBg } = useSelector(
    (state) => state.theme
  );

  const [submenuVisible, setSubmenuVisible] = useState(false);
  const toggleSubmenu = () => {
    setSubmenuVisible(!submenuVisible);
  };

  const submenuCaretRotate = {
    transform: submenuVisible ? "rotate(180deg)" : "rotate(0deg)",
    transition: "transform 0.3s ease", // Smooth rotation animation
  };

  const handleMenuClick = () => {
    onClose();
  };
  const userType = localStorage.getItem("user_type");

  const location = useLocation();
  const generateServicePath = (name) =>
    `/service?name=${(name || "").split(" ").join("_")}`;
  const isActive = (path) => {
    const currentPath = location.pathname + location.search;
    return currentPath === path;
  };

  return (
    <>
      <Box className="mobileMenu" display={{ base: "block", md: "None" }}>
        <HiOutlineMenuAlt2 ref={btnRef} onClick={onOpen} fontSize={"26px"} />

        <Drawer
          isOpen={isOpen}
          placement="top"
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent
            pt="30px"
            bg={primaryBg}
            width={{ base: "98%", md: "300px !important" }}
            maxW={{ base: "100%", md: "100%" }}
            height="auto"
            minH="50vh"
            mx="auto"
            mt="1%"
          >
            <Box>
              <DrawerCloseButton
                color={colorBlack}
                className="SiderBarcloseBtn"
                boxShadow="none"
                outline="0"
              />
            </Box>

            <DrawerBody
              borderRadius="0 0 6px 6px"
              css={{
                "&::-webkit-scrollbar": {
                  width: "5px",
                },
                "&::-webkit-scrollbar-thumb": {
                  borderRadius: "10px",
                },
              }}
            >
              <Box className="Mobilemenu_wr" overflowY="auto" textAlign="left">
                <Box>
                  <Box py="10px">
                    <Text className={`${isActive("/") ? "active-menu" : ""}`}>
                      <Link onClick={handleMenuClick} to="/">
                        Home
                      </Link>
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Box py="10px">
                    <Text
                      className={`${isActive("/about") ? "active-menu" : ""}`}
                    >
                      <Link onClick={handleMenuClick} to="/about">
                        About
                      </Link>
                    </Text>
                  </Box>
                </Box>

                {(userType === "user" ||
                  userType === null ||
                  userType === "") && (
                  <Box
                    display="flex"
                    alignItems="center"
                    onClick={toggleSubmenu}
                    justifyContent="space-between"
                    gap="5px"
                  >
                    <Box>
                      <Box py="10px">
                        <Text>Services</Text>
                      </Box>
                    </Box>
                    <FaChevronDown
                      color={colorBlack}
                      style={{ ...submenuCaretRotate }}
                    />
                  </Box>
                )}

                <Collapse in={submenuVisible} animateOpacity>
                  <Box
                    py="10px"
                    px="20px"
                    borderRadius="10px"
                    className="serviceSubmenu"
                  >
                    {services &&
                      services.length > 0 &&
                      services.map((item) => (
                        <Box key={item?.name}>
                          <Box py="10px" className="submenuInner">
                            <Text
                              className={`${
                                isActive(generateServicePath(item?.name))
                                  ? "active-menu"
                                  : ""
                              }`}
                              onClick={() => {
                                handleMenuClick();
                                if (userType === null) {
                                  navigate("/login");
                                } else {
                                  navigate(generateServicePath(item?.name), {
                                    state: item,
                                  });
                                }
                              }}
                            >
                              {item?.name}
                            </Text>
                          </Box>
                        </Box>
                      ))}
                  </Box>
                </Collapse>

                {userType !== "user" && (
                  <Box>
                    <Box py="10px">
                      <Text
                        className={`${
                          isActive("/booking-history") ? "active-menu" : ""
                        }`}
                      >
                        <Link onClick={handleMenuClick} to="/booking-history">
                          Booking History
                        </Link>
                      </Text>
                    </Box>
                  </Box>
                )}

                <Box>
                  <Box py="10px">
                    <Text
                      className={`${isActive("/contact") ? "active-menu" : ""}`}
                    >
                      <Link onClick={handleMenuClick} to="/contact">
                        Contact
                      </Link>
                    </Text>
                  </Box>
                </Box>
              </Box>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Box>
    </>
  );
}

export default MobileMenu;
