import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Flex,
  Text,
  Image,
  Heading,
  Container,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
  VStack,
  useToast,
  SimpleGrid,
} from "@chakra-ui/react";
// import bannerBg from "../assets/images/contact/contactBg.jpg";
import bannerBg from "../assets/images/contact/contact-us-banner.jpg";
import trider from "../assets/images/contact/trider.jpg";
import { IoLocationSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { contactAction } from "../redux/users-redux/actions";

const Contact = () => {
  const { footerBg, primaryText, secondryText, colorBlack, blackmedium } =
    useSelector((state) => state.theme);
  const dispatch = useDispatch();
  const bannerStyle = {
    backgroundImage: `url(${bannerBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100%",
    width: "100%",
  };
  const cardStyle = {
    boxShadow:
      "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
    padding: "30px",
    textAlign: "center",
  };
  const iconStyle = {
    padding: "10px",
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.5)",
    fontSize: "30px",
    display: "inline-block",
    borderRadius: "9px 9px 9px 9px",
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset, // Import reset function
  } = useForm();
  const toast = useToast();

  const onSubmit = async (data) => {
    try {
      dispatch(contactAction(data));
      toast({
        title: "Form submitted.",
        description: "We have received your message.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      // Handle success (e.g., show success message or redirect)
      reset(); // Reset form after successful submission
    } catch (error) {
      console.error("Failed to create contact:", error);
      // Handle error (e.g., show error message)
    }
  };
  return (
    <Box>
      <Box className="pageBanner" style={{ ...bannerStyle }}>
        <Box
          px="20px"
          color={primaryText}
          textAlign="center"
          zIndex="1"
          position="relative"
        >
          <Heading as="h1" py="4">
            Contact Us
          </Heading>
        </Box>
      </Box>
      <Box className="contactPage section_spacingY">
        <Container maxW="container.xl">
          {/* <Box textAlign="center">
            <Heading
              className="bottomLine linegolden line"
              as="h3"
              pb="20px"
              display="inline-block"
              color={colorBlack}
            >
              Get In Touch
            </Heading>
          </Box>
          <Flex
            className="contactDetails"
            gap="30px"
            justifyContent="center"
            py="40px"
            flexWrap="wrap"
          >
            <Box style={{ ...cardStyle }}>
              <Box style={{ ...iconStyle }}>
                <IoLocationSharp size={35} color={secondryText} />
              </Box>
              <Box py="20px">
                <Heading
                  pb="15px"
                  color={blackmedium}
                  fontWeight="bold"
                  as="h5"
                  className="bottomLine linegolden line"
                  display="inline-block"
                >
                  LOCATION
                </Heading>
              </Box>
              <Text fontSize="16px" color={blackmedium} fontWeight="bold">
                130, LOREMIPSUM(M.P)
              </Text>
            </Box>
            <Box style={{ ...cardStyle }}>
              <Box style={{ ...iconStyle }}>
                <MdEmail size={35} color={secondryText} />
              </Box>
              <Box py="20px">
                <Heading
                  pb="15px"
                  color={blackmedium}
                  fontWeight="bold"
                  as="h5"
                  className="bottomLine linegolden line"
                  display="inline-block"
                >
                  LOCATION
                </Heading>
              </Box>
              <Text fontSize="16px" color={blackmedium} fontWeight="bold">
                130, LOREMIPSUM(M.P)
              </Text>
            </Box>
            <Box style={{ ...cardStyle }}>
              <Box style={{ ...iconStyle }}>
                <FaPhoneAlt size={35} color={secondryText} />
              </Box>
              <Box py="20px">
                <Heading
                  pb="15px"
                  color={blackmedium}
                  fontWeight="bold"
                  as="h5"
                  className="bottomLine linegolden line"
                  display="inline-block"
                >
                  LOCATION
                </Heading>
              </Box>
              <Text fontSize="16px" color={blackmedium} fontWeight="bold">
                130, LOREMIPSUM(M.P)
              </Text>
            </Box>
          </Flex> */}
          <Flex gap="30px" pt="40px">
            {/* <Box flex="2" display={{base:'none', lg:"block"}}>
              <Image src={trider}></Image>
            </Box> */}
            <Box flex="3" className="contactFormWr">
              <Box textAlign="center">
                <Heading as="h4" mb="40px">
                  Get in Touch with Us
                </Heading>
              </Box>
              <form onSubmit={handleSubmit(onSubmit)}>
                <VStack spacing={4} alignItems="start">
                  <Flex gap="20px" w="100%">
                    <FormControl isInvalid={errors.name} flex="1">
                      <FormLabel>Full Name</FormLabel>
                      <Input
                        id="name"
                        placeholder="Full Name"
                        {...register("name", {
                          required: "Name is required",
                          minLength: {
                            value: 2,
                            message: "Name must be at least 2 characters",
                          },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.name && errors.name.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors.email} flex="1">
                      <FormLabel>Email</FormLabel>
                      <Input
                        id="email"
                        type="email"
                        placeholder="Email Id"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value:
                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                            message: "Invalid email address",
                          },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.email && errors.email.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                  <FormControl isInvalid={errors.phone}>
                    <FormLabel>Phone Number</FormLabel>
                    <Input
                      id="phone"
                      placeholder="Phone Number"
                      {...register("phone", {
                        required: "Phone number is required",
                        pattern: {
                          value: /^[0-9]{10}$/,
                          message: "Invalid phone number, must be 10 digits",
                        },
                      })}
                    />
                    <FormErrorMessage>
                      {errors.phone && errors.phone.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.message}>
                    <FormLabel>Message</FormLabel>
                    <Textarea
                      id="message"
                      placeholder="Enter your message"
                      minHeight="150px"
                      {...register("message", {
                        required: "Message is required",
                        minLength: {
                          value: 10,
                          message: "Message must be at least 10 characters",
                        },
                      })}
                    />
                    <FormErrorMessage>
                      {errors.message && errors.message.message}
                    </FormErrorMessage>
                  </FormControl>

                  <Button
                    className="contactformBtn blueBtn"
                    color={primaryText}
                    isLoading={isSubmitting}
                    type="submit"
                    borderRadius="0"
                  >
                    Submit
                  </Button>
                </VStack>
              </form>
            </Box>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};

export default Contact;
