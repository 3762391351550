import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Image,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  SimpleGrid,
  Stack,
  Button,
} from "@chakra-ui/react";

const AadhaarCardSection = ({
  setFieldValue,
  errors,
  touched,
  profileDetailsState,
}) => {
  const [showFrontInput, setShowFrontInput] = useState(false);
  const [showBackInput, setShowBackInput] = useState(false);

  useEffect(() => {
    // Update showFrontInput based on the presence of front_aadhaar_card
    setShowFrontInput(
      Boolean(profileDetailsState?.business_profile?.front_aadhaar_card)
    );
  }, [profileDetailsState?.business_profile?.front_aadhaar_card]);

  useEffect(() => {
    // Update showBackInput based on the presence of back_aadhaar_card
    setShowBackInput(
      Boolean(profileDetailsState?.business_profile?.back_aadhaar_card)
    );
  }, [profileDetailsState?.business_profile?.back_aadhaar_card]);
  const aadhaarFrontInputRef = useRef(null);
  const aadhaarBackInputRef = useRef(null);

  const handleFileChange = (event, field) => {
    setFieldValue(field, event.currentTarget.files[0]);
  };

  const handleImageClick = (inputRef, toggleInputState) => {
    if (inputRef.current) {
      inputRef.current.click();
    }
    toggleInputState(false);
  };

  return (
    <Stack spacing={4}>
      <FormControl isInvalid={touched.aadhaarFront && errors.aadhaarFront}>
        <FormLabel>Add Aadhaar Card</FormLabel>
        <SimpleGrid columns={{ base: 1, md: 2 }} gap="40px">
          {/* Front Image */}
          <FormControl>
            <FormLabel fontSize="14px">Upload front image</FormLabel>

            <Box position="relative">
              {showFrontInput ? (
                <Box position="relative">
                  <Image
                    src={
                      profileDetailsState?.business_profile?.front_aadhaar_card
                    }
                    alt="Aadhaar Front"
                    objectFit="cover"
                    boxSize="100%"
                    borderWidth="1px"
                    borderRadius="lg"
                    overflow="hidden"
                  />
                  <Button
                    onClick={() =>
                      handleImageClick(aadhaarFrontInputRef, setShowFrontInput)
                    }
                    position="absolute"
                    bottom="2"
                    right="2"
                    colorScheme="teal"
                    size="sm"
                  >
                    Change Image
                  </Button>
                </Box>
              ) : (
                <Box position="relative">
                  <Input
                    ref={aadhaarFrontInputRef}
                    type="file"
                    onChange={(event) =>
                      handleFileChange(event, "aadhaarFront")
                    }
                  />
                  {/* <Button
                    onClick={() => setShowFrontInput(false)}
                    position="absolute"
                    top="2"
                    right="2"
                    colorScheme="teal"
                    size="sm"
                  >
                    Cancel
                  </Button> */}
                </Box>
              )}
            </Box>

            <FormErrorMessage>{errors.aadhaarFront}</FormErrorMessage>
          </FormControl>

          {/* Back Image */}
          <FormControl>
            <FormLabel fontSize="14px">Upload back image</FormLabel>

            <Box position="relative">
              {showBackInput ? (
                <Box position="relative">
                  <Image
                    src={
                      profileDetailsState?.business_profile?.back_aadhaar_card
                    }
                    alt="Aadhaar Back"
                    objectFit="cover"
                    boxSize="100%"
                    borderWidth="1px"
                    borderRadius="lg"
                    overflow="hidden"
                  />
                  <Button
                    onClick={() =>
                      handleImageClick(aadhaarBackInputRef, setShowBackInput)
                    }
                    position="absolute"
                    bottom="2"
                    right="2"
                    colorScheme="teal"
                    size="sm"
                  >
                    Change Image
                  </Button>
                </Box>
              ) : (
                <Box position="relative">
                  <Input
                    ref={aadhaarBackInputRef}
                    type="file"
                    onChange={(event) => handleFileChange(event, "aadhaarBack")}
                  />
                </Box>
              )}
            </Box>

            <FormErrorMessage>{errors.aadhaarBack}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>
      </FormControl>
    </Stack>
  );
};

export default AadhaarCardSection;
