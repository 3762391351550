import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Box,
  Text,
  Heading,
  Container,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepTitle,
  StepDescription,
  StepSeparator,
  Stack,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Select,
  useDisclosure,
  FormErrorMessage,
} from "@chakra-ui/react";
import { CgProfile } from "react-icons/cg";
import { RiMenuAddLine } from "react-icons/ri";
import AddressDetailsModal from "../../../components/AddressDetails";
import { signupCompanyAction } from "../../../redux/auth-redux/actions";
import { fetchProfileDetails } from "../../../redux/users-redux/actions";

import SelectServices from "../../../components/SelectServices";
import LocationProfile from "../../../components/LocationProfile";
import ProfileImage from "../../../components/ProviderProfileImage";
import { setProfileDetails } from "../../../redux/users-redux/actions";
const useStepper = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = React.useState(0);
  const [formData, setFormData] = React.useState({});
  const [addressData, setAddressData] = React.useState({});
  const [addressString, setAddressString] = React.useState("");

  const { profileLoadingState, profileDetailsState } = useSelector(
    (state) => state?.users
  );

  const user_token = localStorage.getItem("token") || null;

  React.useEffect(() => {
    const fetchData = async () => {
      if (user_token) {
        await dispatch(fetchProfileDetails());
      }
    };
    fetchData();
    return () => {
      // Cleanup code here if required
    };
  }, [user_token, dispatch]);

  const fullAddressIfExists = profileDetailsState?.business_address
    ? `${profileDetailsState?.business_address?.address_line_one}, ${profileDetailsState?.business_address?.address_line_two}, ${profileDetailsState?.business_address?.city}, ${profileDetailsState?.business_address?.state}, ${profileDetailsState?.business_address?.pincode}`
    : "";

  React.useEffect(() => {
    if (fullAddressIfExists) {
      setAddressString(fullAddressIfExists);
    }
  }, [fullAddressIfExists]);

  const nextStep = async (values) => {
    if (activeStep !== 2) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    let merged;
    if (Object.keys(addressData).length) {
      merged = Object.assign(formData, addressData);
    } else if (fullAddressIfExists) {
      const obj = {
        address: profileDetailsState?.business_address?.address_line_one,
        landmark: profileDetailsState?.business_address?.address_line_two,
        city: profileDetailsState?.business_address?.city,
        state: profileDetailsState?.business_address?.state,
        pincode: profileDetailsState?.business_address?.pincode,
        latitude: profileDetailsState?.business_address?.latitude,
        longitude: profileDetailsState?.business_address?.longitude,
      };
      merged = Object.assign(formData, values, obj);
    } else {
      merged = Object.assign(formData, values);
    }

    setFormData(merged);
    if (activeStep === 2) {
      const res = await dispatch(signupCompanyAction(formData));
      if (res.success === true) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        navigate("/");
      }
    }
  };

  const prevStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return {
    activeStep,
    nextStep,
    prevStep,
    setAddressData,
    addressString,
    setAddressString,
    profileDetailsState,
  };
};

const steps = [
  { title: "Basic", description: "Basic Information" },
  { title: "Services", description: "Select Services" },
  { title: "Location", description: "Set Location" },
];

const CompanyProfile = () => {
  const {
    activeStep,
    nextStep,
    prevStep,
    setAddressData,
    addressString,
    setAddressString,
    profileDetailsState,
  } = useStepper();
  const { primaryText, footerColor, activeBg } = useSelector(
    (state) => state.theme
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleAddressSubmit = (addressDetails, currentPosition) => {
    const mergedObj = Object.assign({}, addressDetails, currentPosition);
    setAddressData(mergedObj);
    const fullAddress = `${addressDetails.address}, ${addressDetails.address_line_two}, ${addressDetails.city}, ${addressDetails.state}, ${addressDetails.pincode}`;
    setAddressString(fullAddress);
    onClose();
  };
  const [profileImageFile, setProfileImageFile] = useState(null);

  const handleImageChange = (newImage) => {
    setProfileImageFile(newImage);
  };

  return (
    <Box className="bussinessProfile_page">
      <Box className="bussinessProfile" mb="60px">
        <Container maxW="container.xl" p={4} color={footerColor}>
          <Box className="profile">
            <Heading as="h4" color={primaryText} fontWeight="700">
              Create Premium Profile
            </Heading>
            <Text
              as="span"
              color={primaryText}
              mb="20px"
              display="inline-block"
            >
              Add details about the service you are offering
            </Text>

            <Box>
              <Text
                as="span"
                color={primaryText}
                mb="20px"
                display="inline-block"
                background="#000"
                p="5px 8px"
                borderRadius="6px"
              >
                Premium ID:{" "}
                {profileDetailsState?.business_profile?.company_unique_id}
              </Text>
            </Box>

            <ProfileImage
              profileDetailsState={profileDetailsState}
              onImageChange={handleImageChange}
            />
          </Box>
        </Container>
      </Box>

      <Box mb="40px">
        <Container maxW="container.xl" p={4} color={footerColor}>
          <Box mb="40px" className="stepser formWr">
            <Stepper index={activeStep} mb="6">
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepIndicator>
                    <StepStatus
                      complete={<CgProfile />}
                      incomplete={<RiMenuAddLine />}
                      active={<CgProfile />}
                    />
                  </StepIndicator>
                  <Box flexShrink="0">
                    <StepTitle>{step.title}</StepTitle>
                    <StepDescription>{step.description}</StepDescription>
                  </Box>
                  {index !== steps.length - 1 && <StepSeparator />}
                </Step>
              ))}
            </Stepper>
            <StepContent
              activeStep={activeStep}
              nextStep={nextStep}
              prevStep={prevStep}
              onOpen={onOpen}
              addressString={addressString}
              profileDetailsState={profileDetailsState}
              profileImageFile={profileImageFile}
            />
          </Box>
        </Container>
      </Box>
      <AddressDetailsModal
        isOpen={isOpen}
        onClose={onClose}
        onAddressSubmit={handleAddressSubmit}
        profileDetailsState={profileDetailsState}
      />
    </Box>
  );
};
const StepContent = ({
  activeStep,
  nextStep,
  prevStep,
  onOpen,
  addressString,
  profileDetailsState,
  profileImageFile,
}) => {
  const ImgesData = profileDetailsState?.business_images?.map(
    (i) => i?.business_image
  );
  switch (activeStep) {
    case 0:
      return (
        <Basic
          nextStep={nextStep}
          profileDetailsState={profileDetailsState}
          ImgesData={ImgesData}
          profileImageFile={profileImageFile}
        />
      );
    case 1:
      return (
        <SelectServices
          nextStep={(values) => nextStep(values)}
          prevStep={prevStep}
          profileDetailsState={profileDetailsState}
        />
      );
    case 2:
      return (
        <LocationProfile
          prevStep={prevStep}
          onOpen={onOpen}
          nextStep={(values) => nextStep(values)}
          addressString={addressString}
        />
      );
    default:
      return null;
  }
};
const Basic = ({ nextStep, profileDetailsState, profileImageFile }) => {
  const dispatch = useDispatch(); // Assuming you're using Redux
  // Validation schema using Yup
  const validationSchema = Yup.object({
    business_name: Yup.string().required("Full Name is required"),
    businessMobileNumber: Yup.string()
      .matches(
        /^(\+\d{1,3})?[0-9]{10}$/,
        "Must be a valid 10-digit mobile number"
      )
      .required("Business Mobile Number is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    gstNumber: Yup.string().required("GST Number is required"),
    panNumber: Yup.string().required("PAN Number is required"),
    experience: Yup.string().required("Years of experience is required"),
  });

  const handleSubmit = (values) => {
    // Format values according to the expected structure
    const formattedValues = {
      business_profile: {
        business_name: values.business_name,
        business_phone_no: values.businessMobileNumber,
        email: values.email,
        gst_number: values.gstNumber,
        pan_card_number: values.panNumber,
        year_of_exp: values.experience,
        businessImages: profileImageFile ? [profileImageFile] : [], // Add profileImageFile if present
      },
    };

    // Merge formattedValues with existing profileDetailsState
    const mergedValues = {
      ...profileDetailsState,
      business_profile: {
        ...profileDetailsState.business_profile,
        ...formattedValues.business_profile,
      },
    };

    // Dispatch the action with merged values
    dispatch(setProfileDetails(mergedValues));
    // Proceed to next step
    nextStep(formattedValues);
  };
  return (
    <Formik
      enableReinitialize
      initialValues={{
        business_name:
          profileDetailsState?.business_profile?.business_name ??
          profileDetailsState?.business_name,
        businessMobileNumber:
          profileDetailsState?.business_profile?.business_phone_no ||
          profileDetailsState?.phone_number ||
          "",
        email: profileDetailsState?.email ?? "",
        gstNumber: profileDetailsState?.business_profile?.gst_number ?? "",
        panNumber: profileDetailsState?.business_profile?.pan_card_number ?? "",
        experience: profileDetailsState?.business_profile?.year_of_exp ?? "",
        businessImages: [],
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        if (profileImageFile !== undefined) {
          values.businessImages.push(profileImageFile);
        }
        handleSubmit(values);
      }}
    >
      {({ setFieldValue, errors, touched, values }) => (
        <Form>
          <Stack spacing={4}>
            <SimpleGrid columns={{ base: 1, md: 2 }} gap="40px">
              <FormControl
                isInvalid={errors.business_name && touched.business_name}
              >
                <FormLabel>Business Name</FormLabel>
                <Field as={Input} name="business_name" />
                {errors.business_name && touched.business_name && (
                  <Text color="red.500" fontSize="sm">
                    {errors.business_name}
                  </Text>
                )}
              </FormControl>

              <FormControl
                isInvalid={
                  errors.businessMobileNumber && touched.businessMobileNumber
                }
              >
                <FormLabel>Business Mobile Number</FormLabel>
                <Field as={Input} name="businessMobileNumber" type="tel" />
                {errors.businessMobileNumber &&
                  touched.businessMobileNumber && (
                    <Text color="red.500" fontSize="sm">
                      {errors.businessMobileNumber}
                    </Text>
                  )}
              </FormControl>

              <FormControl isInvalid={errors.email && touched.email}>
                <FormLabel>Email</FormLabel>
                <Field as={Input} name="email" type="email" />
                {errors.email && touched.email && (
                  <Text color="red.500" fontSize="sm">
                    {errors.email}
                  </Text>
                )}
              </FormControl>

              <FormControl isInvalid={errors.gstNumber && touched.gstNumber}>
                <FormLabel>GST Number</FormLabel>
                <Field as={Input} name="gstNumber" />
                {errors.gstNumber && touched.gstNumber && (
                  <Text color="red.500" fontSize="sm">
                    {errors.gstNumber}
                  </Text>
                )}
              </FormControl>

              <FormControl isInvalid={errors.panNumber && touched.panNumber}>
                <FormLabel>PAN Number</FormLabel>
                <Field as={Input} name="panNumber" />
                {errors.panNumber && touched.panNumber && (
                  <Text color="red.500" fontSize="sm">
                    {errors.panNumber}
                  </Text>
                )}
              </FormControl>

              <FormControl isInvalid={touched.experience && errors.experience}>
                <FormLabel>Years of Experience</FormLabel>
                <Field name="experience">
                  {({ field }) => (
                    <Select placeholder="Select experience" {...field}>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="5+">5+</option>
                    </Select>
                  )}
                </Field>
                <FormErrorMessage>{errors.experience}</FormErrorMessage>
              </FormControl>
            </SimpleGrid>
            <button className="btnGreen" type="submit">
              Next
            </button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default CompanyProfile;
