import React, { useState, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  Box,
  Text,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
} from "@chakra-ui/react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import GoogleMapComponent from "../pages/map";

const AddressDetailsModal = ({ isOpen, onClose, onAddressSubmit, address }) => {
  const modalBodyRef = useRef();
  const [currentPosition, setCurrentPosition] = useState(address || {}); // Initialize with provided address or an empty object
  const [isFormVisible, setIsFormVisible] = useState(false);

  const scrollToBottom = () => {
    if (modalBodyRef.current) {
      modalBodyRef.current.scrollTop = modalBodyRef.current.scrollHeight;
    }
  };

  const handleChangeClick = () => {
    setIsFormVisible(!isFormVisible);
    setTimeout(scrollToBottom, 0); // Scroll to bottom after toggle
  };

  // Callback for map location updates
  const onLocationUpdate = (info) => {
    setCurrentPosition(info); // Update current position with the selected location
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount={false}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Your Address</ModalHeader>
        <ModalCloseButton />
        <ModalBody ref={modalBodyRef}>
          {/* Google Map Component */}
          <GoogleMapComponent onLocationUpdate={onLocationUpdate} />

          {/* Display Current Location Info */}
          <Box
            display="flex"
            justifyContent="space-between"
            pt="20px"
            mb="30px"
          >
            <Box>
              <Text fontSize="16px" fontWeight="700">
                Current Location
              </Text>
              <Text fontSize="14px">
                Latitude: {currentPosition?.latitude || "N/A"}, Longitude:{" "}
                {currentPosition?.longitude || "N/A"}
              </Text>
            </Box>
            <Button onClick={handleChangeClick}>SELECT</Button>
          </Box>

          {/* Formik Form for Address */}
          {isFormVisible && (
            <Formik
              initialValues={{
                address_line_one: currentPosition?.address_line_one ?? "",
                address_line_two: currentPosition?.address_line_two ?? "",
                city: currentPosition?.city ?? "",
                state: currentPosition?.state ?? "",
                pincode: currentPosition?.pincode ?? "",
                is_default: currentPosition?.is_default || false,
              }}
              validationSchema={Yup.object({
                address_line_one: Yup.string().required("Address is required"),
                city: Yup.string().required("City is required"),
                state: Yup.string().required("State is required"),
                pincode: Yup.string().required("Pincode is required"),
                address_line_two: Yup.string().required("Landmark is required"),
              })}
              enableReinitialize={true}
              onSubmit={(values, actions) => {
                const finalAddress = {
                  ...values,
                  latitude: currentPosition.latitude,
                  longitude: currentPosition.longitude,
                };
                onAddressSubmit(finalAddress); // Pass final address to parent component
                actions.setSubmitting(false);
                onClose(); // Close modal after submission
              }}
            >
              {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <FormControl mb="10px">
                    <FormLabel>Complete Address</FormLabel>
                    <Field
                      as={Input}
                      name="address_line_one"
                      type="text"
                      placeholder="Enter Address"
                    />
                    <ErrorMessage name="address_line_one" component="div" />
                  </FormControl>
                  <FormControl mb="10px">
                    <FormLabel>Landmark</FormLabel>
                    <Field
                      as={Input}
                      name="address_line_two"
                      type="text"
                      placeholder="Landmark"
                    />
                    <ErrorMessage name="address_line_two" component="div" />
                  </FormControl>
                  <FormControl mb="10px">
                    <FormLabel>City</FormLabel>
                    <Field
                      as={Input}
                      name="city"
                      type="text"
                      placeholder="City"
                    />
                    <ErrorMessage name="city" component="div" />
                  </FormControl>

                  <FormControl mb="10px">
                    <FormLabel>State</FormLabel>
                    <Field
                      as={Input}
                      name="state"
                      type="text"
                      placeholder="State"
                    />
                    <ErrorMessage name="state" component="div" />
                  </FormControl>

                  <FormControl mb="10px">
                    <FormLabel>Pincode</FormLabel>
                    <Field
                      as={Input}
                      name="pincode"
                      type="text"
                      placeholder="Pincode"
                    />
                    <ErrorMessage name="pincode" component="div" />
                  </FormControl>

                  <FormControl mb="10px">
                    <Field as={Checkbox} name="is_default">
                      Set as Default Address
                    </Field>
                  </FormControl>

                  <Button mt={4} type="submit">
                    Save
                  </Button>
                </Form>
              )}
            </Formik>
          )}
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddressDetailsModal;
