import React from 'react';
import { useSelector } from "react-redux";
import { Box, Flex, Text, Image, Heading, Link, Button } from '@chakra-ui/react';
import bannerBg from '../../assets/images/about/about-banner-new.jpg'

const AboutBanner = () => {
  const {
    footerBg,
    primaryText,
    secondryText,
    footerColor,
  } = useSelector((state) => state.theme);

  const bannerStyle = {
    backgroundImage: `url(${bannerBg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    height: '100%',
    width: '100%',
  };
  return (
    <Box className='pageBanner' style={{...bannerStyle}}>
        <Box px="20px" color={primaryText} textAlign="center" zIndex="1" position="relative">
          <Heading as="h1" py="4">About Us</Heading>
        </Box>
    </Box>
  );
};

export default AboutBanner;
