// bookingsReducer.js
import {
  GET_BOOKING_LISTS_REQUEST,
  GET_BOOKING_LISTS_SUCCESS,
  GET_BOOKING_LISTS_FAILURE,
  GET_SERVICES_REQUEST,
  GET_SERVICES_SUCCESS,
  GET_SERVICES_FAILURE,
} from "./actionTypes";

const initialState = {
  bookingLists: [],
  services: [],
  loading: false,
  error: null,
};

export const bookingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BOOKING_LISTS_REQUEST:
    case GET_SERVICES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_BOOKING_LISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        bookingLists: action.payload,
      };
    case GET_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        services: action.payload,
      };
    case GET_BOOKING_LISTS_FAILURE:
    case GET_SERVICES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
