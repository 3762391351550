import React, { useEffect } from "react";
import { useRazorpay } from "react-razorpay";

const PaymentComponent = ({ onSuccess, onFailure, onCancel, data }) => {
  const { Razorpay } = useRazorpay();

  useEffect(() => {
    handlePayment();
    // eslint-disable-next-line no-use-before-define
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlePayment = () => {
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: data?.grand_amount, // Amount in paise (e.g., INR 500.00)
      currency: "INR",
      name: "Test Company",
      description: "Test Transaction",
      order_id: data?.order_no, // Valid order ID from backend
      handler: (response) => {
        onSuccess(response.razorpay_payment_id);
      },
      prefill: {
        name: "John Doe",
        email: "john.doe@example.com",
        contact: "9999999999",
      },
      theme: {
        color: "#F37254",
      },
      modal: {
        escape: true, // Allow modal close during payment
        ondismiss: () => {
          // Called when the Razorpay modal is closed
          console.log("Razorpay modal closed");
          onCancel();
        },
      },
    };

    const razorpayInstance = new Razorpay(options);

    try {
      razorpayInstance.open(); // Open Razorpay modal
    } catch (error) {
      console.error("Razorpay instance error:", error);
      onCancel(); // Fallback if opening Razorpay fails
    }

    razorpayInstance.on("payment.failed", (response) => {
      console.error("Payment failed:", response.error);
      onFailure();
    });
  };

  return <div></div>;
};

export default PaymentComponent;
