import React, { useState, useEffect } from "react";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { SearchProvider } from "../api-services";
const SearchComponent = ({ data, onFilter, searchType }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState({
    topRated: false,
    mostPopular: false,
    nearMe: false,
  });

  // Handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Apply filters based on search term and selected filters
  const applyFilters = () => {
    let filteredData = data;
    // if (searchTerm.trim()) {
    //   fetchSearchData();
    //   return;
    //   // filteredData = filteredData.filter((company) =>
    //   //   company.name.toLowerCase().includes(searchTerm.toLowerCase())
    //   // );
    // }

    if (filters.topRated) {
      filteredData = filteredData.filter(
        (company) => parseFloat(company.total_rating) >= 4
      );
    }

    if (filters.mostPopular) {
      filteredData = filteredData.sort(
        (a, b) => b.total_review - a.total_review
      );
    }
    if (searchTerm.trim() === "") {
      onFilter(data);
      return data;
    }

    onFilter(filteredData);
  };

  // Use effect to re-apply filters whenever search term or filters change
  useEffect(() => {
    applyFilters();
  }, [filters]);

  const fetchSearchData = async () => {
    try {
      const response = await SearchProvider(
        { search_type: "business", search_value: searchTerm.trim() },
        searchType
      );
      if (response?.success) {
        onFilter(response?.response?.data);
      } else {
        onFilter([]);
      }

      // setLoading(false);
    } catch (err) {
      // setError(err);
      // setLoading(false);
    }
  };

  return (
    <>
      <InputGroup
        bg="#fff"
        borderRadius="5px"
        maxW="300px"
        borderColor="gray.300"
      >
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="gray.300" />
        </InputLeftElement>
        <Input
          type="text"
          placeholder="Search by name..."
          value={searchTerm}
          onChange={handleSearchChange}
          focusBorderColor="teal.400"
          borderRadius="3px"
          borderColor="#1e88e5"
        />
        <Button
          ml="10px"
          onClick={fetchSearchData}
          background=" #1e88e5"
          color="#fff"
          fontWeight="400"
          borderRadius="3px"
          _hover={{
            bg: "#1776c9",
          }}
        >
          Search
        </Button>
      </InputGroup>
      {/* <Box display="flex" justifyContent="flex-end" px={4}>
        <Menu closeOnSelect={false}>
          <MenuButton
            bg="#fff"
            as={IconButton}
            icon={<BsFilterLeft size="24px" />}
            aria-label="Filter options"
            border="1px solid #0A3331"
          />
          <MenuList>
            <VStack align="start" px={4} py={2}>
              <Checkbox value="topRated" onChange={handleCheckboxChange}>
                Top Rated
              </Checkbox>
              <Checkbox value="mostPopular" onChange={handleCheckboxChange}>
                Most Popular
              </Checkbox>
              <Checkbox value="nearMe" onChange={handleCheckboxChange}>
                Near Me
              </Checkbox>
              <Button colorScheme="blue" size="sm" onClick={applyFilters}>
                Apply
              </Button>
            </VStack>
          </MenuList>
        </Menu>
      </Box> */}
    </>
  );
};

export default SearchComponent;
