import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  HStack,
  Image,
  SimpleGrid,
  Container,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { MdWifiCalling2 } from "react-icons/md";
import { MdOutlineMailOutline } from "react-icons/md";
import {
  getCompanyEmployeeList,
  approveEmployeeApi,
} from "../../../api-services/users";
import NodataFound from "../../../components/NoDataFound";

const CompanyEmployeeList = () => {
  const {
    secondryText,
    primaryText,
    footerColor,
    headingColor,
    rating,
    activeBg,
    redDark,
  } = useSelector((state) => state.theme);

  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [approveEmployeeError, setApproveEmployeeError] = useState(null);

  const [tabType, setTabType] = useState("status");

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        setError(null);
        const data = await getCompanyEmployeeList(tabType);
        if (data.success) {
          setEmployees(data.response.data);
          setError(null);
        } else {
          setError(data?.message);
          setEmployees([]);
        }
      } catch (error) {
        console.error("Error fetching employees:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchEmployees();
  }, [tabType]);

  const approveEmployee = async (employeeId, status) => {
    try {
      const payload = {
        employee_id: employeeId,
        status: status, //1 for accept and 0 for reject
      };
      setLoading(true);

      const response = await approveEmployeeApi(payload);
      if (response.success) {
        // Handle successful approval (e.g., updating the state to remove the approved employee)
        setEmployees((prevEmployees) =>
          prevEmployees.filter((employee) => employee.id !== employeeId)
        );
      } else {
        setApproveEmployeeError(response.message);
        console.error("Failed to approve employee:", response?.message);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error approving employee:", error);
      setApproveEmployeeError(error?.message);
      setLoading(false);
    }
  };
  return (
    <Container maxW="container.xl" className="bookingHistoryPage">
      <Box className="" py="60px">
        <Tabs>
          <TabList
            className="tabsList"
            mx="auto"
            borderRadius="6px"
            border="0"
            gap="8px"
            p="8px"
            justifyContent="center"
            maxW="225px"
            bg={activeBg}
          >
            <Tab border="0" onClick={() => setTabType("status")}>
              Pending
            </Tab>
            <Tab border="0" onClick={() => setTabType("approved")}>
              Approved
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <SimpleGrid
                columns={{ base: 1, sm: 1, md: 2, lg: 4 }}
                spacing={4}
                alignItems="start"
              >
                {employees.map((client) => (
                  <Box
                    key={client.id}
                    p={5}
                    borderWidth={1}
                    borderRadius="md"
                    justifyContent="space-between"
                    background="#f2f2f2"
                  >
                    <HStack>
                      <Image
                        src={client.image}
                        alt={client.name}
                        boxSize="50px"
                        borderRadius="full"
                      />
                      <Box>
                        <Text
                          fontWeight="bold"
                          cursor="pointer"
                          // as={NavLink}
                          // to="/company-employees-details"
                        >
                          {client?.name}
                        </Text>
                        <Text fontSize="sm">{client?.id}</Text>
                      </Box>
                    </HStack>
                    <Box gap="5px" justifyContent="space-between" mt="10px">
                      <Flex
                        alignItems="center"
                        gap="5px"
                        fontWeight="medium"
                        pb="5px"
                        color="#424242"
                        mb="5px"
                        borderBottom="1px solid #d8d8d8"
                      >
                        <MdWifiCalling2 size={20} color={redDark} />
                        {client?.business_phone_no}
                      </Flex>
                      <Flex
                        alignItems="center"
                        gap="5px"
                        fontWeight="medium"
                        color="#424242"
                      >
                        <MdOutlineMailOutline size={20} color={redDark} />
                        {client?.business_email}
                      </Flex>
                    </Box>
                    <Flex justifyContent="space-between" mt="20px" gap="10px">
                      <Button
                        colorScheme="green"
                        flex="1"
                        loading={loading}
                        onClick={() => approveEmployee(client?.id, "accepted")}
                      >
                        Approve
                      </Button>
                      <Button
                        colorScheme="red"
                        flex="1"
                        loading={loading}
                        onClick={() => approveEmployee(client?.id, "rejected")}
                      >
                        reject
                      </Button>
                    </Flex>
                    <Text>{approveEmployeeError}</Text>
                  </Box>
                ))}
              </SimpleGrid>
            </TabPanel>
            <TabPanel>
              <SimpleGrid
                columns={{ base: 1, sm: 1, md: 2, lg: 3 }}
                spacing={4}
                alignItems="start"
              >
                {employees.map((client) => (
                  <Box
                    key={client.id}
                    p={5}
                    borderWidth={1}
                    borderRadius="md"
                    justifyContent="space-between"
                    background="#f2f2f2"
                  >
                    <HStack>
                      <Image
                        src={client.image}
                        alt={client?.name}
                        boxSize="50px"
                        borderRadius="full"
                      />
                      <Box>
                        <Text
                          fontWeight="bold"
                          cursor="pointer"
                          // as={NavLink}
                          // to="/company-employees-details"
                        >
                          {client?.name}
                        </Text>
                        <Text fontSize="sm">{client?.id}</Text>
                      </Box>
                    </HStack>
                    <Box gap="5px" justifyContent="space-between" mt="10px">
                      <Flex
                        alignItems="center"
                        gap="5px"
                        fontWeight="medium"
                        pb="5px"
                        color="#424242"
                        mb="5px"
                        borderBottom="1px solid #d8d8d8"
                      >
                        <MdWifiCalling2 size={20} color={redDark} />
                        {client?.business_phone_no}
                      </Flex>
                      <Flex
                        alignItems="center"
                        gap="5px"
                        fontWeight="medium"
                        color="#424242"
                      >
                        <MdOutlineMailOutline size={20} color={redDark} />
                        {client?.business_email}
                      </Flex>
                    </Box>
                    <Flex justifyContent="space-between" mt="20px" gap="10px">
                      <Button
                        colorScheme="green"
                        flex="1"
                        // as={NavLink}
                        // to="/company-employees-details"
                        disabled={true}
                      >
                        Approved
                      </Button>
                    </Flex>
                  </Box>
                ))}
              </SimpleGrid>
            </TabPanel>
          </TabPanels>
        </Tabs>
        {/* <Text>{error}</Text> */}
        {error !== null && <NodataFound message={error} />}
      </Box>
    </Container>
  );
};

export default CompanyEmployeeList;
