import axios from "axios";
import { API_URL } from "./baseURL";

export const aboutUsApi = async (userData) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${API_URL}/about-us-content`, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const faqApi = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${API_URL}/faq-content`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const contactApi = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    console.log("token", token);

    const response = await axios.post(`${API_URL}/contact-us`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};
export const submitBankDetailsApi = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    console.log("token", token);

    const response = await axios.post(
      `${API_URL}/provider/bank-detail`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};
export const fetchBankDetailsApi = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${API_URL}/provider/bank-detail`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getBookingListsApi = async (param) => {
  const response = await axios.get(`/provider/getBookingLists?param=${param}`);
  return response;
};

export const fetchProfileDetailsAPI = async () => {
  try {
    const token = localStorage.getItem("token");
    const user_type = localStorage.getItem("user_type");

    const url =
      user_type === "user"
        ? `${API_URL}/customer/user-profile`
        : `${API_URL}/provider/provider-profile`;
    const response = await axios.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const fetchNotifications = async () => {
  try {
    const token = localStorage.getItem("token");
    const user_type = localStorage.getItem("user_type");

    const url =
      user_type === "user"
        ? `${API_URL}/customer/notifications`
        : `${API_URL}/provider/notifications`;
    const response = await axios.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};
export const getCompanyBookings = async (bookingType) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      `${API_URL}/provider/getBookingLists?param=${bookingType}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};
// http://triderapp.com/api/provider/getBookingDetail/1
export const getCompanyBookingDetails = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      `${API_URL}/provider/getBookingDetail/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getCompanyEmployeeList = async (tabType) => {
  try {
    const token = localStorage.getItem("token");
    const companyId = 183;
    let endPoint = "getEmployeeList";
    if (tabType === "status") {
      endPoint = `getEmployeeList?param=${tabType}`;
    }
    const response = await axios.get(`${API_URL}/company/${endPoint}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};
export const getBookingCompanyEmployeeLists = async (companyId) => {
  try {
    const token = localStorage.getItem("token");
    // const companyId = 183;
    const companyId = localStorage.getItem("userId");

    const response = await axios.get(
      `${API_URL}/getBookingCompanyEmployeeLists`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};
export const approveEmployeeApi = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    // localStorage.getItem("companyId");
    const response = await axios.post(
      `${API_URL}/company/acceptOrRejectEmployeeAccount`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const postAcceptAndReject = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    // localStorage.getItem("companyId");
    const response = await axios.post(
      `${API_URL}/booking_accept_or_reject`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const customerProfileUpdate = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    // localStorage.getItem("companyId");
    const response = await axios.post(
      `${API_URL}/customer/edit-profile`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const startJob = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    // localStorage.getItem("companyId");
    const response = await axios.post(`${API_URL}/start_job`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    return error;
  }
};
export const endJob = async (payload) => {
  try {
    // console.log("payload=========", payload);
    const token = localStorage.getItem("token");
    const response = await axios.post(`${API_URL}/end_job`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const getEarnings = async (payload) => {
  try {
    //
    console.log("data========= call");

    const token = localStorage.getItem("token");
    //localStorage.getItem("token");
    const response = await axios.post(`${API_URL}/provider/earnings`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("data========= response", response?.data);
    return response?.data;
  } catch (error) {
    console.log("data=========response", error);

    return error;
  }
};
