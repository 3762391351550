// WishListCompanyCard.js
import React, { useState } from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  Icon,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { FaHeart, FaRegHeart, FaUser, FaMapMarkerAlt } from "react-icons/fa";
import { ImUserTie } from "react-icons/im";
import Rating from "../../components/rating"; // Update the path if necessary
import { manageHeart } from "../../api-services/booking"; // Import your heart management API function

const WishListCompanyCard = ({
  company,
  onClick,
  primaryText,
  secondryText,
  bodyTextColor,
  lightBlue,
  refresh,
}) => {
  const [isHearted, setIsHearted] = useState(company?.is_favourite === 1);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const handleHeartClick = async (e) => {
    try {
      setLoading(true);
      await manageHeart(company?.business_address?.user_id); // Call the heart API with provider ID
      setIsHearted(!isHearted); // Toggle heart status
      refresh();
    } catch (error) {
      console.error("Error managing heart:", error);
      toast({
        title: "Error",
        description: "There was an error updating the favorite status.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      onClick();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      borderRadius="30px"
      p={4}
      bg={lightBlue}
      className="bookingCardInner"
      mb="20px"
      cursor="pointer"
      onClick={onClick}
      border="1px solid #1E88E5"
      transition="all .3s"
      _hover={{
        boxShadow: "#84c1f3 0px 0px 8px",
      }}
    >
      <Flex mb={4} gap="10px" justifyContent="space-between">
        <Box w="100%">
          <Box
            overflow="hidden"
            borderRadius="20px"
            mb="8px"
            position="relative"
          >
            <Image
              maxW="100%"
              w="100%"
              h="220px"
              objectFit="cover"
              src={company?.image || "default-image-url"}
              alt="Company Image"
              transition="all .3s"
              _hover={{
                transform: "scale(1.1)",
              }}
            />
            <Box
              className="wishListIcon"
              position="absolute"
              right="15px"
              top="15px"
              color={primaryText}
              onClick={(e) => {
                handleHeartClick();
              }}
            >
              <FaHeart size={20} color={true ? "red" : "gray"} />
            </Box>
          </Box>
          <Box flex="1">
            <Box>
              <Text fontWeight="bold" color={bodyTextColor} cursor="pointer">
                {company?.name}
              </Text>
              <Flex alignItems="center">
                <Rating
                  total_rating={company?.total_rating}
                  reviewsCount={company?.total_review}
                  bodyTextColor={bodyTextColor}
                />
              </Flex>
            </Box>
          </Box>
        </Box>
      </Flex>
      <Stack spacing={2} mb={4}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
          gap="5px"
        >
          <Flex gap="5px" alignItems="center">
            <Icon as={FaUser} mr={2} color={secondryText} />
            <Text color={bodyTextColor}>
              No. of services :{" "}
              <Text as="span" fontWeight="600">
                {company.total_service}
              </Text>
            </Text>
          </Flex>
          <Flex gap="5px" alignItems="center">
            <Icon as={FaMapMarkerAlt} mr={2} color={secondryText} />
            <Text color={bodyTextColor}>{company.location}</Text>
          </Flex>
        </Flex>
        <Flex gap="5px" alignItems="center">
          <ImUserTie color={secondryText} mr={2} />
          <Flex alignItems="center" ml="5px" color={bodyTextColor}>
            <Text>No. Of employees : </Text>
            <Text fontWeight="600" ml="5px">
              {company.total_employees}
            </Text>
          </Flex>
        </Flex>
      </Stack>
    </Box>
  );
};

export default WishListCompanyCard;
