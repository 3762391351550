import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Text,
  VStack,
  HStack,
  NumberInput,
  NumberInputField,
  Heading,
  Stack,
  Select,
  Image,
} from "@chakra-ui/react";

const ServiceDetails = ({ service, onTotalsChange , chooseProvider , businessTax , plateformTax }) => {
  const [quantity, setQuantity] = useState(1);
  const [duration, setDuration] = useState(0);
  const [durationType, setDurationType] = useState("hours");
  const [selectedService, setSelectedService] = useState(null);
  const [selectedValues, setSelectedValues] = useState(null);
  const [quantityError, setQuantityError] = useState("");
  const [durationError, setDurationError] = useState("");

  const { secondryText, redDark } = useSelector((state) => state.theme);
  const serviceId = localStorage.getItem("serviceId");

  useEffect(() => {
    const service = JSON.parse(localStorage.getItem("service"));
    setSelectedService(service);
    console.log(chooseProvider)
    console.log(service?.[chooseProvider])
    setSelectedValues(service?.[chooseProvider]?.[0]);
  }, [chooseProvider]);

  // Validation function for quantity and duration
  const validateInputs = () => {
    let isValid = true;

    if (quantity < 1 || isNaN(quantity)) {
      setQuantityError("Quantity must be at least 1.");
      isValid = false;
    } else {
      setQuantityError("");
    }

    if (duration < 1 || isNaN(duration)) {
      setDurationError("Duration must be at least 1.");
      isValid = false;
    } else {
      setDurationError("");
    }
    return isValid;
  };

  // Call this function when performing calculations or submitting
  const calculateTotals = () => {
    if (!validateInputs()) return;
    let findValue = duration;
    if (durationType === "days") {
      findValue = "9";
    }
    const selectedValues = selectedService?.[chooseProvider]?.find(
      (n) => n?.shift == findValue
    );
    setSelectedValues(selectedValues);
    let hoursRate = Number(selectedValues?.price || 0);
    let total = 0;

    if (durationType === "hours") {
      total = hoursRate * quantity;
    } else {
      total = hoursRate * quantity * duration;
    }
    const tax = businessTax || 0;
    const platformFeePercentage = plateformTax || 0;
    const totalTaxAmount = total * (tax / 100);

    const platformFee = total * (platformFeePercentage / 100);

    const grandTotal = total + totalTaxAmount + platformFee;

    if (onTotalsChange) {
      let hours = 0;
      let days = 0;
      if (durationType === "hours") {
        hours = duration;
      } else {
        days = duration;
      }
      onTotalsChange(total, totalTaxAmount, grandTotal, tax, {
        days,
        hours,
        qty: quantity,
        id: Number(serviceId),
        platformFee
      });
    }
  };

  useEffect(() => {
    calculateTotals();
    if (durationType === "days") {
      setDuration(1); // Automatically set duration to 1 when it's not "hours"
    }
  }, [quantity, duration, durationType]);

  return (
    <>
      {selectedService === null ? (
        <>loading.....</>
      ) : (
        <VStack
          spacing={6}
          align="start"
          borderRadius="md"
          p={6}
          mb={6}
          boxShadow="md"
          bgGradient="linear(to-r, gray.50, white)"
          _hover={{ boxShadow: "lg" }}
          transition="box-shadow 0.3s ease"
          background="#f6f6f6"
          className="bookingservices_Info"
          flexDirection={{ base: "column", md: "row" }}
          alignItems="center"
          gap="20px"
        >
          <Image src={selectedService?.image} boxSize="100px"></Image>

          <Box>
            <Heading
              size="md"
              color={secondryText}
              mb="10px"
              textAlign={{ base: "center", md: "left" }}
            >
              {service?.services[0]?.service_name}
            </Heading>
            <Stack
              direction={{ base: "row" }}
              spacing={4}
              w="100%"
              flexWrap="wrap"
            >
              <HStack>
                <Select
                  value={durationType}
                  onChange={(e) => setDurationType(e.target.value)}
                  maxW="120px"
                >
                  <option value="hours">Hours</option>
                  <option value="days">Days</option>
                </Select>
              </HStack>
              <HStack>
                <Text color={secondryText}>Manpower:</Text>
                <Select
                  value={quantity}
                  onChange={(e) => setQuantity(parseInt(e.target.value))}
                  maxW="60px"
                  color={secondryText}
                  borderRadius="5px"
                >
                  {[...Array(5).keys()].map((num) => (
                    <option key={num + 1} value={num + 1}>
                      {num + 1}
                    </option>
                  ))}
                </Select>
                {quantityError && <Text color="red">{quantityError}</Text>}
              </HStack>
              <HStack>
                <Text color={secondryText}>Duration:</Text>
                {durationType === "hours" ? (
                  <Select
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                    maxW="120px"
                  >
                    <option value={0}>0</option>
                    {selectedService?.[chooseProvider]
                      ?.filter((n) => n.shift !== '1') // Filter out the value 1
                      ?.sort((a, b) => a.shift - b.shift) // Sorting by shift value
                      .map((n) => {
                        return (
                          <option key={n.shift} value={n.shift}>
                            {n.shift}
                          </option>
                        );
                      })}
                  </Select>
                ) : (
                  <NumberInput
                    defaultValue={1}
                    min={1}
                    maxW="110px"
                    value={`${duration} Day ( 9 hrs )`}
                    onChange={(e) => setDuration(1)}
                    color={secondryText}
                    borderRadius="5px"
                    isReadOnly
                  >
                    <NumberInputField
                      p="5px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    />
                  </NumberInput>
                )}
                {durationError && <Text color="red">{durationError}</Text>}
              </HStack>
              <HStack>
                <Text color={secondryText}>Cost:</Text>
                <Text color={redDark} fontWeight="bold">
                  ₹ {duration > 0 ? selectedValues?.price : 0}
                </Text>
              </HStack>
            </Stack>
          </Box>
        </VStack>
      )}
    </>
  );
};

export default ServiceDetails;
