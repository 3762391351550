import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import servicebg from "../../assets/images/home/service-bg.png";
import { FaArrowRightLong } from "react-icons/fa6";
import {
  Box,
  //   Flex,
  Text,
  Heading,
  Button,
  Container,
  SimpleGrid,
  Image,
} from "@chakra-ui/react";
// import { FaBeer } from "react-icons/fa";
// import { IoIosHome } from "react-icons/io";
// import { RiCustomerService2Fill } from "react-icons/ri";
import { allServices } from "../../api-services";

const ServiceHome = () => {
  const navigate = useNavigate();

  const {
    serviceBgHome,
    primaryText,
    secondryText,
    serviceColor,
    textColor,
    activeBg,
  } = useSelector((state) => state.theme);

  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const data = await allServices();
        setServices(data?.response);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []);

  const handleButtonClick = (service) => {
    const userType = localStorage.getItem("user_type");
    localStorage.setItem("serviceId", service?.id);
    localStorage.setItem("service", JSON.stringify(service));

    if (userType === null) {
      // navigate("/customer-login");
      localStorage.setItem("service", JSON.stringify(service));
      // navigate(
      //   `/customer-login?name=${(service?.name || "").split(` `).join(`_`)}`
      // );
      navigate(`/service?name=${(service?.name || "").split(` `).join(`_`)}`, {
        state: service,
      });
    } else {
      navigate(`/service?name=${(service?.name || "").split(` `).join(`_`)}`, {
        state: service,
      });
    }
  };
  return (
    <Box
      className="homeService section_spacingY"
      // bg={serviceBgHome}
    >
      <Container maxW="container.xl">
        <Box maxW="800px" mx="auto" textAlign="center" mb="40px">
          <Heading as="h2" textAlign="center" mb="20px">
            What Services We Offer
          </Heading>
          <Text>
            At Trider, we proudly provide a diverse array of premium services
            tailored to simplify your life, enhance safety, and increase
            convenience. Our offerings address both personal and business needs,
            ensuring you receive the right support exactly when you need it.
          </Text>
        </Box>
        <SimpleGrid columns={{ base: 1, sm: 2, lg: 3, xl: 4 }} spacing={8}>
          {services.map((card, index) => (
            <Box
              className="home_serives card"
              key={index}
              p={5}
              overflow="hidden"
              textAlign="center"
              _hover={{
                boxShadow: "0px 0px 8px 0px rgb(0 0 0 / 26%)",
                transform: "translate(0px, -10px)",
              }}
              data-aos="fade-right"
            >
              <Box
                className="iconWr"
                as="span"
                fontSize="2xl"
                display="block"
                mb={3}
                margin="0 auto 20px"
              >
                <Box
                  as="span"
                  display="inline-block"
                  className="serviceIconAnimation"
                >
                  <Image
                    src={card?.image}
                    alt="Service Icon"
                    boxSize="80px" // Adjust this to your desired size (e.g., 50px by 50px)
                    objectFit="cover" // Ensures the image covers the box while maintaining aspect ratio
                  />
                </Box>
              </Box>
              <Box padding="0px 24px 24px 24px">
                <Heading as="h5" size="lg" mb={3} color={serviceColor}>
                  {card.name}
                </Heading>
                <Text mb={4} color={textColor}>
                  {card.description}
                </Text>
                <Button
                  // bg={activeBg}
                  bg="#08c71f"
                  color={primaryText}
                  fontWeight="500"
                  fontSize="14px"
                  height="auto"
                  padding="10px 15px"
                  borderRadius="5px"
                  _hover={{
                    bg: "#069718",
                  }}
                  onClick={() => handleButtonClick(card)}
                >
                  Book now
                </Button>
              </Box>
            </Box>
          ))}
        </SimpleGrid>
        {/* <Box textAlign="center">
          <Button className="viewAllBtn"  gap="5px" mt="20px">
          View All
          <FaArrowRightLong />
          </Button>
        </Box> */}
      </Container>
    </Box>
  );
};

export default ServiceHome;
