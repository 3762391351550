// CompanyCard.js
import React, { useState } from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  Icon,
  Stack,
  Tooltip,
  Button,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import { FaHeart, FaUser, FaMapMarkerAlt, FaRegHeart } from "react-icons/fa";
import { ImUserTie } from "react-icons/im";
import Rating from "../../components/rating"; // Update the path if necessary
import { manageHeart } from "../../api-services/booking"; // Import your heart management API function
import { FaEye } from "react-icons/fa";
import { redirect, useNavigate } from "react-router-dom";

const formatAddress = (address) => {
  const {
    address_line_one,
    address_line_two,
    city,
    state,
    pincode,
    latitude,
    longitude,
  } = address;

  return (
    <>
      {address_line_one}, {address_line_two}, {city}, {state} - {pincode}.
      <br />
      Coordinates: ({latitude}, {longitude})
    </>
  );
};

const CompanyCard = ({
  company,
  handleOpenModal,
  type,
  onClick,
  primaryText,
  footerColor,
  mediumGray,
  secondryText,
  blackmedium,
  bodyTextColor,
  acitveBorder,
  lightBlue,
}) => {
  const [isHearted, setIsHearted] = useState(() =>
    company?.is_favourite === 1 ? true : false
  ); // Track heart status
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleHeartClick = async () => {
    try {
      setLoading(true);
      await manageHeart(company.id); // Call the heart API with company ID
      setIsHearted(!isHearted); // Toggle heart status
    } catch (error) {
      console.error("Error managing heart:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRedirect = (e, id) => {
    console.log(company);

    navigate(`/provider-details?id=${id}&parm=0`);
  };

  return (
    <Box
      // border="1px"
      // borderColor={acitveBorder}
      // borderRadius="30px"
      overflow="hidden"
      p={4}
      bg={lightBlue}
      className="bookingCardInner companyslider"
      mb="20px"
      cursor="pointer"
      onClick={onClick}
      // border="1px solid #1E88E5"
      borderRadius="0"
      transition="all .3s"
      _hover={{
        boxShadow: "#84c1f3 0px 0px 8px",
      }}
    >
      <Flex mb={1} gap="10px" justifyContent="space-between">
        <Box w="100%">
          <Box
            overflow="hidden"
            // borderRadius="20px"
            // mb="8px"
            position="relative"
            // _before={{
            //   position: "absolute",
            //   content: "''",
            //   background: "#090454",
            //   width: "100%",
            //   height: "100%",
            //   left: 0,
            //   top: 0,
            //   opacity: .4,
            // }}
          >
            <Image
              maxW="100%"
              w="100%"
              h="220px"
              objectFit="cover"
              src={company.image || "default-image-url"} // Add a fallback image URL if needed
              alt="Company Image"
              transition="all .3s"
              _hover={{
                transform: "scale(1.1)",
              }}
            />
            <Box
              className="wishListIcon"
              position="absolute"
              right="15px"
              top="15px"
              color={primaryText}
              onClick={(e) => {
                e.stopPropagation(); // Prevents triggering parent onClick
                handleHeartClick();
              }}
            >
              <Tooltip
                label={isHearted ? "Remove from favorites" : "Add to favorites"}
                aria-label="A tooltip"
              >
                {isHearted ? (
                  <FaHeart size={20} color="red" />
                ) : (
                  <FaRegHeart size={20} color="gray" />
                )}
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Flex>
      <Box p="10px 10px 15px">
        <Box flex="1" mb="10px">
          <Box>
            <Text
              fontWeight="bold"
              color={bodyTextColor}
              cursor="pointer"
              onClick={(e) => {
                e.stopPropagation(); // Prevents triggering parent onClick
                //   onHeaderClick(company);
              }}
              className="companyName providerName"
            >
              {company.name}
            </Text>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              className="rating"
            >
              <Rating
                total_rating={company.total_rating}
                reviewsCount={company.total_review}
              />
              <FaEye
                style={{ color: "red" }}
                // onClick={(e) => {
                //   // e.stopPropagation(); // Prevent triggering parent click events
                //   handleOpenModal(e, company.id); // Call the function passed from the parent component
                // }}
                onClick={(e) => {
                  // e.stopPropagation(); // Prevent triggering parent click events
                  handleRedirect(e, company.id, company); // Call the function passed from the parent component
                }}
              />
            </Flex>
          </Box>
        </Box>
        <Stack spacing={2} mb={4}>
          <Flex
            alignItems="flex-start"
            justifyContent="space-between"
            flexWrap="wrap"
            gap="5px"
            w="100%"
          >
            <Flex
              gap="5px"
              // alignItems="baseline"
              alignItems="flex-start"
              borderBottom="1px solid #1f1f1f24"
              w="100%"
              py="8px"
            >
              <Icon
                className="iconwr"
                as={FaUser}
                mr={2}
                color={secondryText}
              />
              <Text color={blackmedium}>
                No. of services :{" "}
                <Text as="span" fontWeight="600">
                  {company.total_service}
                </Text>
              </Text>
            </Flex>

            <Flex
              gap="5px"
              // alignItems="baseline"
              alignItems="flex-start"
              borderBottom="1px solid #1f1f1f24"
              w="100%"
              py="8px"
            >
              <Icon
                className="iconwr"
                as={FaMapMarkerAlt}
                mr={2}
                color={secondryText}
              />
              <Text color={blackmedium}>
                {/* {formatAddress(company?.business_address)} */}
                {company?.business_address
                  ? (() => {
                      const {
                        address_line_one,
                        address_line_two,
                        city,
                        state,
                        pincode,
                      } = company.business_address;
                      const fullAddress = `${address_line_one || ""}, ${
                        address_line_two || ""
                      }, ${city || ""}, ${state || ""}, ${pincode || ""}`;
                      return fullAddress.length > 40
                        ? `${fullAddress.substring(0, 40)}...`
                        : fullAddress;
                    })()
                  : ""}
              </Text>
            </Flex>
          </Flex>
          {type !== "freelancer" ? (
            <Flex
              gap="5px"
              // alignItems="baseline"
              alignItems="flex-start"
              borderBottom="1px solid #1f1f1f24"
              py="8px"
            >
              <Icon
                className="iconwr"
                as={ImUserTie}
                color={secondryText}
                mr={2}
              />
              <Flex alignItems="center" ml="5px">
                <Text>No. Of employees : </Text>
                <Text fontWeight="600" ml="5px">
                  {company?.total_employees}
                </Text>
              </Flex>
            </Flex>
          ) : null}
          {type !== "freelancer" && (
            <Flex
              gap="5px"
              // alignItems="baseline"
              alignItems="flex-start"
              // borderBottom="1px solid #1f1f1f24"
              w="100%"
              py="10px"
              justifyContent="center"
            ></Flex>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default CompanyCard;
