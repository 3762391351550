import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import AboutBanner from "../components/About/Banner";
import {
  Box,
  Heading,
  SimpleGrid,
  Container,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Text,
  Flex,
} from "@chakra-ui/react";
import bannerBg from "../assets/images/home/slider/slide1.jpg";
import faqImage from "../assets/images/faq.png";
import { faqAction } from "../redux/users-redux/actions";

const FaqPage = () => {
  const { footerBg, primaryText, secondryText, iconGreen } = useSelector(
    (state) => state.theme
  );
  const { faqData } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const bannerStyle = {
    backgroundImage: `url(${bannerBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100%",
    width: "100%",
  };

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        dispatch(faqAction());
      } catch (error) {
        console.error("Error fetching FAQ content:", error);
      }
    };

    fetchFaqs();
  }, []); // Empty dependency array ensures this runs once on mount

  const colorPrimary = "#000000";
  const colorActive = "#F36C29";
  const gray30 = "#F5F5F5";

  return (
    <Box>
      <Box className="pageBanner" style={{ ...bannerStyle }}>
        <Box
          px="20px"
          color={primaryText}
          textAlign="center"
          zIndex="1"
          position="relative"
        >
          <Heading as="h1" py="4">
            FAQ
          </Heading>
        </Box>
      </Box>
      <Box py="60px">
        <Container maxW="container.xl" className="faqSection">
          <Heading as="h2" mb="40px">
            Questions? Look here (FAQs)
          </Heading>
          <Flex flexDir="column" gap="30px">
            {/* <Box>
              <Image className="" W="100%" src={faqImage} maxW="500px"></Image>
            </Box> */}
            <Box>
              <Accordion allowMultiple>
                <SimpleGrid
                  columns={{ base: 1, sm: 1, md: 2, xl: 2 }}
                  gap="20px"
                >
                  {faqData?.map((faq, index) => (
                    <AccordionItem
                      key={index}
                      sx={{
                        bg: gray30,
                        borderRadius: "14px",
                        border: "none",
                      }}
                    >
                      <h2>
                        <AccordionButton
                          sx={{
                            bg: "transparent",
                            border: "none",
                            boxShadow: "none",
                            fontWeight: "600",
                            _expanded: {
                              fontWeight: "600",
                              color: colorPrimary,
                            },
                          }}
                        >
                          <Flex flex="1" textAlign="left" align="center">
                            <Text>{faq.question}</Text>
                          </Flex>
                          <Box
                            as="span"
                            sx={{
                              color: colorPrimary,
                              transition: "all 0.3s",
                            }}
                          >
                            <AccordionIcon />
                          </Box>
                        </AccordionButton>
                      </h2>
                      <AccordionPanel
                        pb={4}
                        sx={{ border: "none", fontSize: "16px" }}
                      >
                        {parse(faq.answer)}
                      </AccordionPanel>
                    </AccordionItem>
                  ))}
                </SimpleGrid>
              </Accordion>
            </Box>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};

export default FaqPage;
