import {
  GET_ADMIN_FAILURE,
  GET_ADMIN_REQUEST,
  GET_ADMIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  UPDATE_ADMIN_FAILURE,
  UPDATE_ADMIN_REQUEST,
  UPDATE_ADMIN_SUCCESS,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  FORGOT_PASS_SUCCESS,
  SIGNUP_RESET,
  LOGIN_RESET,
} from "./actionTypes";
import {
  forgotPassword,
  loginAPI,
  resetPassword,
  signupCompany,
  signupCompanyEmployee,
  signupFreelancer,
  signupUser,
  verifyOtp,
  aboutUsApi,
  faqApi,
} from "../../api-services/auth"; // Adjust the import path
import { setProfileDetails } from "../users-redux/actions";

// Action creators
export const loginRequest = () => ({ type: LOGIN_REQUEST, payload: null });
export const loginSuccess = (userData) => ({
  type: LOGIN_SUCCESS,
  payload: userData,
});
export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});
export const logout = () => ({ type: LOGOUT });

// Action creator for getting admin information
export const getAdminRequest = () => ({
  type: GET_ADMIN_REQUEST,
});

export const getAdminSuccess = (adminData) => ({
  type: GET_ADMIN_SUCCESS,
  payload: adminData,
});

export const getAdminFailure = (error) => ({
  type: GET_ADMIN_FAILURE,
  payload: error,
});

// Action creator for updating admin information
export const updateAdminRequest = () => ({
  type: UPDATE_ADMIN_REQUEST,
});

export const updateAdminSuccess = (updatedAdminData) => ({
  type: UPDATE_ADMIN_SUCCESS,
  payload: updatedAdminData,
});

export const updateAdminFailure = (error) => ({
  type: UPDATE_ADMIN_FAILURE,
  payload: error,
});

export const forgotPasswordSuccess = (data) => ({
  type: FORGOT_PASS_SUCCESS,
  payload: data,
});

////////// SIGNUP ACTION TYPES /////////
export const signupRequest = () => ({ type: SIGNUP_REQUEST });
export const signupSuccess = (userData) => ({
  type: SIGNUP_SUCCESS,
  payload: userData,
});
export const signupFailure = (error) => ({
  type: SIGNUP_FAILURE,
  payload: error,
});

export const resetSignup = () => ({
  type: SIGNUP_RESET,
});
export const resetLogin = () => ({
  type: LOGIN_RESET,
});

export const signupAction = (userData) => async (dispatch) => {
  dispatch(signupRequest());
  try {
    const data = await signupUser(userData);
    if (data?.success) {
      localStorage.setItem("userId", data?.response?.id);
      // localStorage.setItem("token", data.token);
      // localStorage.setItem("isLogin", true);
      if (userData?.user_type === "user") {
        localStorage.setItem("user_type", userData?.user_type);
      } else {
        localStorage.setItem("user_type", data?.response?.user_type);
      }

      if (userData?.companyId) {
        localStorage.setItem("companyId", userData.companyId);
      }
      dispatch(signupSuccess(data));
      return data;
    } else {
      dispatch(signupFailure(data));
      return data;
    }
  } catch (error) {
    dispatch(signupFailure(error?.response?.data));
  }
};

export const signupCompanyEmployeeAction =
  (userData, companyIdIfExist) => async (dispatch) => {
    dispatch(signupRequest());
    console.log("userData actions signupCompanyEmployeeAction", userData);

    try {
      const user_id = localStorage.getItem("userId");
      const companyId = companyIdIfExist ?? "";
      const serviceIds = userData?.service ?? [];

      const formData = new FormData();

      formData.append("user_id", user_id);
      formData.append("company_id", companyId);
      formData.append("user_type", "employee");

      if (userData?.business_profile?.business_images?.[0]) {
        formData.append(
          "business_images[0]",
          userData?.business_profile?.business_images?.[0]
        );
      }
      if (typeof userData?.business_profile?.back_aadhaar_card !== "string") {
        formData.append(
          "back_aadhaar_card",
          userData?.business_profile?.back_aadhaar_card
        );
      }
      if (typeof userData?.business_profile?.front_aadhaar_card !== "string") {
        formData.append(
          "front_aadhaar_card",
          userData?.business_profile?.front_aadhaar_card
        );
      }
      // if (typeof userData?.businessImages?.[0] !== "string") {
      //   formData.append("business_images[0]", userData?.businessImages?.[0]);
      // }

      formData.append(
        "business_name",
        userData?.business_profile?.business_name
      );
      formData.append("business_email", userData?.business_profile?.email);
      // formData.append("availability", userData?.availability);
      formData.append(
        "business_phone_no",
        userData?.business_profile?.business_phone_no
      );
      formData.append("year_of_exp", userData?.business_profile?.year_of_exp);
      serviceIds.forEach((item, index) => {
        formData.append(`service_id[${index}]`, item);
      });
      // formData.append("service_id[0]", userData?.serviceId);
      // formData.append("service", userData?.service);
      // formData.append("subService", userData?.subService);
      formData.append("bio", userData?.bio);
      formData.append("address_line_one", userData?.address ?? "");
      formData.append("address_line_two", userData?.address_line_two ?? "");
      formData.append("city", userData?.city ?? "");
      formData.append("state", userData?.state ?? "state");
      formData.append("pincode", userData?.pincode ?? "");
      formData.append("latitude", userData?.latitude ?? "");
      formData.append("longitude", userData?.longitude ?? "");

      const response = await signupCompanyEmployee(formData);
      localStorage.setItem("user_type", response?.data?.user_type);
      localStorage.setItem("status", response?.data?.status);
      dispatch(signupSuccess(response));
      return response;
    } catch (error) {
      console.log("error========>", error);
      dispatch(signupFailure(error?.response?.data));
      return error;
    }
  };

export const signupCompanyAction = (userData) => async (dispatch) => {
  dispatch(signupRequest());
  console.log("userData actions", userData);
  try {
    const user_id = localStorage.getItem("userId");
    const serviceIds = userData?.service ?? [];

    const formData = new FormData();

    formData.append("user_id", user_id);
    formData.append("user_type", "company");
    if (userData?.business_profile?.business_images?.[0]) {
      formData.append(
        "business_images[0]",
        userData?.business_profile?.business_images?.[0]
      );
    }
    formData.append("business_name", userData?.business_profile?.business_name);
    formData.append("business_email", userData?.business_profile?.email);
    formData.append(
      "business_phone_no",
      userData?.business_profile?.business_phone_no
    );
    formData.append("year_of_exp", userData?.business_profile?.year_of_exp);
    formData.append("gst_number", userData?.business_profile?.gst_number ?? "");
    formData.append(
      "pan_card_number",
      userData?.business_profile?.pan_card_number ?? ""
    );
    // if (typeof userData?.businessImages?.[0] !== "string") {
    //   formData.append("business_images[0]", userData?.businessImages?.[0]);
    // }
    // if (userData?.businessImages[1]) {
    //   formData.append("business_images[1]", userData?.businessImages[1]);
    // }
    // if (typeof userData?.aadhaarBack !== "string") {
    //   formData.append("back_aadhaar_card", userData?.aadhaarBack);
    // }
    // if (typeof userData?.aadhaarFront !== "string") {
    //   formData.append("front_aadhaar_card", userData?.aadhaarFront);
    // }
    serviceIds.forEach((item, index) => {
      formData.append(`service_id[${index}]`, item);
    });
    formData.append("bio", userData?.bio);
    formData.append("address_line_one", userData?.address ?? "");
    formData.append("address_line_two", userData?.address_line_two ?? "");
    formData.append("state", userData?.state ?? "state");
    formData.append("city", userData?.city ?? "");
    formData.append("pincode", userData?.pincode ?? "");
    formData.append("latitude", userData?.latitude ?? "");
    formData.append("longitude", userData?.longitude ?? "");
    const response = await signupCompany(formData);
    console.log("actions response", response);
    localStorage.setItem("user_type", response?.data?.user_type);
    localStorage.setItem("status", response?.data?.status);

    dispatch(signupSuccess(response));
    return response;
  } catch (error) {
    dispatch(signupFailure(error?.response?.data));
    return error;
  }
};

export const signupFreelancerAction = (userData) => async (dispatch) => {
  dispatch(signupRequest());
  console.log("userData actions Freelancer", userData);
  console.log(
    "userData?.business_images?.[0]",
    userData?.business_profile?.business_images?.[0]
  );
  try {
    const user_id = localStorage.getItem("userId");
    const serviceIds = userData?.service ?? [];

    const formData = new FormData();

    formData.append("user_id", user_id);
    formData.append("user_type", "freelancer");
    formData.append("back_aadhaar_card", userData?.aadhaarBack);
    formData.append("front_aadhaar_card", userData?.aadhaarFront);
    if (userData?.business_profile?.business_images?.[0]) {
      formData.append(
        "business_images[0]",
        userData?.business_profile?.business_images?.[0]
      );
    }
    if (typeof userData?.business_profile?.back_aadhaar_card !== "string") {
      formData.append(
        "back_aadhaar_card",
        userData?.business_profile?.back_aadhaar_card
      );
    }
    if (typeof userData?.business_profile?.front_aadhaar_card !== "string") {
      formData.append(
        "front_aadhaar_card",
        userData?.business_profile?.front_aadhaar_card
      );
    }
    // // if (typeof userData?.businessImages?.[0] !== "string") {
    // //   formData.append("business_images[0]", userData?.businessImages[0]);
    // // }

    formData.append("business_name", userData?.business_profile?.business_name);
    formData.append("business_email", userData?.business_profile?.email);
    formData.append(
      "business_phone_no",
      userData?.business_profile?.business_phone_no
    );
    formData.append("year_of_exp", userData?.business_profile?.year_of_exp);
    serviceIds.forEach((item, index) => {
      formData.append(`service_id[${index}]`, item);
    });
    formData.append("bio", userData?.bio);
    formData.append("address_line_one", userData?.address ?? "");
    formData.append("address_line_two", userData?.address_line_two ?? "");
    formData.append("city", userData?.city ?? "");
    formData.append("state", userData?.state ?? "");
    formData.append("pincode", userData?.pincode ?? "");
    formData.append("latitude", userData?.latitude ?? "");
    formData.append("longitude", userData?.longitude ?? "");

    const response = await signupFreelancer(formData);
    localStorage.setItem("user_type", response?.data?.user_type);
    localStorage.setItem("status", response?.data?.status);

    dispatch(signupSuccess(response));
    return response;
  } catch (error) {
    dispatch(signupFailure(error?.response?.data));
    return error;
  }
};

export const loginAction = (userData) => async (dispatch) => {
  try {
    dispatch(loginRequest());
    const data = await loginAPI(userData);
    if (data?.success) {
      localStorage.setItem("token", data.token);
      localStorage.setItem("user_type", data?.response?.user_type);
      localStorage.setItem("userId", data?.response?.id);
      localStorage.setItem("isLogin", true);
      localStorage.setItem("status", data?.status);
      dispatch(loginSuccess(data));
      return data;
    } else {
      dispatch(loginFailure(data));
    }
  } catch (error) {
    dispatch(loginFailure(error.message));
  }
};

export const logoutAction = (userData) => async (dispatch) => {
  try {
    localStorage.clear();
    dispatch(setProfileDetails(null)); //clear redux state:
    // removeItem("token");
    dispatch({
      type: LOGOUT,
      payload: null,
    });
  } catch (error) {
    dispatch(loginFailure(error.message));
  }
};
export const forgotPassAction = (userData) => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append("email", userData);
    const data = await forgotPassword(formData);
    if (data?.success) {
      dispatch(forgotPasswordSuccess(data?.response));
    }
    return data;
  } catch (error) {
    throw error;
    // dispatch(signupFailure(error.message));
  }
};

export const verifyOtpAction = (userData) => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append("user_id", userData?.user_id);
    formData.append("otp", userData?.otp);
    const data = await verifyOtp(formData);
    // dispatch(signupSuccess(data));
    return data;
  } catch (error) {
    throw error;
    // dispatch(signupFailure(error.message));
  }
};

export const resetPassAction = (userData) => async (dispatch) => {
  try {
    const data = await resetPassword(userData);
    return data;
    // dispatch(signupSuccess(data));
  } catch (error) {
    // dispatch(signupFailure(error.message));
  }
};
// export const aboutUsAction = (userData) => async (dispatch) => {
//   try {
//     const data = await aboutUsApi(userData);
//     // dispatch(signupSuccess(data));
//   } catch (error) {
//     // dispatch(signupFailure(error.message));
//   }
// };
// export const faqAction = (userData) => async (dispatch) => {
//   try {
//     return await faqApi(userData);
//     dispatch(signupSuccess(data));
//   } catch (error) {
//     dispatch(signupFailure(error.message));
//   }
// };
