import React from "react";
import PaymentComponent from "../components/PaymentGatway";
const Payment = ({ amount, name, email, phone }) => {
  return (
    <>
      <PaymentComponent />
    </>
  );
};

export default Payment;
