import { useSelector } from "react-redux";
import React from "react";
import { useEffect, useState } from "react";
import {
  Box,
  SimpleGrid,
  Heading,
  Container,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react";
import bannerBg from "../../../assets/images/services/bouncers-and-security-bg.jpg";
// import serviceIMg from "../../../assets/images/services/bouncers-and-security.jpg";
// import { Link } from "react-router-dom";
// import { IoStar } from "react-icons/io5";
// import { FaUser, FaMapMarkerAlt } from "react-icons/fa";
// import customer from "../../../assets/images/services/client-small.png";
// import companyImg from "../../../assets/images/customer/popular-customer/customer-company-img.png";
// import { ImUserTie } from "react-icons/im";

import { useNavigate } from "react-router-dom";

import FreelancerCard from "../FreelancerCard";
// Import Swiper React components
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";

import SpinnerComponent from "../../../components/Spinner";
import ProviderDetails from "../CompanyProfile";
import { topProviders } from "../../../api-services/booking";
import BookingPage from "../BookingPage";
import SearchComponent from "../../../components/SearchAndFilter";

const PopularProvider = ({ selectOnly, providerIdForbase }) => {
  const navigate = useNavigate();

  const { primaryText, footerColor, mediumGray, secondryText, pageBg } =
    useSelector((state) => state.theme);

  const bannerStyle = {
    backgroundImage: `url(${bannerBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100%",
    width: "100%",
  };

  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isBookingModalOpen, setBookingModalOpen] = useState(false);
  const [isProfileModalOpen, setProfileModalOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [count, setCount] = useState(1);
  const [selectedProviderData, setSelectedProviderData] = useState(null);
  const [selectedProviderId, setSelectedProviderId] = useState(null);

  const handleOpenBookingModal = (id) => {
    setBookingModalOpen(true);
  };

  const handleCloseBookingModal = () => {
    setBookingModalOpen(false);
  };

  const handleOpenProviderModal = (id, provider) => {
    if (selectOnly) {
      providerIdForbase(id);
      return;
    }
    setProfileModalOpen(true);
    setSelectedProviderId(id);
    setSelectedProviderData(provider);
  };

  const handleCloseProviderModal = () => {
    setProfileModalOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // Fetch freelancers and companies in parallel
        const companyData = await topProviders("freelancer", count);
        const newCompanies = companyData?.response?.data || [];

        // Append new data to existing companies
        if (companyData?.success === false) {
          setError(companyData?.message);
          return;
        }
        setCompanies((prevCompanies) => [...prevCompanies, ...newCompanies]);
        setFilteredData((prevCompanies) => [...prevCompanies, ...newCompanies]);
        // setCompanies(companyData?.response?.data || []);
      } catch (err) {
        if (err.message === "Request failed with status code 401") {
          navigate("/login");
        }
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [count]);
  // Handle search input
  const handleFilter = (filteredData) => {
    setFilteredData(filteredData);
  };

  const handleBookNowClick = () => {
    handleOpenBookingModal(true);
    handleCloseProviderModal();
  };
  if (loading && filteredData.length === 0) return <SpinnerComponent />;
  if (error && companies.length === 0) return <div>Error: {error}</div>;

  const uniqueFilteredData = filteredData.reduce((acc, current) => {
    const x = acc.find((item) => item.id === current.id);
    if (!x) {
      acc.push(current);
    }
    return acc;
  }, []);
  return (
    <Box className="" bg={pageBg}>
      <Box className="pageBanner" style={{ ...bannerStyle }}>
        <Box
          px="20px"
          color={primaryText}
          textAlign="center"
          zIndex="1"
          position="relative"
        >
          <Heading as="h1" py="4">
            Popular Providers
          </Heading>
        </Box>
      </Box>
      <Container className="providersPagewr" maxW="container.xl" pt="60px" pb="30px">
        <Heading mb="30px" fontWeight="700" color="#000">
          Book Your Service Provider
        </Heading>
        <Flex
          className=""
          alignItems="center"
          gap="10px"
          borderRadius="10px"
          mb="20px"
          p="10px"
        >
          <SearchComponent
            data={companies}
            onFilter={handleFilter}
            searchType={"freelancer"}
          />
        </Flex>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap="10px">
          {uniqueFilteredData.map((provider, index) => (
            <FreelancerCard className="providersPage"
              key={provider.id} // Ensure a unique key
              provider={provider}
              onClick={() => handleOpenProviderModal(provider.id, provider)}
              primaryText={primaryText}
              footerColor={footerColor}
              mediumGray={mediumGray}
              secondryText={secondryText}
            />
          ))}
        </SimpleGrid>
      </Container>
      <Box textAlign="center">
        {!error ? (
          <Button
            className="viewAllBtn"
            onClick={() => setCount((prevCount) => prevCount + 1)}
            isLoading={loading}
            mb="40px"
          >
            Load more
          </Button>
        ) : (
          <Text color={"red"}>{error}</Text>
        )}
      </Box>

      <BookingPage
        isOpen={isBookingModalOpen}
        onClose={handleCloseBookingModal}
        providerId={selectedProviderId}
      />

      <Modal isOpen={isProfileModalOpen} onClose={handleCloseProviderModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Business Info</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ProviderDetails providerData={selectedProviderData} />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" flex="1" onClick={handleBookNowClick}>
              Book Now
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default PopularProvider;
