import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  Text,
  HStack,
  PinInput,
  PinInputField,
  Box,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { verifyPhoneNumberOtp, resendPhoneNoOTP } from "../api-services/auth"; // Adjust the import path as necessary

const PhoneVerification = ({ isOpen, onClose }) => {
  const navigator = useNavigate();

  const [otp, setOtp] = useState(""); // OTP input state
  const [userId] = useState(572); // Replace with dynamic user ID as needed
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [resendTimer, setResendTimer] = useState(60); // Timer for resend button
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  //   const [isOpen, setIsOpen] = useState(true);
  useEffect(() => {
    // Start the resend timer
    const timer = setInterval(() => {
      setResendTimer((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleOtpChange = (value) => {
    setOtp(value);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const response = await verifyPhoneNumberOtp(otp, userId);
      if (!response.success) {
        // Check if the response indicates a failure
        throw new Error(response.message);
      } else {
        setSuccessMessage("OTP Verified Successfully!");
        onClose(false);
        const type = localStorage.getItem("user_type");
        localStorage.setItem("token", response.token);
        localStorage.setItem("isLogin", true);
        const paths = {
          freelancer: "/freelancer-profile",
          employee: "/employee-profile",
          company: "/company-profile",
          user: "/", // Default path for 'user'
        };
        const path = paths[type] || "/"; // Fallback to home if type is not recognized
        navigator(path);
      }
    } catch (error) {
      setError("Failed to verify OTP. Please try again.");
      console.error("Verification error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleResend = async () => {
    if (resendTimer > 0) return; // Prevent resend if timer is active

    setError(null);
    setSuccessMessage(null);

    try {
      const response = await resendPhoneNoOTP(userId);
      setSuccessMessage("OTP Resent Successfully!");
      setResendTimer(60); // Reset timer
    } catch (error) {
      setError("Failed to resend OTP. Please try again.");
      console.error("Resend error:", error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={() => onClose()}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text as="span" fontSize="18px">
            OTP Verification
          </Text>
        </ModalHeader>
        {/* <ModalCloseButton /> */}

        <ModalBody>
          <Text mb="5px">Enter your OTP here.</Text>
          <HStack>
            <PinInput otp onChange={handleOtpChange}>
              <PinInputField flex="1" />
              <PinInputField flex="1" />
              <PinInputField flex="1" />
              <PinInputField flex="1" />
            </PinInput>
          </HStack>
          <Button
            mt="4"
            colorScheme="blue"
            onClick={handleResend}
            isDisabled={resendTimer > 0} // Disable button while timer is active
          >
            Resend OTP {resendTimer > 0 && `(${resendTimer}s)`}
          </Button>
          {successMessage && (
            <Box mt="4">
              <Text color="green">{successMessage}</Text>
            </Box>
          )}
          {error && (
            <Box mt="4">
              <Text color="red">{error}</Text>
            </Box>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="green"
            flex="1"
            isLoading={isSubmitting}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PhoneVerification;
