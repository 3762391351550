import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import React from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Flex,
  Text,
  Heading,
  Link,
  Image,
  Container,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
// import mobile from "../../assets/images/home/mobile.png";
import mobile from "../../assets/images/home/rd.png";
import qr from "../../assets/images/home/qr.png";
import trider_QR from "../../assets/images/home/Trider-QR.png";
import trider_squad_QR from "../../assets/images/home/Trider_Squad_QR.png";
import Googlestore from "../../assets/images/home/google-store.png";
import Applestore from "../../assets/images/home/apple-store.png";
// import mobile from "../../assets/images/home/mobile2.png";

const MobileApp = () => {
  const { footerBg, primaryText, secondryText, textColor } = useSelector(
    (state) => state.theme
  );

  return (
    <Box className="mobileAppDownload">
      <Container maxW="container.xl" py={{ base: "40px", sm: "80px" }}>
        <Flex className="mobileAppDownload_content" flexDirection="row">
          <Flex
            flex="1"
            position="relative"
            textAlign="center"
            alignItems="center"
          >
            <Box className="mobileBgcercle"></Box>
            <Image
              src={mobile}
              mx="auto"
              zIndex="1"
              position="relative"
              maxW={{ base: "200px", lg: "100%" }}
              transition="all .3s"
              _hover={{
                transform: "rotate(3deg)",
              }}
            ></Image>
          </Flex>
          <Box flex="1" p="20px" mb={{ base: "30px", md: "0px" }}>
            <Box>
              <Heading as="h2" mb="20px" textTransform="capitalize">
                Download Our App for Easy Access!
              </Heading>
              <Text color={textColor} fontSize="20px" lineHeight="28px">
                Experience seamless access with our mobile app! Download now for
                exclusive content and quick updates, all at your fingertips!
              </Text>
            </Box>
            <Flex
              gap={{ base: "30px", md: "60px" }}
              pt="30px"
              flexDirection={{ base: "column", sm: "row" }}
            >
              <Box textAlign="center">
                <Flex
                  flexDirection={{ base: "row", sm: "column" }}
                  alignItems="center"
                  justifyContent="center"
                  gap="15px"
                >
                  <Image
                    maxW={{ base: "120px", md: "150pxx" }}
                    w="100%"
                    mx={{ base: "unset", sm: "auto" }}
                    src={trider_QR}
                  ></Image>
                  <Box>
                    <Link
                      href="https://play.google.com/store/apps/details?id=com.service.trider"
                      isExternal
                    >
                      <Image
                        W="100%"
                        maxW={{ base: "135px", md: "160px" }}
                        mx="auto"
                        src={Googlestore}
                        mb="5px"
                      ></Image>
                    </Link>
                    {/* <Link
                      href="https://play.google.com/store/apps/details?id=com.service.trider"
                      isExternal
                    >
                      <Image
                        W="100%"
                        maxW={{ base: "135px", md: "160px" }}
                        mx="auto"
                        src={Applestore}
                      ></Image>
                    </Link> */}
                  </Box>
                </Flex>
                <Heading
                  as="h5"
                  // className="gradient-text"
                  // color="#5858ff"
                  fontWeight="700"
                  fontSize="20px"
                  lineHeight="26px"
                  mt="10px"
                >
                  Download <br className="brwr"></br>Our Customer App
                </Heading>
              </Box>
              <Box textAlign="center">
                <Flex
                  flexDirection={{ base: "row", sm: "column" }}
                  alignItems="center"
                  justifyContent="center"
                  gap="15px"
                >
                  <Image
                    maxW={{ base: "120px", md: "150pxx" }}
                    w="100%"
                    mx={{ base: "unset", sm: "auto" }}
                    src={trider_squad_QR}
                  ></Image>
                  <Box>
                    <Link
                      href="https://play.google.com/store/apps/details?id=com.business.trider"
                      isExternal
                    >
                      <Image
                        W="100%"
                        maxW={{ base: "135px", md: "160px" }}
                        mx="auto"
                        src={Googlestore}
                        mb="5px"
                      ></Image>
                    </Link>
                    {/* <Link
                      href="https://play.google.com/store/apps/details?id=com.business.trider"
                      isExternal
                    >
                      <Image
                        W="100%"
                        maxW={{ base: "135px", md: "160px" }}
                        mx="auto"
                        src={Applestore}
                      ></Image>
                    </Link> */}
                  </Box>
                </Flex>
                <Heading
                  as="h5"
                  // className="gradient-text"
                  // color="#5858ff"
                  fontWeight="700"
                  fontSize="20px"
                  lineHeight="26px"
                  mt="10px"
                >
                  Download<br className="brwr"></br> Our Squad App
                </Heading>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default MobileApp;
