import React from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  Text,
  Heading,
  Button,
  Container,
  Link,
} from "@chakra-ui/react";
import { FaBeer } from "react-icons/fa";
import { IoIosHome } from "react-icons/io";
import { RiCustomerService2Fill } from "react-icons/ri";
// import getInTouchImg from '../../assets/images/home/get-in-touch.jpg'
// import getInTouchImg from "../../assets/images/home/get-in-touch-img.jpg";
import getInTouchImg from "../../assets/images/home/letsconnect.jpg";

const GetinTouch = () => {
  const { primaryText, primaryBg, secondryText, serviceColor, textColor } =
    useSelector((state) => state.theme);

  const navigate = useNavigate(); // Initialize navigate
  const bgStyle = {
    backgroundImage: `URL(${getInTouchImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100%",
    width: "100%",
    backgroundRepeate: "no-repeate",
    backgroundAttachment: "fixed",
  };
  return (
    <Box className="getIN_touch section_spacingY">
      <Container maxW="100%" px="0">
        <Box className="getIN_Bg" style={{ ...bgStyle }} py="40px">
          <Container maxW="container.xl">
            <Box
              zIndex="1"
              position="relative"
              // maxW="830px"
              mx="auto"
              // px="20px"
              py="20px"
            >
              <Flex justifyContent="center" flexDirection={{base:"column", md:"row"}} data-aos="fade-up">
                <Box flex="2">
                  <Heading
                    as="h3"
                    pb="4"
                    zIndex="1"
                    fontWeight="500"
                    position="inherit"
                    color={primaryText}
                  >
                    Schedule a Service <Text as="span" color="#08c71f" display="inline-block">Let’s Connect!</Text>
                  </Heading>
                  <Text color={primaryText} zIndex="1" position="inherit">
                  Schedule a service with ease. Choose from our range of reliable manpower solutions, book in just a few clicks, and let our professionals handle the rest. Fast, simple, and dependable.
                  </Text>
                </Box>
                <Box textAlign={{base:"left", md:"center"}} pt="4" flex="1">
                  <Link
                    className="getInBtn"
                    mt="4"
                    display="inline-block"
                    textDecor="none"
                    color="#ffffff"
                    onClick={() => {
                      navigate("/contact");
                    }}
                  >
                    Get In Touch
                  </Link>
                </Box>
              </Flex>
            </Box>
          </Container>
        </Box>
      </Container>
    </Box>
  );
};

export default GetinTouch;
