import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select,
  Text,
} from "@chakra-ui/react";
import { allServices } from "../api-services";

const AllServicesList = ({
  isOpen,
  onClose,
  services,
}) => {
  const [selectedProvider, setSelectedProvider] = useState("");
  const [selectedServiceId, setSelectedServiceId] = useState("");
  const [loading, setLoading] = useState(false);
  console.log("isOpen", isOpen)
  

  const handlePaymentChange1 = (e) => {
    const id = parseInt(e.target.value); // Get the selected service's ID
    const selectedService = services.find((item) => item.id === id); // Find the full object using the ID
    setSelectedServiceId(e.target.value);
    setSelectedProvider(selectedService); // Set the selected service in state
  };

  const handleService = () => {
    setLoading(true)
    localStorage.setItem("serviceId", selectedServiceId);
    localStorage.setItem("service", JSON.stringify(selectedProvider));
    onClose();
  };

  const handleProviderChange = (event) => {
    setSelectedProvider(event.target.value);
  };

  const paymentTypes = [
    { id: "online", name: "Online" },
    { id: "cod", name: "Cash" },
  ];

  const providerTypes = [
    { id: "freelancer", name: "Freelancer" },
    { id: "company", name: "Company" },
  ];

  return (
    <div>
      <Modal isOpen={isOpen} onClose={() => onClose()} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Choose Service</ModalHeader>
          {/* <ModalCloseButton /> */}
          <ModalBody>
            {/* Payment Type Dropdown */}
            <Select
              placeholder="--Choose Service--"
              value={selectedServiceId}
              onChange={handlePaymentChange1}
            >
              {services?.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>

            {/* Display selected payment and provider */}
            {/* {selectedServiceId && (
              <Text mt={4}>You selected: {selectedServiceId} </Text>
            )} */}
          </ModalBody>
          <ModalFooter>
            {/* <Button colorScheme="black" mr={3} onClick={() => onClose()}>
              Close
            </Button> */}
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => handleService()}
              // isDisabled={selectedPayment === "online" && !selectedProvider} // Disable submit if provider is not selected
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default AllServicesList;
