import {
  Box,
  Container,
  Flex,
  Text,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Link,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { FaRegBell, FaUserCircle, FaCaretDown } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { MdMarkEmailUnread } from "react-icons/md";
import { MdLogin } from "react-icons/md";
// import logo from "../assets/images/logo.png";
// import logo from "../TRIDER_squad.png";
import logo from "../trider-logo.png";
import MobileMenu from "../model/MobileMenu";
import Profile from "./Profile";
import NoficationModel from "./Notification";
import { allServices } from ".././api-services";
import { color } from "framer-motion";

const Header = () => {
  const {
    subHeaderBg,
    secondryText,
    headerBg,
    primaryText,
    subMenuBg,
    activeBg,
    colorBlack,
  } = useSelector((state) => state.theme);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate(); // Initialize navigate
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const navStyle = {
    fontSize: "15px",
    textTransform: "capitalize",
    fontWeight: "500",
    color: "#29435a",
  };
  const SubnavStyle = {
    textTransform: "capitalize",
    fontWeight: "500",
    padding: "10px 20px",
    fontSize: "16px",
  };

  const location = useLocation();
  const isActive = (path) => location.pathname === path;
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      setIsLoggedIn(true); // User is logged in
    } else {
      // navigate("/login"); // Redirect to login if token is not found
      setIsLoggedIn(false);
    }
  }, [navigate, token]);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const data = await allServices();
        setServices(data?.response);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []);
  const userType = localStorage.getItem("user_type");

  // Scroll handling
  useEffect(() => {
    let lastScrollTop = 0;
    const header = document.querySelector(".mainHeader");

    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const isLargeScreen = window.innerWidth >= 767; // Adjust breakpoint as needed

      if (scrollTop === 0) {
        header.style.top = isLargeScreen ? "27px" : "0px"; // Set top based on screen size
      } else if (scrollTop > lastScrollTop) {
        header.style.top = "0px"; // Hide header when scrolling down
      } else {
        header.style.top = "0"; // Show header when scrolling up
      }

      lastScrollTop = scrollTop;
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box>
      <Box
        className="subHeader"
        bg={headerBg}
        display={{ base: "none", md: "block" }}
      >
        <Container maxW="container.xl" p={2} color="black">
          <Flex
            alignItems="center"
            gap="20px"
            justifyContent={{ base: "center", md: "start" }}
          >
            <Flex gap="5px" alignItems="center">
              <IoCall color={primaryText} w="16px" h="auto" />
              <Text
                as="a"
                href="tel:+918500483413"
                lineHeight="12px"
                color={primaryText}
                fontSize={{ base: "14px", md: "16px" }}
              >
                (+91) 8500483413
              </Text>
            </Flex>
            <Flex gap="5px" alignItems="center">
              <MdMarkEmailUnread color={primaryText} w="16px" h="auto" />
              <Text
                as="a"
                href="mailto:triderapplication@gmail.com"
                lineHeight="12px"
                color={primaryText}
                fontSize={{ base: "14px", md: "16px" }}
              >
                triderapplication@gmail.com
              </Text>
            </Flex>
          </Flex>
        </Container>
      </Box>
      <Box
        className="mainHeader"
        // bg={headerBg}
      >
        <Container
          maxW="container.xl"
          px={4}
          color="black"
          className="headerContainer"
        >
          <Flex align="center" justifyContent="space-between">
            <MobileMenu services={services} />
            <Image
              src={logo}
              maxH={{ base: "35px", sm: "50px", lg: "50px" }}
              onClick={() => {
                navigate("/");
              }}
            />

            <Flex align="center" display={{ base: "none", md: "flex" }}>
              <Box
                className="mainHeaderwr"
                display="flex"
                alignItems="center"
                gap="20px"
              >
                <Link
                  className={`${isActive("/") ? "active-menu" : ""}`}
                  style={navStyle}
                  as={NavLink}
                  to="/"
                  px="15"
                  color="black"
                >
                  Home
                </Link>
                <Link
                  className={`${isActive("/about") ? "active-menu" : ""}`}
                  style={navStyle}
                  as={NavLink}
                  to="/about"
                  px="15"
                  color="black"
                >
                  About
                </Link>
                {(userType === "user" ||
                  userType === null ||
                  userType === "") && (
                  <Link style={navStyle} px="15" color="black">
                    <Menu>
                      <MenuButton
                        className={`${
                          isActive("/services")
                            ? "active-menu"
                            : "services_menu"
                        }`}
                        color="black"
                        _hover={{ bg: "transparent" }}
                        style={{ ...navStyle, background: "transparent" }}
                        p="0"
                        as={Button}
                        rightIcon={<FaCaretDown color="black" />}
                      >
                        Services
                      </MenuButton>
                      <MenuList
                        className="submenu"
                        bg={subMenuBg}
                        border="0"
                        zIndex="99"
                        boxShadow="rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
                        borderRadius="10px"
                        left=" -100px"
                      >
                        {services?.map((item, i) => {
                          return (
                            <MenuItem
                              className={i === activeIndex ? "active-menu" : ""}
                              style={SubnavStyle}
                              bg="transparent"
                              color={colorBlack}
                              _hover={{ bg: activeBg, color: primaryText }}
                              onClick={() => {
                                if (userType === null) {
                                  // navigate("/login");
                                  localStorage.setItem("serviceId", item?.id);
                                  localStorage.setItem(
                                    "service",
                                    JSON.stringify(item)
                                  );

                                  navigate(
                                    `/customer-login?name=${(item?.name || "")
                                      .split(` `)
                                      .join(`_`)}`
                                    // navigate(
                                    //   `/service?name=${(item?.name || "")
                                    //     .split(` `)
                                    //     .join(`_`)}`,
                                    //   { state: item }
                                  );
                                } else {
                                  navigate(
                                    `/service?name=${(item?.name || "")
                                      .split(` `)
                                      .join(`_`)}`,
                                    { state: item }
                                  );
                                  setActiveIndex(i);
                                  localStorage.setItem("serviceId", item?.id);
                                  localStorage.setItem(
                                    "service",
                                    JSON.stringify(item)
                                  );
                                }
                              }}
                            >
                              {item?.name}
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    </Menu>
                  </Link>
                )}

                {userType !== "user" && (
                  <Link
                    className={`${
                      isActive("/booking-history") ? "active-menu" : ""
                    }`}
                    style={navStyle}
                    as={NavLink}
                    to="/booking-history"
                    px="15"
                    color="black"
                  >
                    Booking History
                  </Link>
                )}

                <Link
                  className={`${isActive("/contact") ? "active-menu" : ""}`}
                  to="/contact"
                  style={navStyle}
                  as={NavLink}
                  href="/contact"
                  px="15"
                  color="black"
                >
                  Contact
                </Link>
                {/* <Link
                  className={`${isActive("/blog") ? "active-menu" : ""}`}
                  to="/blog"
                  style={navStyle}
                  as={NavLink}
                  px="15"
                  color="black"
                >
                  Blog
                </Link> */}
              </Box>
            </Flex>
            {isLoggedIn && (
              <Flex gap="20px">
                {/* <Box>
                  <FaLocationDot size="20" />
                </Box> */}
                <Box className="">
                  <NoficationModel></NoficationModel>
                </Box>
                {/* <Box className='notificationBell'>
                <Text className='notification_count'>1</Text>
                <FaRegBell size="20" />
              </Box> */}
                {userType === "user" && (
                  <Box>
                    <Link
                      className={`${
                        isActive("/wishlist") ? "active-menu" : ""
                      }`}
                      to="/wishlist"
                      style={navStyle}
                      as={NavLink}
                      href="/wishlist"
                      color="black"
                    >
                      <FaRegHeart size="20" />
                    </Link>
                  </Box>
                )}
                <Box as="button" onClick={onOpen}>
                  <FaUserCircle size="20" />
                </Box>
              </Flex>
            )}
            {!isLoggedIn && (
              <Box>
                <Popover placement="top-end">
                  <PopoverTrigger>
                    <Link
                      className={`${
                        isActive("/") ? "loginSignup" : "loginSignup"
                      }`}
                      style={navStyle}
                      // as={NavLink}
                      // to="/login"
                      px="15"
                      display="flex"
                      alignItems="center"
                      gap="5px"
                      color="white !important"
                      borderRadius="20px"
                    >
                      <MdLogin />
                      Login
                    </Link>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody py="25px" px="20px">
                      {/* Login As a Customer Button */}
                      <Button
                        backgroundColor="#FFA500" // btn_txt (green)
                        color="#FFFFFFFF" // white
                        size="md"
                        width="100%"
                        fontWeight="400"
                        mb={2}
                        _hover={{
                          backgroundColor: "#d9961b",
                          color: "#ffffff",
                        }} // Change on hover
                        borderRadius="md"
                        as={NavLink}
                        to="/customer-login"
                      >
                        Login As a Customer
                      </Button>
                      {/* Login As a Squad Button */}
                      <Button
                        backgroundColor="#408000" // greenish
                        color="#ffffff" // black
                        size="md"
                        width="100%"
                        fontWeight="400"
                        _hover={{
                          backgroundColor: "#57a706",
                          color: "#ffffff",
                        }} // Change on hover
                        borderRadius="md"
                        as={NavLink}
                        to="/provider-login"
                      >
                        Login As a Squad
                      </Button>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Box>
            )}
          </Flex>
        </Container>
      </Box>

      {/* Profile Drawer */}
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton color="#fff" />
          <DrawerBody
            borderRadius="0 0 6px 6px"
            p="0"
            css={{
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#e2e2e2", // Track color
                margin: "2px 2px", // Simulate padding with margins
              },
              "&::-webkit-scrollbar-thumb": {
                // backgroundColor: "#08c71f",    // Thumb color
                backgroundColor: "#2172b9", // Thumb color
                borderRadius: "10px",
                height: "40px",
                cursor: "pointer",
              },
            }}
          >
            <Profile onClose={onClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Header;
