import { API_URL } from "./baseURL";
import axios from "axios";

export const businessReview = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${API_URL}/customer/businessReviews`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(`Error managing heart: ${error.message}`);
  }
};

export const getCustomerReview = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      `${API_URL}/customer/business-review/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(`Error managing heart: ${error.message}`);
  }
};
