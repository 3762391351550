import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "../pages/Home";
import About from "../pages/About";
import Contact from "../pages/Contact";
import SignUp from "../pages/SignUp";
import BouncersAndSecurity from "../pages/services/Bouncers-and -Security";
import ForgetPassword from "../components/ForgetPassword";
import OtpVarify from "../components/OtpVarify";
import ResetPassword from "../components/ResetPassword";
import FreelancerProfile from "../pages/provider/Freelancer/FreelancerProfile";
import CompanyProfile from "../pages/provider/company/CompanyProfile";
import CompanyEmployeeProfile from "../pages/provider/company/CompanyEmployeeProfile";
import Profile from "../components/Profile";
import BookingHistory from "../pages/provider/Freelancer/BookingHistory";
import FaqPage from "../pages/Faq";
import TermsAndConditions from "../pages/TermsAndConditions";
import PrivacyPolicy from "../pages/PrivacyPolicy";
// import BookingPage from "../pages/services/Booking";
import CompanyEmployeeList from "../pages/provider/company/CompanyEmployeeList";
import CompanyEmployeeDetails from "../pages/provider/company/CompanyEmployeeDetails";
// Customer
import WishList from "../pages/customer/WishList";
import CustomerBookingHistory from "../pages/customer/CustomerBookingHistory";
import PopularProvider from "../pages/customer/popular/PopularProvider";
import PopularCompany from "../pages/customer/popular/PopularCompany";
import CustomerProfile from "../pages/customer/customerprofile";
import ServiceProviderPage from "../pages/customer/ProviderProfile";
import CompanyServiceProfile from "../pages/customer/CompanyProfile";
import Earnings from "../components/Earnings";
// import ProviderLogin from "../pages/provider-login";
import Login from "../pages/Login";
import ProviderLogin from "../pages/provider-login";
import Providerregistration from "../pages/provider-registration";
import MyGoogleMap from "../pages/map";
import Payment from "../pages/payment";
import BookingPage from "../pages/customer/BookingPage";
import Chat from "../pages/Chat";
import NotFound from "../components/NotFound";
// import { AuthProvider } from "../pages/AuthContext";
// import ProtectedRoute from "../components/ProtectedRoute";

// import BookingPageModal from "../pages/services/BookingModal";
// const userType = localStorage.getItem("user_type");
// console.log("userType======", userType);
const AllRoute = () => {
  return (
    // <AuthProvider>
    // <Router>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<About />} />
      {/* <ProtectedRoute path="/dashboard" component={Dashboard} /> */}
      <Route path="/chat" element={<Chat />} />

      <Route path="/contact" element={<Contact />} />
      <Route path="/service" element={<BouncersAndSecurity />} />
      <Route path="/map" element={<MyGoogleMap />} />
      <Route path="/payment" element={<Payment />} />

      {/* Customer Auth */}
      <Route path="/customer-registration" element={<SignUp />} />
      <Route path="/customer-login" element={<Login />} />

      {/* Provider Auth */}
      <Route path="/provider-registration" element={<Providerregistration />} />
      <Route path="/provider-login" element={<ProviderLogin />} />
      {/* <Route path="/provider-login" element={<Login />} />
      <Route path="/provider-registration" element={<ProviderLogin />} /> */}

      <Route path="/forget-password" element={<ForgetPassword />} />
      <Route path="/otp-varify" element={<OtpVarify />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/freelancer-profile" element={<FreelancerProfile />} />
      {/* {userType === "company" && (
        <Route path="/company-profile" element={<CompanyProfile />} />
      )} */}
      <Route path="/company-profile" element={<CompanyProfile />} />
      <Route path="/employee-profile" element={<CompanyEmployeeProfile />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/booking-history" element={<BookingHistory />} />
      <Route path="/faq" element={<FaqPage />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/privacy-and-policy" element={<PrivacyPolicy />} />
      {/* <Route path="/booking" element={<BookingPage />} /> */}

      {/* Customer */}
      <Route path="/booking" element={<BookingPage />} />
      <Route
        path="/customer-booking-history"
        element={<CustomerBookingHistory />}
      />
      <Route path="/popular-provider" element={<PopularProvider />} />

      <Route path="/popular-company" element={<PopularCompany />} />
      <Route path="/customer-profile" element={<CustomerProfile />} />
      <Route path="/provider-details" element={<ServiceProviderPage />} />
      <Route path="/company-details" element={<CompanyServiceProfile />} />

      {/* <Route path="/booking-modal" element={<BookingPageModal />} /> */}
      {/* Customer */}
      <Route path="/company-employees" element={<CompanyEmployeeList />} />
      <Route
        path="/company-employees-details"
        element={<CompanyEmployeeDetails />}
      />
      <Route path="/wishlist" element={<WishList />} />

      {/* Earnings */}
      <Route path="/earnings" element={<Earnings />} />
      {/* Earnings */}

      <Route path="*" element={<NotFound />} /> {/* Catch-all route for undefined paths */}
    </Routes>
    // </Router>
    // </AuthProvider>
  );
};

export default AllRoute;
