import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Input,
  Link,
  Stack,
  Text,
  InputGroup,
  InputRightElement,
  Select,
  Heading,
  Image,
  Flex,
  Container,
} from "@chakra-ui/react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { loginAction, resetLogin } from "../redux/auth-redux/actions";
import PhoneVerification from "../components/PhoneVerify";
import { getFirebaseToken } from "../services/Notification";

const Login = ({ loginType }) => {
  const { acitveColor, primaryBg, primaryText, bgBlack } = useSelector(
    (state) => state.theme
  );
  const { isLoggedIn, loginError, loading } = useSelector(
    (state) => state.auth
  );
  const [searchParams] = useSearchParams();
  const [isModalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => setShowPassword(!showPassword);

  const validationSchema = Yup.object({
    email: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required"),
    // providerType: Yup.string().required("Provider type is required"),
  });
  useEffect(() => {
    // Reset login state when the component mounts
    dispatch(resetLogin());
  }, [dispatch]);
  const initialValues = {
    email: "",
    password: "",
    optInPromotions: false,
  };

  const onSubmit = async (values) => {
    const firebaseToken = await getFirebaseToken();

    values.device_type = "web";
    values.firebase_token = firebaseToken || "";
    values.udid = "xxxxxxxx";
    values.providerType = loginType || "user";

    // Dispatch the login action
    const data = await dispatch(loginAction(values));
    if (data?.success === true && data?.is_verify === 0) {
      setModalOpen(true);
    } else {
      localStorage.setItem("token", data.token );
      localStorage.setItem("isLogin", true);
      const serviceId = localStorage.getItem("serviceId");
      const service = JSON.parse(localStorage.getItem("service"));
      // if (isLoggedIn) {
      if (serviceId) {
        const name = searchParams.get("name");
        navigate(`/service?name=${name || ""}`, { state: service });
      } else {
        navigate("/");
      }
      // }
    }
  };

  // useEffect(() => {
  //   const serviceId = localStorage.getItem("serviceId");
  //   const service = JSON.parse(localStorage.getItem("service"));
  //   const isLogin = JSON.parse(localStorage.getItem("isLogin"));

  //   if (isLogin) {
  //     if (serviceId) {
  //       const name = searchParams.get("name");
  //       navigate(`/service?name=${name || ""}`, { state: service });
  //     } else {
  //       navigate("/");
  //     }
  //   }
  // }, [isLoggedIn, navigate, searchParams]);

  return (
    <Box>
      <Container maxW="container.xl" py="80px">
        <Box className="loginPage" w="100%" bg={primaryBg} pt="80px">
          <Flex justifyContent="center">
            {/* <Box className="loginImgWr" flex="1" display={{base:"none" , md:"block"}}>
            <Image src={leftimg}></Image>
          </Box> */}

            <Box flex="1" p={{ base: "0px", sm: "30px" }} maxW="650px">
              <Box className="head" textAlign="center">
                <Heading>Welcome Back!</Heading>
                <Text fontSize={{ base: "14px", sm: "16px" }}>
                  We're thrilled to have you back! We knew it wouldn't be
                  long—when you're onto something great, it always brings you
                  back!
                </Text>
              </Box>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ values, handleChange }) => (
                  <Form>
                    <Box className="providerType" marginBottom="20px"></Box>
                    <Stack spacing={4}>
                      <FormControl id="email">
                        <Field
                          name="email"
                          type={Text}
                          as={Input}
                          placeholder={loginType ? "Enter Squad Email" : "Enter Customer Email"}
                        />
                        <ErrorMessage
                          name="email"
                          component={Text}
                          color="red.500"
                        />
                      </FormControl>
                      <FormControl id="password">
                        <InputGroup>
                          <Field
                            name="password"
                            type={showPassword ? "text" : "password"}
                            as={Input}
                            placeholder={loginType ? "Squad Password" : "Customer Password"}
                          />
                          <InputRightElement height="100%">
                            <Button
                              variant="ghost"
                              onClick={handleShowPassword}
                              _hover={{ bg: "transparent" }}
                            >
                              {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                        <ErrorMessage
                          name="password"
                          component={Text}
                          color="red.500"
                        />
                      </FormControl>
                      <Text color={bgBlack} mt="-15px">
                        <Link
                          as={NavLink}
                          to="/forget-password"
                          fontWeight="500"
                          textDecoration="underline"
                        >
                          Forgotten password?
                        </Link>
                      </Text>
                      {/* <FormControl>
                        <Field
                          name="optInPromotions"
                          type="checkbox"
                          as={Checkbox}
                        >
                          By signing up, you accept the Terms and Conditions
                        </Field>
                      </FormControl> */}
                      <Text color="red">
                        <p>{loginError?.message}</p>
                      </Text>
                      <Button
                        // bg="#ffa542"
                        bg={bgBlack}
                        type="submit"
                        borderRadius="0"
                        isLoading={loading}
                        color={primaryText}
                        _hover={{
                          bg: "#fb901a",
                        }}
                      >
                        Login
                      </Button>
                      <Flex
                        gap="10px"
                        flexDirection={{ base: "column", sm: "row" }}
                      >
                        <Button
                          backgroundColor="#56A900"
                          color="#FFFFFFFF"
                          size="md"
                          width="100%"
                          borderRadius="0"
                          fontWeight="400"
                          _hover={{
                            backgroundColor: "#408000",
                            color: "#ffffff",
                          }}
                          as={NavLink}
                          to="/customer-registration"
                        >
                          Signup As a Customer
                        </Button>
                        <Button
                          backgroundColor="#FFA500"
                          color="#ffffff"
                          size="md"
                          width="100%"
                          borderRadius="0"
                          fontWeight="400"
                          _hover={{
                            backgroundColor: "#FFAB82",
                            color: "#ffffff",
                          }}
                          as={NavLink}
                          to="/provider-registration"
                        >
                          Signup As a Squad
                        </Button>
                      </Flex>
                    </Stack>
                  </Form>
                )}
              </Formik>
            </Box>
          </Flex>
        </Box>
      </Container>
      {isModalOpen && (
        <PhoneVerification
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </Box>
  );
};

export default Login;
