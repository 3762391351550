import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Image,
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Avatar,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdCurrencyRupee } from "react-icons/md";
import { FaMapLocationDot } from "react-icons/fa6";
import { NavLink, useNavigate } from "react-router-dom";
import Rating from "../../components/rating";
import moment from "moment";
import { sendMessage } from "../../api-services";

const formatAddress = (address) => {
  const { address_line_one, address_line_two, city, state, pincode } = address;

  return (
    <>
      {address_line_one}, {address_line_two}, {city}, {state} - {pincode}.
    </>
  );
};

const BookingDetails = ({ isOpen, onClose, providerData }) => {
  console.log("providerData", providerData);
  const businessDetail = providerData?.businesses;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { redDark, darkYellow, bodyTextColor } = useSelector(
    (state) => state.theme
  );
  const navigate = useNavigate();
  // const onChat = () => {
  //   navigate(`/chat`);
  // };

  const onChat = (user) => {
    const newMessage = {
      booking_id: user?.otp?.booking_id,
      receiver_id: user?.business_id,
      message: `Hello , ${user?.business_name}`,
      chat_id: user?.chatID,
    };
    const chatMessage = async (newMessage) => {
      try {
        setLoading(true);
        const data = await sendMessage(newMessage); // Fetch about content
        console.log("initail message chat", data);
        navigate(`/chat`);
      } catch (error) {
        setError("Failed to load content"); // Set error if the request fails
      } finally {
        setLoading(false); // Disable loading once data is fetched
      }
    };
    chatMessage(newMessage);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Business Info</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Image src={providerData?.business_image} m="0 auto"></Image>
            <Box>
              <Box textAlign="center">
                <Heading as="h6" fontSize="20px" textAlign="center" mt="10px">
                  {providerData?.business_name}
                </Heading>
                <Flex justifyContent="center">
                  <Rating
                    total_rating={providerData?.total_rating}
                    reviewsCount={providerData?.total_review}
                  />
                </Flex>
                <Text
                  color="#505002"
                  fontSize="18px"
                  fontWeight="600"
                  my="10px"
                  textDecor="underline"
                  as={NavLink}
                  to={`/provider-details?id=${providerData?.business_id}&booking_id=${providerData?.booking_services?.[0]?.booking_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Review
                </Text>
              </Box>
            </Box>
            <Flex
              justifyContent="space-between"
              pb="5px"
              alignItems="center"
              mb="10px"
            >
              <Flex alignItems="center" gap="5px">
                <FaMapMarkerAlt size="22" />
                <Text>{formatAddress(providerData?.booking_address)}</Text>
              </Flex>
              <FaMapLocationDot size="22" />
            </Flex>
            {/* <Box pb="15px">
              <Text fontWeight="600">Instructions</Text>
              <Text color="#a6a6a6">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
                nec mi nec mi feugiat tincidunt fringilla nec nisi. Nam vitae mi
                id dolor dignissim condimentum. Vestibulum a tortor eu ex
                gravida elementum.
              </Text>
            </Box> */}
            <Box mb="20px" p="5px" bg="#0000000d">
              <Flex justifyContent="space-between" pb="5px">
                <Text fontWeight="600">Booking Id</Text>
                <Text color="#a6a6a6">{providerData?.order_no}</Text>
              </Flex>
              <Flex justifyContent="space-between" pb="5px">
                <Text fontWeight="600">Date</Text>
                <Text color="#a6a6a6">
                  {providerData?.booking_date}{" "}
                  {moment(providerData?.booking_time, "HH:mm:ss").format(
                    "h:mm A"
                  )}
                </Text>
              </Flex>
            </Box>
            <Box>
              <Text fontWeight="700" pb="5px" color={darkYellow}>
                Service
              </Text>
              <Box mb="20px" p="5px" bg="#0000000d">
                <Flex justifyContent="space-between" pb="5px">
                  <Text fontWeight="600">
                    {providerData?.booking_services[0]?.service_name}
                  </Text>
                  <Text fontWeight="600">
                    Qty : {providerData?.booking_services[0]?.qty}
                  </Text>
                </Flex>
                <Flex justifyContent="space-between" pb="5px">
                  {providerData?.booking_services[0]?.days > 0 ? (
                    <Text fontWeight="600" fontSize="12px">
                      No. of days : {providerData?.booking_services[0]?.days}
                    </Text>
                  ) : (
                    <Text fontWeight="600" fontSize="12px">
                      No. of hours : {providerData?.booking_services[0]?.hours}
                    </Text>
                  )}

                  <Flex alignItems="center" fontWeight="600" fontSize="12px">
                    {" "}
                    <MdCurrencyRupee />
                    Per Hour : {providerData?.booking_services[0]?.amount}
                  </Flex>
                </Flex>
              </Box>
            </Box>

            <Box>
              <Text fontWeight="700" pb="5px" color={darkYellow}>
                Amount
              </Text>
            </Box>
            <Box mb="20px" p="5px" bg="#0000000d">
              <Flex justifyContent="space-between" pb="5px">
                <Text fontWeight="600">Total Amount</Text>
                <Text fontWeight="600">Rs {providerData?.total_amount}</Text>
              </Flex>
              <Flex justifyContent="space-between" pb="5px" color="red">
                <Text fontWeight="600">Tax</Text>
                <Flex alignItems="center" fontWeight="600" color={redDark}>
                  {" "}
                  <MdCurrencyRupee />
                  {providerData?.tax}
                </Flex>
              </Flex>
            </Box>
            <Flex
              justifyContent="space-between"
              borderBottom="1px solid #e99c0c42"
              pb="5px"
              mb="10px"
            >
              <Text fontWeight="600" fontSize="20px">
                Grand Total
              </Text>
              <Flex alignItems="center" fontWeight="600" fontSize="20px">
                {" "}
                <MdCurrencyRupee />
                Rs {providerData?.grand_amount}
              </Flex>
            </Flex>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Box width="100%">
            <Flex mb="8px" fontWeight="600">
              Squad Details
            </Flex>
            {businessDetail?.map((businessDetail) => (
              <Flex
                justifyContent="space-between"
                width="100%"
                alignItems="center"
                py="10px"
              >
                <Box flex="1" display="flex" gap="8px" alignItems="center">
                  <Avatar
                    src={businessDetail.business_image}
                    borderColor="red"
                  />
                  <Text fontWeight="bold" color={bodyTextColor}>
                    {businessDetail?.business_name}{" "}
                  </Text>
                  {/* <Rating
                  total_rating={businessDetail?.business_rating}
                  reviewsCount={businessDetail?.business_review}
                /> */}
                </Box>
                <Button
                  colorScheme="green"
                  onClick={() => onChat(businessDetail)}
                >
                  Chat now
                </Button>
              </Flex>
            ))}
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BookingDetails;
