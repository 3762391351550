import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Text,
  Divider,
  Box,
  Button,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { MdDelete, MdOutlineModeEdit } from "react-icons/md";
import AddressDetailsModalNew from "../components/addressDetails-new";
import {
  getAddress,
  getDeleteAddress,
  updateAddressApi,
} from "../api-services/booking";

function ManageAddress({ isOpen, onClose }) {
  const { secondryText, headingColor, activeBg } = useSelector(
    (state) => state.theme
  );
  const [isShow, setIsShow] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await getAddress();
      setAddresses(res?.response || []);
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteAddress = async (id) => {
    try {
      await getDeleteAddress(id);
      setAddresses((prevAddresses) =>
        prevAddresses.filter((address) => address.id !== id)
      );
      setError(null);
    } catch (error) {
      setError("Failed to delete the address.");
    }
  };

  const handleEditAddress = (address) => {
    setSelectedAddress(address);
    setIsShow(true);
  };

  const handleAddressSubmit = async (addressDetails, currentPosition) => {
    const updatedAddress = { ...addressDetails, ...currentPosition };
    try {
      console.log("updatedAddress======", updatedAddress);
      await updateAddressApi(selectedAddress?.id, updatedAddress);
      await fetchData();
      setError(null);
      setIsShow(false);
    } catch (error) {
      setError("Failed to update the address.");
    }
  };

  return (
    <>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Manage Address</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box className="manageAddress_Wr">
              {loading ? (
                <Text>Loading...</Text>
              ) : error ? (
                <Text color="red">{error}</Text>
              ) : addresses.length === 0 ? (
                <Text>No addresses found.</Text>
              ) : (
                addresses.map((address, index) => (
                  <React.Fragment key={index}>
                    <Flex
                      className="AddressWr"
                      justifyContent="space-between"
                      alignItems="center"
                      p="10px"
                      boxShadow="rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px"
                    >
                      <Box>
                        <Text fontWeight="600">{address.address_line_one}</Text>
                        <Text>{`${address.city}, ${address.state} - ${address.pincode}`}</Text>
                      </Box>

                      <Text fontWeight="600">
                        {address.is_default === 1 ? "default" : ""}
                      </Text>

                      <Flex gap="5px">
                        <Flex
                          onClick={() => handleEditAddress(address)}
                          mr="10px"
                          alignItems="center"
                          justifyContent="center"
                          className="icon"
                          background="rgb(52 77 77 / 9%)"
                          w="30px"
                          h="30px"
                          borderRadius="3px"
                        >
                          <Button p="0">
                            <MdOutlineModeEdit />
                          </Button>
                        </Flex>
                        <Flex
                          alignItems="center"
                          justifyContent="center"
                          className="icon"
                          background="rgb(52 77 77 / 9%)"
                          w="30px"
                          h="30px"
                          borderRadius="3px"
                        >
                          <Button
                            p="0"
                            onClick={() => handleDeleteAddress(address.id)}
                          >
                            <MdDelete />
                          </Button>
                        </Flex>
                      </Flex>
                    </Flex>
                    <Divider color="#e7e7e7" my="10px" />
                  </React.Fragment>
                ))
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              background={activeBg}
              color="#fff"
              variant="ghost"
              onClick={() => {
                setIsShow(true);
                setSelectedAddress(null);
              }}
            >
              Add New +
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {isShow && (
        <AddressDetailsModalNew
          isOpen={isShow}
          onClose={() => setIsShow(false)}
          onAddressSubmit={handleAddressSubmit}
          address={selectedAddress}
        />
      )}
    </>
  );
}

export default ManageAddress;
