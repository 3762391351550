import React, { useState } from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  Stack,
  Icon,
  useToast,
} from "@chakra-ui/react";
import { FaHeart, FaRegHeart, FaUser, FaMapMarkerAlt } from "react-icons/fa";
import Rating from "../../components/rating"; // Update the path if necessary
import { manageHeart } from "../../api-services/booking"; // Import your heart management API function

const formatAddress = (address) => {
  const {
    address_line_one,
    address_line_two,
    city,
    state,
    pincode,
    latitude,
    longitude,
  } = address;

  return (
    <>
      {address_line_one}, {address_line_two}, {city}, {state} - {pincode}.
      <br />
      Coordinates: ({latitude}, {longitude})
    </>
  );
};

const FreelancerCard = ({
  serviceId,
  provider,
  footerColor,
  mediumGray,
  secondryText,
  primaryText,
  lightBlue,
  blackmedium,
  bodyTextColor,
  redcolor,
  onClick,
}) => {
  const [isHearted, setIsHearted] = useState(provider?.is_favourite === 1);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const handleHeartClick = async (e) => {
    e.stopPropagation(); // Prevents triggering parent onClick
    try {
      setLoading(true);
      await manageHeart(provider.id); // Call the heart API with provider ID
      setIsHearted(!isHearted); // Toggle heart status
    } catch (error) {
      console.error("Error managing heart:", error);
      toast({
        title: "Error",
        description: "There was an error updating the favorite status.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      borderRadius="md"
      p={4}
      bg={lightBlue}
      className="bookingCardInner providerSlider"
      mb="20px"
      onClick={onClick}
      border="1px solid #1E88E5"
      transition="all .3s"
      _hover={{
        boxShadow: "#84c1f3 0px 0px 8px",
      }}
      position="relative" // Ensure proper positioning of heart icon
    >
      <Flex mb={4} gap="10px" justifyContent="space-between">
        <Flex justifyContent="space-between">
          <Flex>
            <Image
              boxSize="50px"
              borderRadius="full"
              src={provider.image}
              alt="Provider Image"
              mr={4}
              border=" 2px solid #fff"
            />
            <Box flex="1">
              <Box>
                <Text
                  className="providerName"
                  fontWeight="bold"
                  color={bodyTextColor}
                >
                  {provider?.name}
                </Text>
                <Flex alignItems="center" className="rating">
                  <Rating
                    color="#fff"
                    total_rating={provider.total_rating}
                    reviewsCount={provider.total_review}
                  />
                </Flex>
              </Box>
            </Box>
          </Flex>
        </Flex>
        {/* <Box
          className="wishListIcon"
          position="absolute"
          right="15px"
          top="15px"
          cursor="pointer"
          p="5px"
          borderRadius="50%"
          bg={isHearted ? redcolor : "transparent"}
          color={isHearted ? "white" : redcolor}
          transition="background-color 0.3s ease"
          onClick={handleHeartClick}
        >
          <FaRegHeart size={20} color={isHearted ? "red" : "gray"} />
        </Box> */}
        <Box
          className="wishListIcon"
          position="absolute"
          right="15px"
          top="15px"
          cursor="pointer"
          p="5px"
          borderRadius="50%"
          bg={isHearted ? redcolor : "transparent"} // Background of the button
          color={isHearted ? "white" : redcolor} // Color of the icon container
          transition="background-color 0.3s ease"
          onClick={handleHeartClick} // Handle the click event
        >
          {isHearted ? (
            <FaHeart size={20} color="red" /> 
          ) : (
            <FaRegHeart size={20} color="white" /> 
          )}
        </Box>
      </Flex>
      <Stack spacing={2}>
        <Flex
          alignItems="flex-start"
          justifyContent="space-between"
          flexWrap="wrap"
          // gap="5px"
          w="100%"
          flexDir="column"
        >
          <Flex
            gap="5px"
            // alignItems="baseline"
            alignItems="flex-start"
            // borderBottom="1px solid #1E88E5"
            borderBottom="1px solid #ffffff38"
            w="100%"
            pb="5px"
            className="bbLight"
          >
            <Icon className="iconwr" as={FaUser} mr={2} color={secondryText} />
            <Text color={blackmedium}>
              No. of services : {provider?.total_service}
            </Text>
          </Flex>
          <Flex
            gap="5px"
            // alignItems="baseline"
            alignItems="flex-start"
            className="bbLight"
          >
            <Icon
              className="iconwr"
              as={FaMapMarkerAlt}
              mr={2}
              color={secondryText}
            />
            <Text color={blackmedium}>
              {/* {" "}
              {formatAddress(provider?.business_address)} */}
              {provider?.business_address
                ? (() => {
                    const {
                      address_line_one,
                      address_line_two,
                      city,
                      state,
                      pincode,
                    } = provider.business_address;
                    const fullAddress = `${address_line_one || ""}, ${
                      address_line_two || ""
                    }, ${city || ""}, ${state || ""}, ${pincode || ""}`;
                    return fullAddress.length > 40
                      ? `${fullAddress.substring(0, 40)}...`
                      : fullAddress;
                  })()
                : ""}
            </Text>
          </Flex>
        </Flex>
      </Stack>
    </Box>
  );
};

export default FreelancerCard;
