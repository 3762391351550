import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  SimpleGrid,
  Image,
  Heading,
  Container,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Link,
  Stack,
  Text,
  InputGroup,
  InputRightElement,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";

import logo from "../trider-logo.png";
import { FaArrowLeft } from "react-icons/fa6";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { resetPassAction } from "../redux/auth-redux/actions";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { forgotPassData } = useSelector((state) => state.auth);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleShowPassword = () => setShowPassword(!showPassword);
  const handleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const { secondryText, primaryText, footerColor, bodyTextColor } = useSelector(
    (state) => state.theme
  );
  const boxStyle = {
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    maxWidth: "550px",
    padding: "30px",
    margin: "0 auto",
    borderRadius: "10px",
  };
  const backBtn = {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  };

  const validationSchema = Yup.object({
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const onSubmit = async (values) => {
    setErrorMessage("");
    setIsLoading(true);
    let obj = {
      user_id: forgotPassData?.id, //make dynamic
      password: values.password,
      password_confirmation: values.confirmPassword,
    };
    // console.log("values", obj);
    await dispatch(resetPassAction(obj)).then((res) => {
      if (res?.sucsess) {
        setErrorMessage(
          res?.message +
            " " +
            "You will be redirected to the login page in 3 seconds..."
        );
        setTimeout(() => {
          setIsLoading(false);
          navigate("/");
        }, 3000);
      } else {
        setIsLoading(false);
        setErrorMessage(res?.message);
      }
    });
  };
  return (
    <Box className="forget-password" as="footer" my="40px">
      <Container maxW="container.xl" p={4} color={footerColor}>
        <Box style={{ ...boxStyle }}>
          <Link as={NavLink} to="/otp-verify" style={{ ...backBtn }}>
            <FaArrowLeft /> Back
          </Link>
          <Box>
          <Image src={logo} margin="0 auto" w="100px" mb="30px" />
          </Box>
          <Box mb="30px" textAlign="center">
            <Heading as="h6" fontSize="28px" color={bodyTextColor}>
              Reset Password
            </Heading>
            <Text color={bodyTextColor}>
              Create your new password so you can login to your account
            </Text>
          </Box>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Stack spacing={4}>
                  <FormControl id="password">
                    <InputGroup>
                      <Field
                        name="password"
                        type={showPassword ? "text" : "password"}
                        as={Input}
                        placeholder="New Password"
                        color={secondryText}
                      />
                      <InputRightElement>
                        <Button className="passView"
                          variant="ghost"
                          onClick={handleShowPassword}
                          _hover={{ bg: "#1e88e5" }}
                        >
                          {showPassword ? (
                            <ViewOffIcon color={primaryText} />
                          ) : (
                            <ViewIcon color={primaryText} />
                          )}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    <ErrorMessage
                      name="password"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </FormControl>
                  <FormControl id="confirmPassword">
                    <InputGroup>
                      <Field
                        name="confirmPassword"
                        type={showConfirmPassword ? "text" : "password"}
                        as={Input}
                        placeholder="Confirm Password"
                        color={secondryText}
                      />
                      <InputRightElement>
                        <Button className="passView"
                          variant="ghost"
                          onClick={handleShowConfirmPassword}
                          _hover={{ bg: "#1e88e5" }}
                        >
                          {showConfirmPassword ? (
                            <ViewOffIcon color={primaryText} />
                          ) : (
                            <ViewIcon color={primaryText} />
                          )}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </FormControl>
                  {errorMessage && (
                    <Text textAlign="center" style={{ color: "red" }}>
                      <p>{errorMessage}</p>
                    </Text>
                  )}
                  <Button
                    type="submit"
                    className="btnGreen"
                    isLoading={isLoading}
                    _hover={{
                      bg:"#eb7b00"
                    }}
                  >
                    Reset Password
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </Box>
  );
};

export default ResetPassword;
