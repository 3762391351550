import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  Box,
  SimpleGrid,
  Image,
  Heading,
  Container,
  Text,
  Flex,
  Button,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
// import bannerBg from "../../assets/images/services/bouncers-and-security-bg.jpg";
import bannerBg from "../../assets/images/services/heavy-lift.jpg";
import { useLocation } from "react-router-dom";
import { MdCurrencyRupee } from "react-icons/md";
// Import Swiper React components
import PaymentSelect from "../../components/PaymentSelect";
import ProviderComponent from "../../components/Provider";
// Import required modules
import { useNavigate } from "react-router-dom";
import BookingPageModal from "../customer/BookingPage";
import parse from "html-react-parser";

const BouncersAndSecurity = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log({ location });

  const { primaryText } = useSelector((state) => state.theme);

  const bannerStyle = {
    backgroundImage: `url(${bannerBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100%",
    width: "100%",
  };

  const [isBookingModalOpen, setBookingModalOpen] = useState(false);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [provider, setProvider] = useState("");
  const [freelancer, setFreelancer] = useState([]);
  const [company, setCompany] = useState([]);
  const [providerOpen, setProviderOpen] = useState(false); // State to manage the open/close status
  const [selectedProviderData, setSelectedProviderData] = useState(null);

  useEffect(() => {
    const service = JSON.parse(localStorage.getItem("service"));
    // setSelectedService(service);
    setFreelancer(service?.freelancer_prices);
    setCompany(service?.company_prices);
  }, []);

  const handleOpenBookingModal = () => {
    setBookingModalOpen(true);
  };

  const handleCloseBookingModal = () => {
    setBookingModalOpen(false);
  };

  const handleBookNowClick = (e) => {
    console.log(e.target.value);
    setSelectedProviderData(e.target.value);
    if (localStorage.getItem("token")) {
      setIsPaymentOpen(!isPaymentOpen);
    } else {
      navigate("/customer-login");
    }
  };

  const selectedPaymentType = (event) => {
    setPaymentMethod(event?.payment);
    // setIsPaymentOpen(false);
    // if (event?.payment === "online") {
    //   setProvider(event?.provider);
    //   setProviderOpen(true);
    // } else {
    //   handleOpenBookingModal(true);
    // }
    // handleOpenBookingModal(true);
    navigate(
      `/booking?paymentMode=${event?.payment}&chooseProvider=${event?.provider}`
    );
  };

  return (
    <Box className="">
      <Box className="pageBanner" style={{ ...bannerStyle }}>
        <Box
          px="20px"
          color={primaryText}
          textAlign="center"
          zIndex="1"
          position="relative"
        >
          <Heading as="h1" py="4">
            {/* Bouncers and Security */}
            {location?.state?.name}
          </Heading>
        </Box>
      </Box>
      <Container maxW="container.xl" py="30px">
        <Box className="section_spacingY">
          <SimpleGrid columns={{ base: 1, md: 2 }}>
            <Box display="flex" justifyContent="center">
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
              >
                <Image
                  // boxSize="100px"
                  // objectFit="cover"
                  height="50%"
                  src={location?.state?.image}
                />
              </Box>
            </Box>
            <Box pl={{ base: "0", md: "70px" }} pt={{ base: "0", md: "40px" }}>
              <Heading
                pb="20px"
                as="h2"
                fontWeight="bold"
                className="section_title line linegolden"
              >
                {/* Efficient and Reliable Air conditioning Engineer in Midlands */}
                {location?.state?.name}
              </Heading>
              <Text fontSize="16px" pt="40px" textAlign="justify">
                {location?.state?.description}
              </Text>
              {/* {location?.state?.instruction
                  ?.split(" -")
                  ?.map((instruction, j) => (
                    <ListItem key={j}>{instruction}</ListItem>
                  ))} */}
              <UnorderedList fontSize="14px">
                {console.log(
                  "location?.state?.instruction",
                  location?.state?.instruction
                )}
                {location?.state?.instruction
                  ?.replaceAll("<p>-", "")
                  ?.replaceAll("</p>", "")
                  ?.split(" -")
                  ?.map((instruction, j) => (
                    <ListItem key={j}>{parse(instruction)}</ListItem>
                  ))}
              </UnorderedList>
              <br />

              <Box>
                <Text style={{ fontWeight: "bold" }}>
                  Premium Service Packages & Overtime Rates
                </Text>
                <br />
                {company?.map((detail, i) => (
                  <Flex justifyContent="space-between" pb="5px" key={i}>
                    {detail?.shift != 1 ? (
                      <Text
                        fontWeight="600"
                        fontSize="12px"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        w="100%"
                      >
                        {detail?.shift} Hours :{" "}
                        <Flex alignItems="center">
                          <MdCurrencyRupee /> {detail.price}
                        </Flex>
                      </Text>
                    ) : (
                      <Text
                        fontWeight="600"
                        fontSize="12px"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        w="100%"
                      >
                        <Text
                          fontWeight="600"
                          fontSize="12px"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          {" "}
                          Overtime {detail?.shift} Hour :{" "}
                        </Text>
                        <Text
                          fontWeight="600"
                          fontSize="12px"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Flex alignItems="center">
                            <MdCurrencyRupee /> {detail.price}
                          </Flex>
                        </Text>
                      </Text>
                    )}
                  </Flex>
                ))}
                <Box display="flex" justifyContent="center">
                  <Button
                    value={"company_prices"}
                    onClick={handleBookNowClick}
                    bg="#1E88E5"
                    color="#ffffff"
                    width="100%"
                    _hover={{
                      bg: "#84c1f3",
                    }}
                  >
                    Book Premium
                  </Button>
                </Box>
              </Box>
              <br />
              <Box>
                <Text style={{ fontWeight: "bold" }}>
                  Standard Service Packages & Overtime Rates
                </Text>
                <br />
                {freelancer &&
                  freelancer?.map((detail, i) => (
                    <Flex justifyContent="space-between" pb="5px" key={i}>
                      {detail?.shift != 1 ? (
                        <Text
                          fontWeight="600"
                          fontSize="12px"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          w="100%"
                        >
                          {detail?.shift} Hours :{" "}
                          <Flex alignItems="center">
                            <MdCurrencyRupee /> {detail.price}
                          </Flex>
                        </Text>
                      ) : (
                        <Text
                          fontWeight="600"
                          fontSize="12px"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          w="100%"
                        >
                          <Text
                            fontWeight="600"
                            fontSize="12px"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            {" "}
                            Overtime {detail?.shift} Hour :{" "}
                          </Text>
                          <Text
                            fontWeight="600"
                            fontSize="12px"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Flex alignItems="center">
                              <MdCurrencyRupee /> {detail.price}
                            </Flex>
                          </Text>
                        </Text>
                      )}
                    </Flex>
                  ))}
                <Box display="flex" justifyContent="center" my="8px">
                  <Button
                    value={"freelancer_prices"}
                    onClick={handleBookNowClick}
                    bg="rgb(8, 199, 31)"
                    color="#ffffff"
                    width="100%"
                    _hover={{
                      bg: "rgb(11 165 29)",
                    }}
                  >
                    Book Standard
                  </Button>
                </Box>
              </Box>
            </Box>
          </SimpleGrid>
        </Box>
      </Container>

      {/* {isBookingModalOpen && (
        <BookingPageModal
          isOpen={isBookingModalOpen}
          onClose={handleCloseBookingModal}
          paymentMethod={paymentMethod}
          providerData={selectedProviderData}
          hello = "hfel"
        />
      )} */}
      {/*  */}

      {isPaymentOpen && (
        <PaymentSelect
          isOpen={isPaymentOpen}
          onClose={() => setIsPaymentOpen(false)}
          paymentMethod={(e) => selectedPaymentType(e)}
          selectedProviderData={selectedProviderData}
        />
      )}
    </Box>
  );
};

export default BouncersAndSecurity;
