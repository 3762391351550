import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Box,
  SimpleGrid,
  Heading,
  Container,
  Button,
  Checkbox,
  FormControl,
  Input,
  Link,
  Stack,
  Text,
  InputGroup,
  InputRightElement,
  Select,
  Flex,
} from "@chakra-ui/react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import bannerBg from "../assets/images/contact/contactBg.jpg";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { signupAction, resetSignup } from "../redux/auth-redux/actions";
import PhoneVerification from "../components/PhoneVerify";
import { getFirebaseToken } from "../services/Notification";

const Providerregistration = () => {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { primaryBg, primaryText, bgBlack, acitveColor, darkBlue } =
    useSelector((state) => state.theme);
  const { error, loading, isSignUp } = useSelector((state) => state.auth);
  const [isModalOpen, setModalOpen] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleShowPassword = () => setShowPassword(!showPassword);
  const handleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  useEffect(() => {
    // Reset signup state when the component mounts
    dispatch(resetSignup());
  }, [dispatch]);
  const validationSchemaFreelancerAndCompany = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone_number: Yup.string()
      .matches(/^[0-9]+$/, "Invalid input: only numbers are allowed")
      .required("Mobile number is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleSubmit = async (values, type) => {
    const firebaseToken = await getFirebaseToken();

    if (!values.user_type) {
      values.user_type = type;
    }
    values.device_type = "web";
    values.firebase_token = firebaseToken;
    values.udid = "xxxxxx";
    try {
      const data = await dispatch(signupAction(values, type));
      if (data?.success === true && data?.is_verify === 0) {
        setModalOpen(true);
      } else if (data?.success === true && data?.is_verify !== 0) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("isLogin", true);
        const paths = {
          freelancer: "/freelancer-profile",
          employee: "/employee-profile",
          company: "/company-profile",
          user: "/", // Default path for 'user'
        };
        const path = paths[values.user_type] || "/"; // Fallback to home if type is not recognized
        navigator(path);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const boxStyle = {
    // boxShadow:
    //   "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
    // borderRadius: "10px",
  };
  return (
    <Box className="signup" py="80px">
      <Container maxW="container.xl" pt="80px">
        <Box
          className=""
          style={{ ...boxStyle }}
          p={{ base: "15px", sm: "30px" }}
        >
          <SimpleGrid
            columns={{ base: 1, md: 1 }}
            gap="40px"
            // bg={darkBlue}
            borderRadius="10px"
          >
            {/* <Box
              className="cardImg"
              bg={primaryBg}
              display={{ base: "none", md: "block" }}
            >
              <Image src={signup}></Image>
            </Box> */}
            <Box
              className="loginSignUp_form"
              // p={{ base: "20px", md: "40px 40px 40px 0" }}
              maxW="650px"
              mx="auto"
            >
              <Box className="freelancerSignUp">
                <Box className="head" textAlign="center" mb="40px">
                  <Heading
                    as="h2"
                    mb="10px"
                    fontWeight="400"
                    textTransform="capitalize"
                  >
                    registered As a Squad
                  </Heading>
                  <Text fontSize={{ base: "14px", sm: "16px" }}>
                    We're thrilled to have you back! We knew it wouldn't be
                    long—when you're onto something great, it always brings you
                    back!
                  </Text>
                </Box>
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                    confirmPassword: "",
                    user_type: "",
                    phone_number: "",
                  }}
                  validationSchema={validationSchemaFreelancerAndCompany}
                  onSubmit={(values) => handleSubmit(values)}
                >
                  {({ handleSubmit, errors, touched, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                      <Stack spacing={4}>
                        <FormControl id="user_type">
                          <Field
                            as={Select}
                            name="user_type"
                            placeholder="Select provider type"
                            onChange={(e) =>
                              setFieldValue("user_type", e.target.value)
                            }
                          >
                            <option
                              style={{
                                color: "black",
                                backgroundColor: "#f0f0f0",
                              }}
                              value="freelancer"
                            >
                              Standard
                            </option>
                            <option
                              style={{
                                color: "black",
                                backgroundColor: "#f0f0f0",
                              }}
                              value="company"
                            >
                              Premium
                            </option>
                            <option
                              style={{
                                color: "black",
                                backgroundColor: "#f0f0f0",
                              }}
                              value="employee"
                            >
                              Premium Employee
                            </option>
                          </Field>
                        </FormControl>
                        <FormControl
                          id="phone_number"
                          isInvalid={
                            errors.phone_number && touched.phone_number
                          }
                        >
                          <Field
                            as={Input}
                            type="tel"
                            placeholder="Mobile number"
                            name="phone_number"
                          />
                          <ErrorMessage
                            name="phone_number"
                            component={Text}
                            color="red.500"
                          />
                        </FormControl>
                        <FormControl
                          id="email"
                          isInvalid={errors.email && touched.email}
                        >
                          <Field
                            as={Input}
                            type="email"
                            placeholder="Your Email"
                            name="email"
                          />
                          <ErrorMessage
                            name="email"
                            component={Text}
                            color="red.500"
                          />
                        </FormControl>
                        <FormControl
                          id="password"
                          isInvalid={errors.password && touched.password}
                        >
                          <InputGroup>
                            <Field
                              as={Input}
                              type={showPassword ? "text" : "password"}
                              placeholder="Your Password"
                              name="password"
                            />
                            <InputRightElement>
                              <Button
                                variant="ghost"
                                onClick={handleShowPassword}
                                _hover={{ bg: "transparent" }}
                              >
                                {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                              </Button>
                            </InputRightElement>
                          </InputGroup>
                          <ErrorMessage
                            name="password"
                            component={Text}
                            color="red.500"
                          />
                        </FormControl>
                        <FormControl
                          id="confirmPassword"
                          isInvalid={
                            errors.confirmPassword && touched.confirmPassword
                          }
                        >
                          <InputGroup>
                            <Field
                              as={Input}
                              type={showConfirmPassword ? "text" : "password"}
                              placeholder="Confirm Password"
                              name="confirmPassword"
                            />
                            <InputRightElement>
                              <Button
                                variant="ghost"
                                onClick={handleShowConfirmPassword}
                                _hover={{ bg: "transparent" }}
                              >
                                   {showConfirmPassword ? (
                                  <ViewIcon />
                                ) : (
                                  <ViewOffIcon />
                                )}
                              </Button>
                            </InputRightElement>
                          </InputGroup>
                          <ErrorMessage
                            name="confirmPassword"
                            component={Text}
                            color="red.500"
                          />
                        </FormControl>
                        <Checkbox>
                          By signing up, you accept the Terms and Conditions
                        </Checkbox>
                        <Text color="red.500">
                          <p>{error?.message}</p>
                        </Text>
                        <Button
                          type="submit"
                          isLoading={loading}
                          borderRadius="0"
                          bg={bgBlack}
                          color={primaryText}
                          _hover={{
                            bg: "#fb901a",
                          }}
                        >
                          Sign Up
                        </Button>
                        <Text textAlign="center">
                          Already have an account?{" "}
                          <Link
                            color="#ff0000"
                            as={NavLink}
                            to="/provider-login"
                          >
                            Log in
                          </Link>
                        </Text>
                        <Flex
                          gap="10px"
                          flexDirection={{ base: "column", sm: "row" }}
                        >
                          <Button
                            backgroundColor="#56A900"
                            color="#FFFFFFFF"
                            size="md"
                            width="100%"
                            borderRadius="0"
                            fontWeight="400"
                            _hover={{
                              backgroundColor: "#408000",
                              color: "#ffffff",
                            }}
                            as={NavLink}
                            to="/customer-registration"
                          >
                            Registered As a Customer
                          </Button>
                        </Flex>
                      </Stack>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Box>
          </SimpleGrid>
        </Box>
      </Container>
      {isModalOpen && (
        <PhoneVerification
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </Box>
  );
};

export default Providerregistration;
