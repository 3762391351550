import {
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Text,
  VStack,
  Badge,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FaRegBell } from "react-icons/fa";
import { fetchNotifications } from "../api-services/users";

const NotificationModel = () => {
  const { secondryText } = useSelector((state) => state.theme);
  const [notifications, setNotifications] = useState([]);

  const handleNotificationFetch = async () => {
    try {
      const data = await fetchNotifications();
      console.log("data=======", data);
      if (data?.success) {
        setNotifications(data?.response);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  useEffect(() => {
    handleNotificationFetch();
  }, []);

  const NotificationItem = ({ title, message, date_time }) => (
    <Box bg="white" p={4} borderRadius="md" boxShadow="sm" mb={4} w="100%">
      <Text fontSize="lg" fontWeight="bold" mb={2}>
        {title}.
      </Text>
      <Text fontSize="md" color="gray.600">
        {message}
      </Text>
      <Text fontSize="sm" color="gray.400" mt={2}>
        {date_time}
      </Text>
    </Box>
  );

  return (
    <Box position="relative">
      <Popover>
        <PopoverTrigger>
          <Box cursor="pointer" position="relative">
            <FaRegBell size="24" />
            {notifications.length > 0 && (
              <Badge
                position="absolute"
                top="-1"
                right="-1"
                colorScheme="red"
                borderRadius="full"
                p={1}
                fontSize="10px"
                w="18px"
                h="18px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                background="#1e88e5"
                color="#ffffff"
              >
                {notifications.length}
              </Badge>
            )}
          </Box>
        </PopoverTrigger>
        <PopoverContent color={secondryText} maxW="300px">
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader fontWeight="bold">Notifications</PopoverHeader>
          <PopoverBody>
            <VStack
              spacing={1}
              maxH="500px"
              overflowY="auto"
              p={1}
              bg="gray.100"
            >
              {notifications.length > 0 ? (
                notifications.reverse().map((notification, index) => (
                  <NotificationItem key={index} {...notification} />
                ))
              ) : (
                <Text color="gray.500">No new notifications</Text>
              )}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default NotificationModel;
