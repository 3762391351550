import React from "react";

import { Box } from "@chakra-ui/react";

import Login from "./Login";
const ProviderLogin = () => {
  return (
    <Box className="signup">
      <Box
        className=""
        // style={{ ...boxStyle }}
      >
        <Login loginType="provider" />
      </Box>
    </Box>
  );
};

export default ProviderLogin;
