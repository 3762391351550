import { Box, Container, Flex, Heading, Image, Text } from "@chakra-ui/react";
// import howwork1 from "../../assets/images/home/how-it-work-img1.png";
// import howwork2 from "../../assets/images/home/how-it-work-img2.png";
// import howwork3 from "../../assets/images/home/how-it-work-img3.png";
// import howwork1 from "../../assets/images/home/download-HIW.png";
// import howwork2 from "../../assets/images/home/signup-HIW.png";
// import howwork3 from "../../assets/images/home/service-HIW.png";
import howwork1 from "../../assets/images/home/Img1.jpg";
import howwork2 from "../../assets/images/home/Img2.jpg";
import howwork3 from "../../assets/images/home/Img3.jpg";
export default function HowItWorks() {
  return (
    <Box py="80px" bg="gray.50">
      <Container maxW="container.xl">
        <Box textAlign="center" mb="8" >
          <Text
            as="span"
            textTransform="uppercase"
            fontWeight="medium"
            color="#0063ab"
          >
            How It Works
          </Text>
          <Heading as="h2" fontWeight="700">
            <Text as="span">Three Steps</Text> to Effortless Booking
          </Heading>
        </Box>
        <Flex justify="center" w="100%" mt="50px">
          <Flex justify="space-between" w="100%" flexDirection={{base:"column", md:"row"}} gap={{base:"40px", md:"0"}}>
            {/* Step 1 */}
            <Box textAlign="center" flex="1" px="40px" className="HW_box arrow1" >
              <Image
                src={howwork1}
                alt="how-it-works-img"
                mx="auto"
                maxW={{base:"170px", lg:"200px"}}
                height={{base:"170px", lg:"200px"}}
                className="HW_img"
              />
              <Heading as="h4" fontWeight="medium" mt="4">
              Download the App
              </Heading>
              <Text mt="2">
              Visit the App Store or Google Play, search for Trider, and download the app.
              </Text>
            </Box>

            {/* Step 2 */}
            <Box textAlign="center" flex="1" px="40px" className="HW_box arrow2" >
              <Image
                src={howwork2}
                alt="how-it-works-img"
                mx="auto"
                maxW={{base:"170px", lg:"200px"}}
                height={{base:"170px", lg:"200px"}}
                className="HW_img"
              />
              <Heading as="h4" fontWeight="medium" mt="4">
              Sign Up/Log In
              </Heading>
              <Text mt="2">
              Create an account or log in with your details to access the platform.
              </Text>
            </Box>

            {/* Step 3 */}
            <Box textAlign="center" flex="1" px="40px" >
              <Image
                src={howwork3}
                alt="how-it-works-img"
                mx="auto"
                maxW={{base:"170px", lg:"200px"}}
                height={{base:"170px", lg:"200px"}}
                className="HW_img"
              />
              <Heading as="h4" fontWeight="medium" mt="4">
              Book Services
              </Heading>
              <Text mt="2">
              Browse through available services, select what you need, and book instantly!
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
}
