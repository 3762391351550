import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Text,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import GoogleMapComponent from "../pages/map";

const AddressDetailsModal = ({
  isOpen,
  onClose,
  onAddressSubmit,
  profileDetailsState,
}) => {
  const { activeBg, primaryText } = useSelector((state) => state.theme);
  // const [currentPosition, setCurrentPosition] = useState({ lat: 0, lng: 0 });
  const [currentPosition, setCurrentPosition] = useState(
    profileDetailsState?.business_address || {}
  ); // Initialize with provided address or an empty object

  const [isFormVisible, setIsFormVisible] = useState(false);

  const handleChangeClick = () => {
    setIsFormVisible(!isFormVisible);
  };
  const onLocationUpdate = (info) => {
    setCurrentPosition(info); // Update current position with the selected location
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount={false}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Your Address</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <GoogleMapComponent
            onLocationUpdate={(info) => onLocationUpdate(info)}
          />

          <Box
            display="flex"
            justifyContent="space-between"
            pt="20px"
            mb="30px"
          >
            <Box>
              <Text fontSize="16px" fontWeight="700">
                Current Location
              </Text>
              <Text fontSize="14px">
                Latitude: {currentPosition?.latitude || "N/A"}, Longitude:{" "}
                {currentPosition?.longitude || "N/A"}
              </Text>
            </Box>
            <Button onClick={handleChangeClick}>EDIT ADDRESS</Button>
          </Box>

          {isFormVisible && (
            <Formik
              initialValues={{
                address: currentPosition?.address_line_one ?? "",
                address_line_two: currentPosition?.address_line_two ?? "",
                city: currentPosition?.city ?? "",
                state: currentPosition?.state ?? "",
                pincode: currentPosition?.pincode ?? "",
              }}
              enableReinitialize={true}
              validationSchema={Yup.object({
                address: Yup.string().required("Address is required"),
                address_line_two: Yup.string().required("Landmark is required"),
                city: Yup.string().required("City is required"),
                state: Yup.string().required("State is required"),
                pincode: Yup.string().required("Pincode is required"),
              })}
              onSubmit={(values, actions) => {
                onAddressSubmit(values, currentPosition); // Pass both values and currentPosition to the parent
                actions.setSubmitting(false);
              }}
            >
              {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit} >
                  <FormControl mb="10px">
                    <FormLabel>Complete Address</FormLabel>
                    <Field
                      as={Input}
                      name="address"
                      type="text"
                      placeholder="Enter Address"
                    />
                    <ErrorMessage name="address" component="div" />
                  </FormControl>
                  <FormControl mb="10px">
                    <FormLabel>Landmark</FormLabel>
                    <Field
                      as={Input}
                      name="address_line_two"
                      type="text"
                      placeholder="Landmark"
                    />
                    <ErrorMessage name="address_line_two" component="div" />
                  </FormControl>
                  <FormControl mb="10px">
                    <FormLabel>City</FormLabel>
                    <Field
                      as={Input}
                      name="city"
                      type="text"
                      placeholder="City"
                    />
                    <ErrorMessage name="city" component="div" />
                  </FormControl>
                  <FormControl mb="10px">
                    <FormLabel>State</FormLabel>
                    <Field
                      as={Input}
                      name="state"
                      type="text"
                      placeholder="State"
                    />
                    <ErrorMessage name="state" component="div" />
                  </FormControl>
                  <FormControl mb="10px">
                    <FormLabel>Pincode</FormLabel>
                    <Field
                      as={Input}
                      name="pincode"
                      type="text"
                      placeholder="Pincode"
                    />
                    <ErrorMessage name="pincode" component="div" />
                  </FormControl>
                  <Button
                    mt="20px"
                    mb="30px"
                    type="submit"
                    background={activeBg}
                    color="primaryText"
                    w="100%"
                  >
                    Save
                  </Button>
                </Form>
              )}
            </Formik>
          )}
        </ModalBody>
        {/* <ModalFooter gap="10px">
         
          <Button flex="1" colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter> */}
      </ModalContent>
    </Modal>
  );
};

export default AddressDetailsModal;
