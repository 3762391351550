import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Flex,
  Text,
  Image,
  Heading,
  Container,
  SimpleGrid,
  Stack,
  InputGroup,
  FormControl,
  Input,
  Button,
} from "@chakra-ui/react";
import logo from "../trider-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { NavLink, useLocation } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { forgotPassAction } from "../redux/auth-redux/actions";

const ForgetPassword = () => {
  const { secondryText, primaryText, footerColor, bodyTextColor } = useSelector(
    (state) => state.theme
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const boxStyle = {
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    maxWidth: "650px",
    padding: "30px",
    textAlign: "center",
    margin: "0 auto",
    borderRadius: "10px",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const initialValues = {
    email: "",
  };

  const onSubmit = async (values) => {
    // console.log("dataaaaaaa", values.email);
    setIsLoading(true);
    setErrorMessage("");
    await dispatch(forgotPassAction(values.email)).then((res) => {
      if (res?.success) {
        setIsLoading(false);
        navigate("/otp-varify");
      } else {
        setIsLoading(false);
        setErrorMessage(res?.message);
      }
    }); // Navigate to the desired route after form submission
  };

  return (
    // <Box className="forget-password" my="40px">
    //   <Container maxW="container.xl" p={4} color={footerColor}>
    //     <Box style={{ ...boxStyle }} textAlign="center">
    //       <Box>
    //         <Image src={logo} margin="0 auto"></Image>
    //       </Box>
    //       <Box mb="30px">
    //         <Heading as="h6" fontSize="28px" color={secondryText}>
    //           Forgot Password
    //         </Heading>
    //         <Text color={secondryText}>
    //           Enter your email account to reset your password
    //         </Text>
    //       </Box>
    //       <form>
    //         <Stack spacing={4}>
    //           <FormControl id="userId">
    //             <Input
    //               type="email"
    //               placeholder="example@gmail.com"
    //               color="#000000"
    //               borderColor="#000000"
    //             />
    //           </FormControl>
    //           <Link as={NavLink} to="/otp-varify" className="btnGreen">
    //             Reset Password
    //           </Link>
    //         </Stack>
    //       </form>
    //     </Box>
    //   </Container>
    // </Box>
    <Box className="forget-password" my="40px">
      <Container maxW="container.xl" p={4} color={footerColor}>
        <Box style={{ ...boxStyle }} textAlign="center">
          <Box>
            <Image src={logo} margin="0 auto" w="100px" mb="30px" />
          </Box>
          <Box mb="30px">
            <Heading as="h6" fontSize="28px" color={bodyTextColor}>
              Forgot Password
            </Heading>
            <Text color={bodyTextColor}>
              Enter your email account to reset your password
            </Text>
          </Box>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Stack spacing={4}>
                  <FormControl id="email">
                    <Field
                      name="email"
                      type="email"
                      as={Input}
                      placeholder="example@gmail.com"
                      color="#000000"
                      borderColor="#000000"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </FormControl>
                  {/* <Link as={NavLink} to="/otp-varify" className="btnGreen" onClick={onSubmit}>
                    Reset Password
                  </Link> */}
                  {errorMessage && (
                    <Text textAlign="center" style={{ color: "red" }}>
                      <p>{errorMessage}</p>
                    </Text>
                  )}
                  <Button
                    type="submit"
                    className="btnGreen"
                    isLoading={isLoading}
                    _hover={{
                      bg:"#eb7b00"
                    }}
                  >
                    Reset Password
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </Box>
  );
};

export default ForgetPassword;
