import axios from "axios";

// const API_URL = "http://triderapp.com/api"; // Replace with your API URL
import { API_URL } from "./baseURL";
export const signupUser = async (userData) => {
  try {
    const url =
      userData?.user_type === "user"
        ? `${API_URL}/customer/register`
        : `${API_URL}/provider/register`;
    const response = await axios.post(url, userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const signupFreelancer = async (formData) => {
  try {
    // console.log("formData============>", formData);
    const token = localStorage.getItem("token");
    console.log("Freelancer form-data", JSON.stringify(formData, null, 2));
    
    const response = await axios.post(
      `${API_URL}/provider/addBusinessProfile`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const signupCompany = async (formData) => {
  try {
    const token = localStorage.getItem("token");

    console.log("userData", formData);
    const response = await axios.post(
      `${API_URL}/provider/addBusinessProfile`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const signupCompanyEmployee = async (formData) => {
  try {
    // console.log("formData", formData);
    const token = localStorage.getItem("token");
    console.log(":addBusinessProfile", formData);
    
    const response = await axios.post(
      `${API_URL}/provider/addBusinessProfile`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const forgotPassword = async (userData) => {
  try {
    console.log("userData", userData);
    const response = await axios.post(
      `${API_URL}/provider/forgotPassword`,
      userData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyOtp = async (userData) => {
  try {
    const response = await axios.post(
      `${API_URL}/provider/verifyOtp`,
      userData
    );
    return response.data;
  } catch (error) {
    console.log("error=======", error);

    throw error;
  }
};

export const resetPassword = async (userData) => {
  try {
    console.log("userData", userData);
    const response = await axios.post(
      `${API_URL}/provider/resetPassword`,
      userData
    );
    return response.data;
  } catch (error) {
    console.log("error=======", error);
    throw error;
  }
};

export const loginAPI = async (userData) => {
  try {
    console.log("userData", userData);
    const url =
      userData?.providerType === "user"
        ? `${API_URL}/customer/login`
        : `${API_URL}/provider/login`;

    const response = await axios.post(url, userData);
    return response.data;
  } catch (error) {
    console.log("error=======", error);
    throw error;
  }
};

export const getCompanyProfile = async (data) => {
  try {
    console.log("data=======>", data);
    const response = await axios.get(
      `${API_URL}/company/company-profile`,
      data
    );
    return response.data;
  } catch (error) {
    console.log("error=======", error);
    throw error;
  }
};

export const changePassword = async (userData) => {
  try {
    // /provider/changePassword
    const url =
      localStorage.getItem("user_type") === "user"
        ? `${API_URL}/customer/changePassword`
        : `${API_URL}/provider/changePassword`;
    const token = localStorage.getItem("token");
    const response = await axios.post(url, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log("error=======", error);
    throw error;
  }
};

export const resendPhoneNoOTP = async () => {
  try {
    const userType = localStorage.getItem("user_type");
    const userId = localStorage.getItem("userId");

    const url =
      userType === "user"
        ? `${API_URL}/customer/resendPhoneNoOTP`
        : `${API_URL}/provider/resendPhoneNoOTP`;

    const response = await axios.post(
      url,
      {
        user_id: userId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data; // Return the API response
  } catch (error) {
    console.log("Error resending OTP:", error);
    throw error; // Rethrow error to handle in the calling function
  }
};

export const verifyPhoneNumberOtp = async (otp) => {
  try {
    const userType = localStorage.getItem("user_type"); // Get user type from localStorage
    const url =
      userType === "user"
        ? `${API_URL}/customer/verifyPhoneNumberOtp`
        : `${API_URL}/provider/verifyPhoneNumberOtp`;
    const userId = localStorage.getItem("userId");

    const response = await axios.post(
      url,
      {
        otp, // Pass the OTP dynamically
        user_id: userId, // Pass the user ID dynamically
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data; // Return the API response
  } catch (error) {
    console.log("Error verifying OTP:", error);
    throw error; // Rethrow error to handle in the calling function
  }
};
