import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
  Text,
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { changePassword } from "../api-services/auth";

const ReferralModal = ({ isOpen, onClose , referralCode }) => {
  const toast = useToast();

  // States to toggle password visibility
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Old password is required"),
      newPassword: Yup.string()
        .min(6, "New password must be at least 6 characters")
        .required("New password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm your new password"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const data = {
        old_password: values.oldPassword,
        password: values.newPassword,
        password_confirmation: values.newPassword,
      };

      try {
        const res = await changePassword(data);
        if (res?.success === false) {
          toast({
            title: "Error",
            description: res?.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Success",
            description: res?.message,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          setTimeout(() => {
            onClose();
          }, 2000);
        }
      } catch (err) {
        toast({
          title: "Error",
          description: err?.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(referralCode);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000); // Reset copy status after 2 seconds
  };

  const handleShare = async () => {
    const shareMessage = `Hey! 🎉\n\nCheck out this amazing app! Use my referral code ${referralCode} when you sign up and get ₹50 off on your first booking! 🚗💸\n\nYou can only use this reward while booking.\n\nDownload now: https://play.google.com/store/apps/details?id=com.service.trider`;
    try {
      if (navigator.share) {
        await navigator.share({
          //   title: "My Referral Code",
          text: shareMessage,
        });

        onClose();

        toast({
          title: "Success",
          description: "Code shared successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        // setIsShared(true);
        // setTimeout(() => setIsShared(false), 2000); // Reset share status after 2 seconds
      } else {
        toast({
          title: "Error",
          description: "Sharing is not supported on this device.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error sharing", error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Your Referral Code</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="referralCode" mb={4}>
            {/* <FormLabel>Copy Referral Code</FormLabel> */}
            <InputGroup>
              <Input
                type="text"
                value={referralCode}
                isReadOnly
                placeholder="Referral Code"
              />
              <InputRightElement width={isCopied ? "89px" : ""}>
                <Button variant="ghost" onClick={handleCopy}>
                  {isCopied ? "Copied!" : <CopyIcon />}
                </Button>
              </InputRightElement>
            </InputGroup>
            {isCopied && (
              <Text color="green.500" fontSize="sm" mt={1}>
                Code copied to clipboard
              </Text>
            )}
          </FormControl>
          <FormLabel>
            Refer to your Friend and family and earn reward of 50 INR for each
            joining.
          </FormLabel>
          <Text color="gray.600" fontSize="md" mt={1}>
            NOTE : Reward amount can use while booking only.
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" mr={3} onClick={handleShare}>
            Share
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReferralModal;
