// Action Types for About
export const GET_ABOUT_REQUEST = "GET_ABOUT_REQUEST";
export const GET_ABOUT_SUCCESS = "GET_ABOUT_SUCCESS";
export const GET_ABOUT_FAILURE = "GET_ABOUT_FAILURE";

// Action Types for FAQ
export const GET_FAQ_REQUEST = "GET_FAQ_REQUEST";
export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS";
export const GET_FAQ_FAILURE = "GET_FAQ_FAILURE";

// actionTypes.js
export const CREATE_CONTACT_REQUEST = 'CREATE_CONTACT_REQUEST';
export const CREATE_CONTACT_SUCCESS = 'CREATE_CONTACT_SUCCESS';
export const CREATE_CONTACT_FAILURE = 'CREATE_CONTACT_FAILURE';

// actionTypes.js
export const SUBMIT_BANK_DETAILS_REQUEST = 'SUBMIT_BANK_DETAILS_REQUEST';
export const SUBMIT_BANK_DETAILS_SUCCESS = 'SUBMIT_BANK_DETAILS_SUCCESS';
export const SUBMIT_BANK_DETAILS_FAILURE = 'SUBMIT_BANK_DETAILS_FAILURE';


export const FETCH_BANK_DETAILS_SUCCESS = 'FETCH_BANK_DETAILS_SUCCESS';
export const FETCH_BANK_DETAILS_FAILURE = 'FETCH_BANK_DETAILS_FAILURE';
// ... other action types

//Profile Details Action Types:
export const SUBMIT_PROFILE_DETAILS_REQUEST = 'SUBMIT_PROFILE_DETAILS_REQUEST';
export const FETCH_PROFILE_DETAILS_SUCCESS = 'FETCH_PROFILE_DETAILS_SUCCESS';
export const FETCH_PROFILE_DETAILS_FAILURE = 'FETCH_PROFILE_DETAILS_FAILURE';
