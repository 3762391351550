import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { Box } from "@chakra-ui/react";
import HomeBanner from "../components/Home/HomeBanner";
import AboutHome from "../components/Home/About";
import ServiceHome from "../components/Home/ServiceHome";
import GetinTouch from "../components/Home/GetinTouch";
import TestimonialHome from "../components/Home/Testimonial";
import MobileApp from "../components/Home/MobileApp";
import HowItWorks from "../components/Home/Howitsworks";
import HomeSlider from "../components/Home/HomeSlier";
const HomePage = () => {
  const navigate = useNavigate(); // Initialize navigate
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status

  // Check if token exists in local storage
  const userType = localStorage.getItem("user_type");
  useEffect(() => {
    // const token = localStorage.getItem("token");
    // if (token) {
    //   setIsLoggedIn(true); // User is logged in
    //   return () => {};
    // } else {
    //   navigate("/login"); // Redirect to login if token is not found
    //   return () => {};
    // }
  }, [navigate]);

  return (
    <Box>
      {/* {!isLoggedIn && <HomeBanner />} */}
       {/* {isLoggedIn && ( */}
      {!isLoggedIn && <HomeSlider></HomeSlider>}
      <>
        {/* <AboutHome /> */}
        {(userType === "user" || !userType) && <ServiceHome />}
        <MobileApp></MobileApp>
        <HowItWorks></HowItWorks>
        <GetinTouch />
        {/* <TestimonialHome /> */}
      </>
      {/* )} */}
    </Box>
  );
};

export default HomePage;
