import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MultiSelect } from "react-multi-select-component";
import {
  Stack,
  FormControl,
  FormLabel,
  Textarea,
  Text,
} from "@chakra-ui/react";
import { allServices } from "../api-services";
import { setProfileDetails } from "../redux/users-redux/actions";
const SelectServices = ({ nextStep, prevStep, profileDetailsState }) => {
  const dispatch = useDispatch(); // Assuming you're using Redux

  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState(null);

  const [selectedService, setSelectedService] = useState([]);
  const [selected, setSelected] = useState([]);
  const [bio, setBio] = useState(
    profileDetailsState?.business_profile?.bio || ""
  );

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const data = await allServices();
        if (data?.response) {
          const serviceOptions = data.response.map((service) => ({
            label: service?.name,
            value: service?.id,
          }));
          setServices(serviceOptions);
          if (profileDetailsState) {
            const s = profileDetailsState?.business_services?.map((n) => ({
              label: n?.service?.name,
              value: n?.service_id,
            }));
            if (s) {
              setSelected(s);
            }

            const selectS = profileDetailsState?.business_services?.map(
              (n) => n?.service_id
            );
            if (selectS) {
              setSelectedService(selectS);
            }
          }
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, [profileDetailsState, prevStep]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Basic form validation (ensure at least one service is selected, bio is not empty)
    if (selectedService?.length === 0) {
      setValidationError("Please select at least one service.");
      return;
    }

    if (bio?.length < 10) {
      setValidationError("Bio/About Self must be at least 10 characters long.");
      return;
    }
    setValidationError("");

    const formData = {
      service: selectedService,
      bio,
    };
    const Data = [];
    selected?.map((n) => {
      Data.push({
        service_id: n?.value,
        service: { name: n.label },
      });
    });
    const formattedValues = {
      business_profile: {
        bio: bio,
      },
      business_services: Data,
    };
    // Ensure profileDetailsState exists and initialize if necessary
    const existingProfile = profileDetailsState?.business_profile || {};
    const existingServices = profileDetailsState?.business_services || [];

    // Merge formatted values with existing state
    const mergedValues = {
      ...profileDetailsState, // Spread existing state
      business_profile: {
        ...existingProfile, // Merge existing business profile details
        ...formattedValues.business_profile, // Override with new values
      },
      business_services: formattedValues.business_services || existingServices, // Merge business services
    };

    // Dispatch the action with merged values
    dispatch(setProfileDetails(mergedValues));
    nextStep(formData); // Pass data to the next step
  };
  console.log(
    "selectedService==========profileDetailsState",
    profileDetailsState
  );

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={4}>
        <FormControl>
          <FormLabel>Select Service</FormLabel>
          <MultiSelect
            options={services}
            value={selected}
            onChange={(selected) => {
              setSelected(selected);
              setSelectedService(selected?.map((s) => s.value));
            }}
            labelledBy="Select"
          />
        </FormControl>

        <FormControl>
          <FormLabel>Bio/About Self</FormLabel>
          <Textarea
            value={bio}
            onChange={(e) => setBio(e.target.value)}
            placeholder="Write about yourself"
          />
        </FormControl>
        <Text color="red">{validationError}</Text>
        <Stack direction="row" spacing={4}>
          <button onClick={prevStep} className="btnGreen">
            Back
          </button>
          <button type="submit" className="btnGreen">
            Next
          </button>
        </Stack>
      </Stack>
    </form>
  );
};

export default SelectServices;
