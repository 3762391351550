// reducer.js

import {
  UPDATE_THEME,
  SET_SIDEBAR_VISIBILITY,
  SET_SIDEBAR_OPEN_ALWAYS,
  SET_ACTIVE_VARIABLE,
} from "./actionTypes";

// const initialState = {
//   primaryText: "#ffffff",
//   secondryText: "#0A3331",
//   acitveColor: "#D6B983",
//   acitveBorder: "#D6B983",
//   primaryBg: "#ffffff",
//   subHeaderBg: "#D6B983",
//   headerBg: "#0A3331",
//   subMenuBg: "#D6B983",
//   activeBg: "#0A3331",
//   footerBg: "#0A3331",
//   footerColor: "#D6B983",
//   serviceBgHome: "#FF02000A",
//   serviceColor: "#3D3C5C",
//   borderColor: "#CDB27F",
//   textColor: "#696969",
//   headingColor: "#333333",
//   iconGreen: "#4AAD38",
// };

// New color Theme
const initialState = {
  primaryText: "#ffffff",
  secondryText: "#1E88E5",
  acitveColor: "#D6B983",
  acitveBorder: "#1E88E5",
  primaryBg: "#ffffff",
  subHeaderBg: "#000",
  headerBg: "#1E88E5",
  subMenuBg: "#ffffff",
  activeBg: "#1E88E5",
  footerBg: "#000000",
  footerColor: "#ffffff",
  serviceBgHome: "#bde0ff3b",
  serviceColor: "#3D3C5C",
  borderColor: "#CDB27F",
  textColor: "#696969",
  headingColor: "#333333",
  iconGreen: "#4AAD38",
  darkBlue: "#000080",
  bgBlack: "#000000",
  colorBlack: "#000000",
  bodyTextColor:"#212126",
  blackmedium:"#404048",
  redcolor:"#FF0000",
  lightBlue:"##f3f3ff",
  pageBg:"#f3f3f3",
};

const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_THEME:
      return {
        ...state,

        ...state.theme,
        ...action.payload,
      };
    case SET_SIDEBAR_VISIBILITY:
      return {
        ...state,
        sidebarVisible: action.payload,
      };
    case SET_SIDEBAR_OPEN_ALWAYS:
      return {
        ...state,
        sidebarVisibleAlways: action.payload,
      };
    case SET_ACTIVE_VARIABLE:
      return {
        ...state,
        activeVariable: action.payload,
      };

    default:
      return state;
  }
};

export default themeReducer;
