import React from "react";
import NodataFound from "./NoDataFound";
import notFound from "../assets/images/page_not_found.png";

const NotFound = () => {
  return (
    <>
      <NodataFound message={"No Page found."} notFoundImg={notFound} notFoundMargin="70px" is404={false}/>
    </>
  );
};

export default NotFound;
