import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  VStack,
  HStack,
  StackDivider,
  Divider,
  Container,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  useToast,
} from "@chakra-ui/react";
import { FaRupeeSign } from "react-icons/fa"; // Icon for Rupee
import { getWallet, getProviderWallet, withdrawRequest } from "../api-services";

const WalletModal = ({ isOpen, onClose }) => {
  const userType = localStorage.getItem("user_type");
  const {
    footerColor,
    activeBg,
    primaryBg,
    secondryText,
    primaryText,
    redDark,
  } = useSelector((state) => state.theme);
  const transactions = [
    { id: "TRX12345", name: "Withdrawal", amount: "-₹500" },
    { id: "TRX12346", name: "Received", amount: "+₹2000" },
  ];

  const referrals = [
    { id: "REF12345", name: "John Doe", payment: "₹100" },
    { id: "REF12346", name: "Jane Smith", payment: "₹150" },
  ];

  const balanceCard = {
    background: "rgb(10,51,49)",
    background:
      "-moz-radial-gradient(circle, rgba(10,51,49,1) 0%, rgba(34,71,70,1) 51%, rgba(19,91,87,1) 100%)",
    background:
      "-webkit-radial-gradient(circle, rgba(10,51,49,1) 0%, rgba(34,71,70,1) 51%, rgba(19,91,87,1) 100%)",
    background:
      "radial-gradient(circle, rgba(10,51,49,1) 0%, rgba(34,71,70,1) 51%, rgba(19,91,87,1) 100%)",
    filter:
      "progid:DXImageTransform.Microsoft.gradient(startColorstr='#0a3331',endColorstr='#135b57',GradientType=1)",
  };

  const [isWithdrawModalOpen, setWithdrawModalOpen] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [error, setError] = useState(null);
  const [walletAmt, setWalletAmt] = useState(0);
  const [providerWalletAmt, setProviderWalletAmt] = useState(0);
  const [loading, setLoading] = useState(true);
  const toast = useToast();

  const handleWithdrawClick = () => {
    setWithdrawModalOpen(true);
  };

  const handleWithdrawModalClose = () => {
    setWithdrawModalOpen(false);
    setWithdrawAmount(""); // Clear input when modal closes
  };

  useEffect(() => {
    console.log("withdrawAmount", withdrawAmount);
  }, [withdrawAmount]);

  const fetchWalletData = async () => {
    setError("");
    try {
      const res = await getWallet();
      setWalletAmt(res || {});
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchProviderWalletData = async () => {
    setError("");
    try {
      const res = await getProviderWallet();
      setProviderWalletAmt(res || {});
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const userType = localStorage.getItem("user_type");
    if (userType === "user") {
      fetchWalletData();
    }

    if (userType !== "user") {
      fetchProviderWalletData();
    }
  }, []);

  const handleWithdrawRequest = () => {
    const data = {
      amount: parseFloat(withdrawAmount),
    };

    const withdrawRequestData = async (requestData) => {
      try {
        setLoading(true);
        const response = await withdrawRequest(requestData); // Fetch the response from withdrawRequest API
        handleWithdrawModalClose();
        fetchProviderWalletData();

        if (response?.success) {
          toast({
            title: "Success",
            description: response?.message,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          // Assuming that you need to set some chat list data on success
          // setChatList(response?.response);
          // setFilteredUsers(response?.response); // If you need filtered users
        }

        // Optionally, you can fetch more messages or data if needed
        // fetchMessages(selectedUser);
      } catch (error) {
        setError("Failed to process withdrawal request"); // Set appropriate error message
      } finally {
        setLoading(false); // Stop loading after the request completes
      }
    };

    withdrawRequestData(data);
  };

  return (
    <Box className="wallet-modal" as="footer" my="40px">
      <Container maxW="container.xl" p={4} color={footerColor}>
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          size="lg"
          scrollBehavior="inside"
        >
          <ModalOverlay />
          <ModalContent borderRadius="md" boxShadow="lg">
            <ModalHeader>Wallet</ModalHeader>
            <ModalCloseButton />
            <ModalBody background="#edededdb">
              <Flex
                mb={6}
                style={{ ...balanceCard }}
                p="30px 20px"
                textAlign="center"
                borderRadius="20px"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
              >
                <Box textAlign="left">
                  <Text fontSize="16px" color={primaryText} fontWeight="400">
                    Total Balance
                  </Text>
                  <Text
                    fontSize="4xl"
                    color={primaryText}
                    fontWeight="600"
                    lineHeight="30px"
                  >
                    {userType == "user"
                      ? `  ₹ ${walletAmt?.wallet_amount || 0} /-`
                      : `₹ ${providerWalletAmt?.wallet_amount || 0} /-`}
                  </Text>
                </Box>
                {userType == "user" ? null : (
                  <Button
                    borderRadius="20px"
                    bg={primaryBg}
                    color={secondryText}
                    onClick={handleWithdrawClick}
                  >
                    Withdraw
                  </Button>
                )}
              </Flex>

              {userType === "user" ? (
                <Box mb={6} mt={6}>
                  <Heading as="h4" size="md" fontWeight="600">
                    Transactions
                  </Heading>
                  <VStack
                    spacing={4}
                    align="stretch"
                    divider={<StackDivider borderColor={primaryText} />}
                    mt={2}
                  >
                    {walletAmt &&
                      walletAmt?.response?.transactions?.map((trx, index) => (
                        <HStack
                          key={index}
                          justifyContent="space-between"
                          bg="#fff"
                          p="10px"
                          borderRadius="6px"
                          boxShadow="rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px"
                        >
                          <Box>
                            <Text fontWeight="bold">
                              {trx?.type?.charAt(0)?.toUpperCase() +
                                trx?.type?.slice(1)}
                            </Text>

                            <Text fontSize="sm" color="gray.500">
                              {trx.order_no && `Id: ${trx.order_no}`}
                            </Text>
                          </Box>
                          <Text fontWeight="bold">
                            {trx?.status === "credited"
                              ? `+ ${trx.amount}`
                              : `- ${trx.amount}`}
                          </Text>
                        </HStack>
                      ))}
                  </VStack>
                </Box>
              ) : (
                <Box mb={6} mt={6}>
                  <Heading as="h4" size="md" fontWeight="600">
                    Transactions
                  </Heading>
                  <VStack
                    spacing={4}
                    align="stretch"
                    divider={<StackDivider borderColor={primaryText} />}
                    mt={2}
                  >
                    {providerWalletAmt &&
                      providerWalletAmt?.response?.map((trx, index) => (
                        <HStack
                          key={index}
                          justifyContent="space-between"
                          bg="#fff"
                          p="10px"
                          borderRadius="6px"
                          boxShadow="rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px"
                        >
                          <Box>
                            <Text fontWeight="bold">
                              {trx?.status?.charAt(0)?.toUpperCase() +
                                trx?.status?.slice(1)}
                            </Text>

                            <Text fontSize="sm" color="gray.500">
                              Id: {trx.withdraw_code}
                            </Text>
                          </Box>
                          <Text fontWeight="bold">{trx.amount}</Text>
                        </HStack>
                      ))}
                  </VStack>
                </Box>
              )}

              <Divider />

              {/* <Box mb={6} mt={6}>
                <Heading as="h4" size="md" fontWeight="600">
                  Referrals
                </Heading>
                <VStack
                  spacing={4}
                  align="stretch"
                  divider={<StackDivider borderColor={primaryText} />}
                  mt={2}
                >
                  {referrals.map((ref, index) => (
                    <HStack
                      key={index}
                      justifyContent="space-between"
                      bg="#fff"
                      p="10px"
                      borderRadius="6px"
                      boxShadow="rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px"
                    >
                      <Box>
                        <Text fontWeight="bold">{ref.name}</Text>
                        <Text fontSize="sm" color="gray.500">
                          Id: {ref.id}
                        </Text>
                      </Box>
                      <Text fontWeight="bold">{ref.payment}</Text>
                    </HStack>
                  ))}
                </VStack>
              </Box> */}
            </ModalBody>
          </ModalContent>
        </Modal>

        {/* Withdrawal Modal */}
        <Modal isOpen={isWithdrawModalOpen} onClose={handleWithdrawModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Withdraw Amount</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <InputGroup mb={4}>
                <InputLeftElement
                  pointerEvents="none"
                  children={<Icon as={FaRupeeSign} />}
                />
                <Input
                  placeholder="Enter amount"
                  value={withdrawAmount}
                  onChange={(e) => setWithdrawAmount(e.target.value)}
                  type="number"
                />
              </InputGroup>
            </ModalBody>
            <ModalFooter>
              <Button variant="ghost" onClick={handleWithdrawModalClose}>
                Cancel
              </Button>
              <Button
                colorScheme="teal"
                ml={3}
                bg="#08c71f"
                onClick={handleWithdrawRequest}
              >
                Withdraw
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Container>
    </Box>
  );
};

export default WalletModal;
