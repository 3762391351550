import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  Stack,
  Icon,
  useToast,
} from "@chakra-ui/react";
import { FaHeart, FaRegHeart, FaUser, FaMapMarkerAlt } from "react-icons/fa";
import Rating from "../../components/rating"; // Update the path if necessary
import { allServices, manageHeart } from "../../api-services/booking"; // Import your heart management API function
import PaymentSelect from "../../components/PaymentSelect";
import { useNavigate } from "react-router-dom";
import AllServicesList from "../../components/AllServicesList";

const WishListProviderCard = ({
  key,
  provider,
  secondryText,
  lightBlue,
  bodyTextColor,
  redcolor,
  onClick,
  refresh,
}) => {
  const [isHearted, setIsHearted] = useState(provider?.is_favourite === 1);
  const [loading, setLoading] = useState(false);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [services, setServices] = useState([]);
  const [error, setError] = useState(null);
  const toast = useToast();
  const navigate = useNavigate();
  const handleHeartClick = async (e) => {
    e.stopPropagation(); // Prevents triggering parent onClick
    try {
      setLoading(true);
      await manageHeart(provider?.business_address?.user_id); // Call the heart API with provider ID
      setIsHearted(!isHearted); // Toggle heart status
      refresh();
    } catch (error) {
      console.error("Error managing heart:", error);
      toast({
        title: "Error",
        description: "There was an error updating the favorite status.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCard = (e) => {
    if (localStorage.getItem("token")) {
      setIsPaymentOpen(!isPaymentOpen);
    } else {
      navigate("/customer-login");
    }
  };

  const selectedPaymentType = (event) => {
    navigate(
      `/booking?paymentMode=${event?.payment}&chooseProvider=${event?.provider}&id=${provider?.business_address?.user_id}`
    );
  };

  // useEffect(() => {
  //   setIsServiceOpen(true);
  // }, []);

  // useEffect(() => {}, [onClose]);



  return (
    <>
      <Box
        borderRadius="md"
        pointer="cursor"
        p={4}
        bg={lightBlue}
        className="bookingCardInner"
        mb="20px"
        border="1px solid #1E88E5"
        transition="all .3s"
        _hover={{
          boxShadow: "#84c1f3 0px 0px 8px",
        }}
        position="relative" // Ensure proper positioning of heart icon
        onClick={handleCard}
      >
        <Flex mb={4} gap="10px" justifyContent="space-between">
          <Flex justifyContent="space-between">
            <Flex>
              <Image
                boxSize="50px"
                borderRadius="full"
                src={provider?.image}
                alt="Provider Image"
                mr={4}
              />
              <Box flex="1">
                <Box>
                  <Text fontWeight="bold" color={bodyTextColor}>
                    {provider?.name}
                  </Text>
                  <Flex alignItems="center" color="#000">
                    <Rating
                      total_rating={provider?.total_rating}
                      reviewsCount={provider?.total_review}
                      bodyTextColor={bodyTextColor}
                    />
                  </Flex>
                </Box>
              </Box>
            </Flex>
          </Flex>
          <Box
            className="wishListIcon"
            position="absolute"
            right="15px"
            top="15px"
            cursor="pointer"
            p="5px"
            borderRadius="50%"
            bg={true ? redcolor : "transparent"}
            color={true ? "white" : redcolor}
            transition="background-color 0.3s ease"
            onClick={handleHeartClick}
          >
            <FaHeart size={20} color={true ? "red" : "gray"} />
          </Box>
        </Flex>
        <Stack spacing={2} mb={4}>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            gap="5px"
          >
            <Flex gap="5px" alignItems="center">
              <Icon as={FaUser} mr={2} color={secondryText} />
              <Text color={bodyTextColor}>
                No. of services : {provider?.total_service}
              </Text>
            </Flex>
            <Flex gap="5px" alignItems="center">
              <Icon as={FaMapMarkerAlt} mr={2} color={secondryText} />
              <Text color={bodyTextColor}>Location</Text>
            </Flex>
          </Flex>
        </Stack>
      </Box>

      {isPaymentOpen && (
        <PaymentSelect
          isOpen={isPaymentOpen}
          onClose={() => setIsPaymentOpen(false)}
          paymentMethod={(e) => selectedPaymentType(e)}
          selectedProviderData={"freelancer_prices"}
        />
      )}


    </>
  );
};

export default WishListProviderCard;
