import { useSelector } from "react-redux";
import React from 'react';
import {
    Box,
    Text,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Flex,
    Image,
    Heading,
    Stack,
    Input,
    FormLabel,
    FormControl,
    Textarea,
    Select,
    Checkbox,
    Icon
} from '@chakra-ui/react';
import profile from '../../../assets/images/profile.png';
import { IoStar } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import { NavLink, useLocation } from 'react-router-dom';
import { FaArrowLeft } from "react-icons/fa";
const CompanyEmployeeDetails = () => {

    const {
        secondryText,
        primaryText,
        footerColor,
        headingColor,
        rating,
        activeBg,
    } = useSelector((state) => state.theme);

    return (
        <Box className="" maxW="700px" my="60px" p="30px" boxShadow=" rgba(0, 0, 0, 0.35) 0px 5px 15px" mx="auto">
            <Button variant="link" as={NavLink} to="/company-employees">
                <Icon as={FaArrowLeft} />
            </Button>
            <Flex direction="column" align="center" mb={4}>
                <Image borderRadius="full" boxSize="100" src={profile} alt="Profile Picture" mb={4} />
                <Heading as="h4" size="lg" mb={2} color={headingColor} textTransform='uppercase'>
                    Sarbjeet
                </Heading>
                <Box>
                    <Box className="comapnyUniqueID" >Premium Unique ID: <Box as="span" fontWeight="700">45424DEF45J</Box></Box>
                </Box>
                <Flex alignItems="center" gap="5px">
                    <Text color={secondryText} fontSize='18px' fontWeight="700">4.5</Text>
                    <Flex>
                        <IoStar color={rating} /><IoStar color={rating} /><IoStar color={rating} /><IoStar color={rating} /><IoStar color={rating} />
                    </Flex>
                </Flex>
                <Text color={secondryText} mb={4}>123 Reviews</Text>
            </Flex>
            <Stack spacing={4} pb="30px">
                <FormControl>
                    <FormLabel>Mobile Number</FormLabel>
                    <Input type="tel" defaultValue="+91 9876543210" isReadOnly />
                </FormControl>

                <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input type="email" defaultValue="example@example.com" isReadOnly />
                </FormControl>

                <FormControl>
                    <FormLabel>Total Years of Experience</FormLabel>
                    <Input type="number" defaultValue="5" isReadOnly />
                </FormControl>

                <FormControl>
                    <FormLabel>Days of Availability</FormLabel>
                    <Stack spacing={2} flexDirection="row" flexWrap="wrap">
                        <Checkbox defaultChecked isReadOnly>
                            Monday
                        </Checkbox>
                        <Checkbox defaultChecked isReadOnly>
                            Thuesday
                        </Checkbox>
                        <Checkbox defaultChecked isReadOnly>
                            Wednesday
                        </Checkbox>
                        <Checkbox defaultChecked isReadOnly>
                            Thursday
                        </Checkbox>
                        <Checkbox defaultChecked isReadOnly>
                            Friday
                        </Checkbox>
                        <Checkbox isReadOnly>Saturday</Checkbox>
                        <Checkbox isReadOnly>Sunday</Checkbox>
                    </Stack>
                </FormControl>

                <Flex justifyContent="center" gap="10px">
                    <FormControl>
                        <FormLabel>Aadhaar Card (Front)</FormLabel>
                        <Image
                            src="https://via.placeholder.com/150"
                            alt="Aadhaar Front"
                            borderRadius="md"
                            w="100%"
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Aadhaar Card (Back)</FormLabel>
                        <Image
                            src="https://via.placeholder.com/150"
                            alt="Aadhaar Back"
                            borderRadius="md"
                            w="100%"
                        />
                    </FormControl>
                </Flex>

                <FormControl>
                    <FormLabel>Service Name</FormLabel>
                    <Input type="text" defaultValue="Web Development" isReadOnly />
                </FormControl>

                <FormControl>
                    <FormLabel>Subservice Name</FormLabel>
                    <Input type="text" defaultValue="Frontend Development" isReadOnly />
                </FormControl>

                <FormControl>
                    <FormLabel>About</FormLabel>
                    <Textarea
                        defaultValue="Experienced frontend developer with expertise in React and Chakra UI."
                        isReadOnly
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Location</FormLabel>
                    <Input type="text" defaultValue="Bangalore, India" isReadOnly />
                </FormControl>
                <Flex justifyContent="space-between" mt="20px" gap="10px">
                    <Button colorScheme="green" flex="1">Accept</Button>
                    <Button colorScheme="red" flex="1">Decline</Button>
                </Flex>
            </Stack>
        </Box>
    );
};


export default CompanyEmployeeDetails;
