import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "../../trider-logo.png";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";

// Import required modules
import { Autoplay, Pagination } from "swiper/modules"; // Add Pagination here


import { Box, Heading, Text, Button, Image } from "@chakra-ui/react";

import slider1 from "../../assets/images/home/slider/Heavy_Lift.jpg";
import slider2 from "../../assets/images/home/slider/Slider_2.jpg";
import slider3 from "../../assets/images/home/slider/Slider_3.jpg";
import slider4 from "../../assets/images/home/slider/Slider_4.jpg";

// import slider1 from "../../assets/images/home/slider/slide-new1.jpg";
// import slider2 from "../../assets/images/home/slider/slide-new2.jpg";
// import slider3 from "../../assets/images/home/slider/slide-new3.jpg";

const HomeSlider = () => {
  const { footerBg, primaryText, secondryText, footerColor } = useSelector(
    (state) => state.theme
  );

  const SliderContentStyle = {
    background: "transparent",
    // background: "rgba(255, 255, 255, 0.21)",
    // borderRadius: "16px",
    // boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    // backdropFilter: "blur(2.2px)",
    // border: "1px solid rgba(255, 255, 255, 0.19)",
  };
  const navigate = useNavigate();
  return (
    <Box
      className="homesliderWr"
      position="relative"
      // _before={{
      //   position:"absolute",
      //   top:'0',
      //   left:"0",
      //   width:"100%",
      //   height:"100%",
      //   background:"#000000",
      //   opacity:".3"
      // }}
    >
      <>
        <Swiper
          // pagination={true}
          slidesPerView={1}
          spaceBetween={0}
          // navigation={true}
          modules={[Pagination, Autoplay]}
          autoplay={{ delay: 4000, disableOnInteraction: false }}
          loop={true}
          effect="fade"
          speed={2000}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
          }}
          className="homeSliders"
        >
          <SwiperSlide>
            <Image src={slider1} w="100%" maxH="750px" objectFit="cover"></Image>
          </SwiperSlide>
          <SwiperSlide>
            <Image src={slider2} w="100%" maxH="750px" objectFit="cover"></Image>
          </SwiperSlide>
          <SwiperSlide>
            <Image src={slider3} w="100%" maxH="750px" objectFit="cover"></Image>
          </SwiperSlide>
          <SwiperSlide>
            <Image src={slider4} w="100%" maxH="750px" objectFit="cover"></Image>
          </SwiperSlide>
        </Swiper>
        <Box
          className="homeSliderContent"
          textAlign="left"
          position="absolute"
          zIndex="3"
          top="50%"
          left="10%"
          transform="translate( 0, -50% )"
          // background="#ffffff"
          style={{ ...SliderContentStyle }}
        >
          <Text
            className="subtitle"
            textAlign="left"
            textTransform="uppercase"
            fontWeight="500"
            color="#119d02"
          >
           Experience Service Like Never Before
          </Text>
          <Heading as="h1" mb="10px" className="titile">
            <Text as="span" display="inline-block">
              TRIDER
            </Text>{" "}
            Service Providers
          </Heading>
          {/* <Text
            mb={4}
            color="#ffffff"
            textShadow="0 0 #ffffff"
            fontWeight="300"
            letterSpacing="0.6px"
          >
            At Trider, we are dedicated to providing a wide range of top-tier
            services designed to make your life easier, safer, and more
            convenient.
          </Text> */}
          {!localStorage.getItem("isLogin") && (
            <Box
              className="sliderBtn"
              display="flex"
              justifyContent="left"
              gap={4}
              mb={4}
              flexWrap="wrap"
            >
              <Button
                className="sliderLoginBtn"
                onClick={() => {
                  navigate("/customer-login");
                }}
              >
                Login
              </Button>
              <Button className="sliderSignupBtn"
               onClick={() => {
                navigate("/customer-registration");
              }}
              >SignUp</Button>
            </Box>
          )}
          {/* <Image className="sliderLogo"
            src={logo}
            alt="Image Description"
          /> */}
        </Box>
      </>
    </Box>
  );
};

export default HomeSlider;
