import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Flex,
  Text,
  Image,
  Heading,
  Button,
  Container,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Icon,
  Stack,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  HStack,
} from "@chakra-ui/react";

import { FaUser, FaArrowLeft } from "react-icons/fa";
import { FaCalendarAlt, FaMapMarkerAlt } from "react-icons/fa";
import { MdCurrencyRupee } from "react-icons/md";
import { FaMapLocationDot } from "react-icons/fa6";
import { StarIcon, ChatIcon } from "@chakra-ui/icons";
import { getCustomerBooking } from "../../api-services";
import { NavLink } from "react-router-dom";
import Rating from "../../components/rating"; // Update the path if necessary
import SpinnerComponent from "../../components/Spinner";
import NodataFound from "../../components/NoDataFound";
// Provider Details
import provider from "../../assets/images/customer/booking-history/customer-img.svg";
import BookingDetails from "./BookingDetails";
const formatAddress = (address) => {
  const { address_line_one, address_line_two, city, state, pincode } = address;
  return `${address_line_one}${
    address_line_two ? `, ${address_line_two}` : ""
  }, ${city}, ${state} - ${pincode}`;
};

const CustomerBookingHistory = () => {
  const {
    secondryText,
    primaryText,
    footerColor,
    headingColor,
    bodyTextColor,
    activeBg,
    mediumGray,
    redDark,
    darkYellow,
    acitveColor,
    acitveBorder,
    lightBlue,
    blackmedium,
  } = useSelector((state) => state.theme);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [bookings, setBookings] = useState([]);
  const [bookingType, setBookingType] = useState("new");
  const [selectedProviderData, setSelectedProviderData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const res = await getCustomerBooking(bookingType);
        setBookings(res?.response || []);
        setError(null);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [bookingType]);
  if (loading) return <SpinnerComponent />;

  const handleOpenProviderModal = (provider) => {
    setSelectedProviderData(provider);
    console.log("provider======", selectedProviderData);
    console.log("provider kjhj provider" , provider);

    if (provider) {
      onOpen();
    }
  };

  return (
    <Box p={4} className="bookingHistoryPage">
      <Container maxW="container.xl" p={4} color={footerColor}>
        <Box>
          <Tabs>
            <Box className="" border>
              <TabList
                className="tabsList"
                mx="auto"
                borderRadius="6px"
                border="0"
                gap="8px"
                p="8px"
                justifyContent="center"
                maxW="440px"
                bg={activeBg}
                flexWrap="wrap"
                fontSize={{ base: "14px", sm: "16px" }}
              >
                <Tab
                  border="0"
                  flex="1"
                  fontSize={{ base: "14px", sm: "16px" }}
                  onClick={() => setBookingType("new")}
                >
                  New Booking
                </Tab>
                <Tab
                  border="0"
                  flex="1"
                  fontSize={{ base: "14px", sm: "16px" }}
                  onClick={() => setBookingType("in_progress")}
                >
                  In Progress
                </Tab>
                <Tab
                  border="0"
                  flex="1"
                  fontSize={{ base: "14px", sm: "16px" }}
                  onClick={() => setBookingType("completed")}
                >
                  Completed
                </Tab>
              </TabList>
            </Box>

            <TabPanels>
              <TabPanel py="30px" px="0">
                <SimpleGrid
                  columns={{ base: 1, sm: 2, md: 3, xl: 4 }}
                  spacing={4}
                  className="bookingCardWr"
                >
                  {bookings?.reverse().map((_, index) => (
                    <Box
                      onClick={() => handleOpenProviderModal(_)}
                      key={index}
                      className="bookingCard"
                    >
                      <Box
                        border="1px"
                        className="bookingCardInner"
                        borderColor={acitveBorder}
                        borderRadius="md"
                        p={4}
                        bg={lightBlue}
                        _hover={{
                          boxShadow:
                            "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                        }}
                      >
                        <Flex mb={4} gap="10px" justifyContent="space-between">
                          <Flex>
                            <Image
                              boxSize="50px"
                              borderRadius="full"
                              src={_?.booking_services[0]?.service_image}
                              alt="Customer Image"
                              mr={4}
                            />
                            <Box flex="1">
                              <Box color={blackmedium}>
                                <Text fontWeight="bold" color={bodyTextColor}>
                                  {_?.booking_services[0]?.service_name}
                                </Text>
                                <Rating
                                  total_rating={_?.business_rating}
                                  reviewsCount={_?.business_review}
                                />
                              </Box>
                            </Box>
                          </Flex>
                        </Flex>
                        <Stack spacing={2} mb={4}>
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            flexWrap="wrap"
                            gap="5px"
                          >
                            <Flex gap="5px" alignItems="center">
                              <Text fontSize="sm" color={blackmedium}>
                                <Text fontWeight="600" as="span">
                                  {" "}
                                  ID :
                                </Text>{" "}
                                <Text
                                  as="span"
                                  fontWeight="400"
                                  color={blackmedium}
                                >
                                  {_?.order_no}
                                </Text>
                              </Text>
                            </Flex>
                            <Flex gap="5px" alignItems="center">
                              <Icon
                                as={FaCalendarAlt}
                                mr={2}
                                color={secondryText}
                              />
                              <Text color={blackmedium}>{_?.booking_date}</Text>
                            </Flex>
                          </Flex>
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            flexWrap="wrap"
                            gap="5px"
                          >
                            <Flex gap="5px" alignItems="center">
                              <Icon as={FaUser} mr={2} color={secondryText} />
                              <Text color={blackmedium}>
                                Manpower: {_?.booking_services[0].qty}
                              </Text>
                            </Flex>
                            <Flex gap="5px" alignItems="center">
                              <Icon
                                as={FaMapMarkerAlt}
                                mr={2}
                                color={secondryText}
                              />
                              <Text color={blackmedium}>
                                {formatAddress(_?.booking_address)}
                              </Text>
                            </Flex>
                          </Flex>
                        </Stack>
                        <Flex gap="10px" mt="10px">
                          {/* <Button colorScheme="blue" flex="1">
                            Chat Now
                          </Button> */}
                          <Button colorScheme="green" flex="1">
                            View
                          </Button>
                        </Flex>
                      </Box>
                    </Box>
                  ))}
                </SimpleGrid>
              </TabPanel>


              {/* ====== second tab error =======   */}
              {/* <TabPanel py="30px" px="0">
                <SimpleGrid
                  columns={{ base: 1, sm: 2, md: 3, xl: 4 }}
                  spacing={4}
                  className="bookingCardWr"
                >
                  {bookings?.map((_, index) => (
                    <Box onClick={onOpen} key={index} className="bookingCard">
                      <Box
                        border="1px"
                        className="bookingCardInner"
                        borderColor={acitveBorder}
                        borderRadius="md"
                        p={4}
                        bg={lightBlue}
                        _hover={{
                          boxShadow:
                            "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                        }}
                      >
                        <Flex mb={4} gap="10px" justifyContent="space-between">
                          <Flex>
                            <Image
                              boxSize="50px"
                              borderRadius="full"
                              src={_?.booking_services[0]?.service_image}
                              alt="Customer Image"
                              mr={4}
                            />
                            <Box flex="1">
                              <Box>
                                <Text fontWeight="bold" color={bodyTextColor}>
                                 {_?.booking_services[0]?.service_name}
                                </Text>
                                <Rating
                                  total_rating={_?.business_rating}
                                  reviewsCount={_?.business_review}
                                />
                              </Box>
                            </Box>
                          </Flex>
                        </Flex>
                        <Stack spacing={2} mb={4}>
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            flexWrap="wrap"
                            gap="5px"
                          >
                            <Flex gap="5px" alignItems="center">
                              <Text fontSize="sm">
                                <Text
                                  fontWeight="600"
                                  as="span"
                                  color={blackmedium}
                                >
                                  {" "}
                                  ID :
                                </Text>{" "}
                                <Text
                                  as="span"
                                  fontWeight="400"
                                  color={blackmedium}
                                >
                                  {_?.order_no}
                                </Text>
                              </Text>
                            </Flex>
                            <Flex gap="5px" alignItems="center">
                              <Icon
                                as={FaCalendarAlt}
                                mr={2}
                                color={secondryText}
                              />
                              <Text color={blackmedium}>{_?.booking_date}</Text>
                            </Flex>
                          </Flex>
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            flexWrap="wrap"
                            gap="5px"
                          >
                            <Flex gap="5px" alignItems="center">
                              <Icon as={FaUser} mr={2} color={secondryText} />
                              <Text color={blackmedium}>
                               Manpower: {_?.booking_services[0].qty}
                              </Text>
                            </Flex>
                            <Flex gap="5px" alignItems="center">
                              <Icon
                                as={FaMapMarkerAlt}
                                mr={2}
                                color={secondryText}
                              />
                              <Text color={blackmedium}>
                                {formatAddress(_?.booking_address)}
                              </Text>{" "}
                            </Flex>
                          </Flex>
                        </Stack>
                        <Flex justifyContent="space-between">
                          {_?.booking_status === "in_progress" && (
                            <Text color="#000" fontWeight="700">
                              Start OTP:{" "}
                              <Text as="span" color="red">
                                {_?.booking_otp?.start_time_otp}
                              </Text>
                            </Text>
                          )}
                          {_?.booking_status === "job-in" && (
                            <>
                              <Text color="#000" fontWeight="700">
                                End OTP:{" "}
                                <Text as="span" color="red">
                                  {_?.booking_otp?.end_time_otp}
                                </Text>
                              </Text>
                              <Flex justifyContent="space-between">
                                <Button colorScheme="blue" flex="1">
                                  Chat Now
                                </Button>
                              </Flex>
                            </>
                          )}
                        </Flex>
                      </Box>
                    </Box>
                  ))}
                </SimpleGrid>
              </TabPanel> */}

              <TabPanel py="30px" px="0">
                <SimpleGrid
                  columns={{ base: 1, sm: 2, md: 3, xl: 4 }}
                  spacing={4}
                  className="bookingCardWr"
                >
                  {bookings?.reverse().map((_, index) => (
                    <Box
                      onClick={() => handleOpenProviderModal(_)}
                      key={index}
                      className="bookingCard"
                    >
                      <Box
                        border="1px"
                        className="bookingCardInner"
                        borderColor={acitveBorder}
                        borderRadius="md"
                        p={4}
                        bg={lightBlue}
                        _hover={{
                          boxShadow:
                            "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                        }}
                      >
                        <Flex mb={4} gap="10px" justifyContent="space-between">
                          <Flex>
                            <Image
                              boxSize="50px"
                              borderRadius="full"
                              src={_?.booking_services[0]?.service_image}
                              alt="Customer Image"
                              mr={4}
                            />
                            <Box flex="1">
                              <Box color={blackmedium}>
                                <Text fontWeight="bold" color={bodyTextColor}>
                                  {_?.booking_services[0]?.service_name}
                                </Text>
                                <Rating
                                  total_rating={_?.business_rating}
                                  reviewsCount={_?.business_review}
                                />
                              </Box>
                            </Box>
                          </Flex>
                        </Flex>
                        <Stack spacing={2} mb={4}>
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            flexWrap="wrap"
                            gap="5px"
                          >
                            <Flex gap="5px" alignItems="center">
                              <Text fontSize="sm" color={blackmedium}>
                                <Text fontWeight="600" as="span">
                                  {" "}
                                  ID :
                                </Text>{" "}
                                <Text
                                  as="span"
                                  fontWeight="400"
                                  color={blackmedium}
                                >
                                  {_?.order_no}
                                </Text>
                              </Text>
                            </Flex>
                            <Flex gap="5px" alignItems="center">
                              <Icon
                                as={FaCalendarAlt}
                                mr={2}
                                color={secondryText}
                              />
                              <Text color={blackmedium}>{_?.booking_date}</Text>
                            </Flex>
                          </Flex>
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            flexWrap="wrap"
                            gap="5px"
                          >
                            <Flex gap="5px" alignItems="center">
                              <Icon as={FaUser} mr={2} color={secondryText} />
                              <Text color={blackmedium}>
                                Manpower: {_?.booking_services[0].qty}
                              </Text>
                            </Flex>
                            <Flex gap="5px" alignItems="center">
                              <Icon
                                as={FaMapMarkerAlt}
                                mr={2}
                                color={secondryText}
                              />
                              <Text color={blackmedium}>
                                {formatAddress(_?.booking_address)}
                              </Text>
                            </Flex>
                          </Flex>
                        </Stack>
                        <Flex gap="10px" mt="10px">
                          {/* <Button colorScheme="blue" flex="1">
                            Chat Now
                          </Button> */}
                          <Button colorScheme="green" flex="1">
                            View
                          </Button>
                        </Flex>
                      </Box>
                    </Box>
                  ))}
                </SimpleGrid>
              </TabPanel>

              <TabPanel py="30px" px="0">
                <SimpleGrid
                  columns={{ base: 1, sm: 2, md: 3, xl: 4 }}
                  spacing={4}
                  className="bookingCardWr"
                >
                  {bookings?.reverse().map((_, index) => (
                    <Box onClick={onOpen} key={index} className="bookingCard">
                      <Box
                        border="1px"
                        className="bookingCardInner"
                        borderColor={acitveBorder}
                        borderRadius="md"
                        p={4}
                        bg={lightBlue}
                        _hover={{
                          boxShadow:
                            "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                        }}
                      >
                        <Flex mb={4} gap="10px" justifyContent="space-between">
                          <Flex>
                            <Image
                              boxSize="50px"
                              borderRadius="full"
                              src={_?.booking_services[0]?.service_image}
                              alt="Customer Image"
                              mr={4}
                            />
                            <Box flex="1">
                              <Box>
                                <Text fontWeight="bold" color={bodyTextColor}>
                                  {_?.booking_services[0]?.service_name}{" "}
                                </Text>
                                <Rating
                                  total_rating={_?.business_rating}
                                  reviewsCount={_?.business_review}
                                />
                              </Box>
                            </Box>
                          </Flex>
                        </Flex>
                        <Stack spacing={2} mb={4}>
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            flexWrap="wrap"
                            gap="5px"
                          >
                            <Flex gap="5px" alignItems="center">
                              <Text fontSize="sm">
                                <Text
                                  fontWeight="600"
                                  as="span"
                                  color={blackmedium}
                                >
                                  {" "}
                                  ID :
                                </Text>{" "}
                                <Text
                                  as="span"
                                  fontWeight="400"
                                  color={blackmedium}
                                >
                                  {_?.order_no}
                                </Text>
                              </Text>
                            </Flex>
                            <Flex gap="5px" alignItems="center">
                              <Icon
                                as={FaCalendarAlt}
                                mr={2}
                                color={secondryText}
                              />
                              <Text color={blackmedium}>09-06-2024</Text>
                            </Flex>
                          </Flex>
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            flexWrap="wrap"
                            gap="5px"
                          >
                            <Flex gap="5px" alignItems="center">
                              <Icon as={FaUser} mr={2} color={secondryText} />
                              <Text color={blackmedium}>
                                {" "}
                                Manpower: {_?.booking_services[0].qty}
                              </Text>
                            </Flex>
                            <Flex gap="5px" alignItems="center">
                              <Icon
                                as={FaMapMarkerAlt}
                                mr={2}
                                color={secondryText}
                              />
                              <Text color={blackmedium}>
                                {formatAddress(_?.booking_address)}
                              </Text>
                            </Flex>
                          </Flex>
                        </Stack>
                        <Flex justifyContent="space-between">
                          <Button colorScheme="green" flex="1">
                            View
                          </Button>
                        </Flex>
                      </Box>
                    </Box>
                  ))}
                </SimpleGrid>
              </TabPanel>
            </TabPanels>
          </Tabs>
          {isOpen && (
            <BookingDetails
              isOpen={isOpen}
              onClose={onClose}
              providerData={selectedProviderData}
            />
          )}
        </Box>
        {bookings.length === 0 && <NodataFound message={error} />}
      </Container>
    </Box>
  );
};

export default CustomerBookingHistory;
