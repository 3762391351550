import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Flex,
  Text,
  Image,
  Heading,
  Stack,
  Input,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  VStack,
  HStack,
  FormLabel,
  FormControl,
} from "@chakra-ui/react";
import { NavLink, useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import profile from "../assets/images/no-profile.png";
import { IoStar } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import { PiBank } from "react-icons/pi";
import { LuHistory } from "react-icons/lu";
import { FaAddressBook } from "react-icons/fa";
import { IoWalletOutline } from "react-icons/io5";
import { IoGiftOutline } from "react-icons/io5";
import { TbPasswordUser } from "react-icons/tb";
import { GiTakeMyMoney } from "react-icons/gi";
import BankDetailsModal from "./BankDetailsModal";
import { logoutAction } from "../redux/auth-redux/actions";
import { fetchProfileDetails } from "../redux/users-redux/actions";
import { customerProfileUpdate } from "../api-services/users";
import SpinnerComponent from "./Spinner";
import Rating from "./rating";
import WalletModal from "./Wallet";
import ManageAddress from "../model/ManageAddress";
import ProfileImage from "../components/ProviderProfileImage";
import ChangePasswordModal from "./ChangepasswordModal";
import silverBadge from "../assets/images/provider/silver.png";
import goldBadge from "../assets/images/provider/gold.png";
import platinumBadge from "../assets/images/provider/platinum.png";
import diamondBadge from "../assets/images/provider/diamond.png";
import ReferralModal from "./ReferalModal";

const Profile = ({ onClose }) => {
  const {
    secondryText,
    primaryText,
    footerColor,
    headingColor,
    rating,
    activeBg,
  } = useSelector((state) => state.theme);
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize navigate

  const { profileLoadingState, profileDetailsState } = useSelector(
    (state) => state?.users
  );
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false); // State to manage edit mode
  const [formError, setFormError] = useState("");

  const toggleEdit = () => setIsEditing(!isEditing); // Toggle edit mode
  const {
    isOpen: isProfileOpen,
    onOpen: onProfileOpen,
    onClose: onProfileClose,
  } = useDisclosure();
  const {
    isOpen: isChangePasswordOpen,
    onOpen: onChangePasswordOpen,
    onClose: onChangePasswordClose,
  } = useDisclosure();
  const {
    isOpen: isBankOpen,
    onOpen: onBankOpen,
    onClose: onBankClose,
  } = useDisclosure();
  // const { isOpen: isHistoryOpen, onOpen: onHistoryOpen, onClose: onHistoryClose } = useDisclosure();
  const {
    isOpen: isAddressOpen,
    onOpen: onAddressOpen,
    onClose: onAddressClose,
  } = useDisclosure();
  const {
    isOpen: isEmployeesOpen,
    onOpen: onEmployeesOpen,
    onClose: onEmployeesClose,
  } = useDisclosure();
  const {
    isOpen: isWalletOpen,
    onOpen: onWalletOpen,
    onClose: onWalletClose,
  } = useDisclosure();
  const {
    isOpen: isReferralOpen,
    onOpen: onReferralOpen,
    onClose: onReferralClose,
  } = useDisclosure();

  const handleLogout = () => {
    // localStorage.removeItem("token");
    dispatch(logoutAction());
    onClose();

    navigate("/");
  };
  const user_type = localStorage.getItem("user_type");

  const profileSettingPage = () => {
    if (user_type === "freelancer") {
      onClose();
      navigate("/freelancer-profile?mode=update");
    } else if (user_type === "company") {
      onClose();
      navigate("/company-profile?mode=update");
    } else if (user_type === "employee") {
      onClose();
      navigate("/employee-profile?mode=update");
    } else if (user_type === "user") {
      onProfileOpen();
    }
  };

  React.useEffect(() => {
    fetchData();
    return () => {
      // Cleanup code here if required
    };
  }, [dispatch]);
  const fetchData = async () => {
    setLoading(true);
    await dispatch(fetchProfileDetails());
    setLoading(false);
  };
  const handleClose = () => {
    setFormError(""); // Clear the error message
    onProfileClose(); // Close the modal
  };
  const userType = localStorage.getItem("user_type");

  const handleLinkClick = (path) => {
    onClose(); // Close the drawer
    navigate(path); // Navigate to the desired path
  };

  const [profileImageFile, setProfileImageFile] = useState(null);
  const handleImageChange = (newImage) => {
    setProfileImageFile(newImage);
  };

  const badgeMap = {
    0: silverBadge,
    1: goldBadge,
    2: platinumBadge,
    3: diamondBadge,
  };

  const badgeSrc = badgeMap[profileDetailsState?.business_profile?.badge ?? 0];

  return (
    <>
      {" "}
      {loading ? (
        <SpinnerComponent />
      ) : (
        <Box padding="0">
          <Flex
            direction="column"
            align="center"
            mb={4}
            bg="#2172b9"
            p="20px"
            color="#fff"
            overflow="hidden"
          >
            {userType == "freelancer" ? (
              <>
                <Box position="relative">
                  <Image
                    borderRadius="full"
                    boxSize="100"
                    src={
                      (profileDetailsState?.business_images?.length > 0 &&
                        profileDetailsState?.business_images?.[0]
                          .business_image) ||
                      profile
                    }
                    alt="Profile Picture"
                    objectFit="cover"
                    mb={4}
                  />
                  <Image
                    position="absolute"
                    // right="65px"
                    // top="55px"
                    right="0"
                    bottom="0"
                    maxW="45px"
                    mx="auto"
                    src={badgeSrc}
                    alt="badge"
                  ></Image>
                </Box>
              </>
            ) : userType == "user" ? (
              <Image
                borderRadius="full"
                boxSize="100"
                src={profileDetailsState?.image ?? profile}
                alt="Profile Picture"
                mb={4}
              />
            ) : (
              <Image
                borderRadius="full"
                boxSize="100"
                src={
                  profileDetailsState?.business_images?.[0]?.business_image ??
                  profile
                }
                alt="Profile Picture"
                mb={4}
              />
            )}
            <Heading
              as="h6"
              fontSize="18px"
              color="#fff"
              textTransform="uppercase"
              fontWeight="700"
              textAlign="center"
            >
              {profileDetailsState?.business_profile?.business_name}
            </Heading>
            <Text fontWeight="500" fontSize="14px" color="#fff">
              {profileDetailsState?.email}
            </Text>
            <Flex alignItems="center">
              <Text color="#fbe446" fontSize="16px" fontWeight="700">
                {profileDetailsState?.total_rating}
              </Text>
              <Flex>
                <Rating
                  total_rating={secondryText?.total_rating}
                  reviewsCount={profileDetailsState?.total_review}
                  Reviews
                />
              </Flex>
            </Flex>
            {userType === "freelancer" && (
              <Text fontWeight="500" fontSize="14px" color="#fff">
                Standard
              </Text>
            )}
            {userType === "company" && (
              <Text fontWeight="500" fontSize="14px" color="#fff">
                Premium
              </Text>
            )}
            {userType === "employee" && (
              <Text fontWeight="500" fontSize="14px" color="#fff">
                Employee
              </Text>
            )}
            {/* 
            <Text color={secondryText} mb={4}>
              {profileDetailsState?.total_review} Reviews
            </Text> */}
          </Flex>
          <Box className="profileItems_wr" mt="30px" px="20px">
            <Heading as="h6" fontSize="22px" mb={3} color={headingColor}>
              Account Settings
            </Heading>
            <Stack spacing={3}>
              <Button
                colorScheme="teal"
                variant="outline"
                gap="10px"
                justifyContent="start"
                onClick={profileSettingPage}
              >
                <FaUser /> Profile Setting
              </Button>

              <Button
                colorScheme="teal"
                variant="outline"
                gap="10px"
                justifyContent="start"
                onClick={onChangePasswordOpen}
              >
                <TbPasswordUser /> Change Password
              </Button>
              {user_type === "user" && (
                <Button
                  colorScheme="teal"
                  variant="outline"
                  gap="10px"
                  justifyContent="start"
                  onClick={onReferralOpen}
                >
                  <IoGiftOutline /> Refer and Earn
                </Button>
              )}
              {user_type !== "employee" && (
                <Button
                  colorScheme="teal"
                  variant="outline"
                  gap="10px"
                  justifyContent="start"
                  onClick={onWalletOpen}
                >
                  <IoWalletOutline /> Wallet
                </Button>
              )}

              {user_type !== "user" && user_type !== "employee" && (
                <Button
                  colorScheme="teal"
                  variant="outline"
                  gap="10px"
                  justifyContent="start"
                  as={NavLink}
                  to="/earnings"
                  onClick={() => {
                    onClose(); // Close drawer
                    handleLinkClick("/earnings");
                  }}
                >
                  <GiTakeMyMoney /> Earnings
                </Button>
              )}

              {user_type !== "user" && (
                <Button
                  colorScheme="teal"
                  variant="outline"
                  gap="10px"
                  justifyContent="start"
                  onClick={onBankOpen}
                >
                  <PiBank /> Bank Details
                </Button>
              )}

              <Button
                colorScheme="teal"
                variant="outline"
                gap="10px"
                justifyContent="start"
                onClick={() => {
                  onClose(); // Close drawer
                  handleLinkClick("/chat");
                }}
              >
                <PiBank /> Chat
              </Button>

              <Button
                colorScheme="teal"
                variant="outline"
                gap="10px"
                justifyContent="start"
                as={NavLink}
                to={
                  user_type === "user"
                    ? "/customer-booking-history"
                    : "/booking-history"
                }
                onClick={() => {
                  onClose(); // Close drawer
                  handleLinkClick(
                    user_type === "user"
                      ? "/customer-booking-history"
                      : "/booking-history"
                  );
                }}
              >
                <LuHistory /> Booking History
              </Button>
              {user_type === "user" && (
                <Button
                  colorScheme="teal"
                  variant="outline"
                  gap="10px"
                  justifyContent="start"
                  onClick={onAddressOpen}
                >
                  <FaAddressBook /> Manage Address
                </Button>
              )}

              {user_type === "company" && (
                <Button
                  colorScheme="teal"
                  variant="outline"
                  gap="10px"
                  justifyContent="start"
                  to="/company-employees"
                  // onClick={() => navigate("/company-employees")}
                  onClick={() => {
                    onClose(); // Close drawer
                    handleLinkClick("/company-employees");
                  }}
                >
                  <FaAddressBook /> Manage Employees
                </Button>
              )}
            </Stack>
            <Button
              className="logoutBtn"
              w="100%"
              bg={activeBg}
              color="#fff"
              mt="40px"
              onClick={() => handleLogout()}
            >
              Logout
            </Button>
          </Box>

          {/* Profile Setting Modal */}
          {isProfileOpen && (
            <Modal
              blockScrollOnMount={false}
              isOpen={isProfileOpen}
              onClose={handleClose}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Profile Setting</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Flex direction="column" align="center" mb={4}>
                    <ProfileImage
                      profileDetailsState={profileDetailsState}
                      onImageChange={handleImageChange}
                      customMargin={0}
                    />
                    <Heading
                      as="h4"
                      size="lg"
                      mb={2}
                      color={headingColor}
                      textTransform="uppercase"
                    >
                      {""}
                      {/* s{profileDetailsState?.name} */}
                    </Heading>
                  </Flex>

                  <Formik
                    initialValues={{
                      name: profileDetailsState?.name || "",
                      phone_number: profileDetailsState?.phone_number || "",
                      email: profileDetailsState?.email || "",
                      // location: profileDetailsState?.location || "",
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      try {
                        const formData = new FormData();
                        if (profileImageFile !== undefined) {
                          // values?.image?.push(profileImageFile);
                          formData.append("image", profileImageFile);
                        }
                        // if (
                        //   values?.phone_number !==
                        //   profileDetailsState?.phone_number
                        // ) {
                        //   formData.append("phone_number", values?.phone_number);
                        // }
                        formData.append("phone_number", values?.phone_number);
                        formData.append("name", values?.name);
                        // formData.append("email", values?.email);

                        //
                        // Simulate an API call to update profile
                        const response = await customerProfileUpdate(formData);
                        console.log("response", response);
                        if (!response.success) {
                          setFormError(response?.response?.data?.message);
                        } else {
                          fetchData();
                          setFormError(); // Clear error message if successful
                          onProfileClose(); // Close the modal
                        }
                      } catch (error) {
                        console.log("response", error);

                        setFormError("An unexpected error occurred.");
                      } finally {
                        setSubmitting(false);
                        setIsEditing(false);
                      }
                    }}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <Stack spacing={4} mt="60px" pb="30px">
                          <FormControl>
                            <FormLabel>Name</FormLabel>
                            <Field
                              as={Input}
                              type="text"
                              name="name"
                              isReadOnly={!isEditing}
                            />
                          </FormControl>

                          <FormControl>
                            <FormLabel>Phone Number</FormLabel>
                            <Field
                              as={Input}
                              type="tel"
                              name="phone_number"
                              isReadOnly
                            />
                          </FormControl>

                          <FormControl>
                            <FormLabel>Email</FormLabel>
                            <Field
                              as={Input}
                              type="email"
                              name="email"
                              isReadOnly
                            />
                          </FormControl>

                          {/* <FormControl>
                          <FormLabel>Location</FormLabel>
                          <Field
                            as={Input}
                            type="text"
                            name="location"
                            isReadOnly={!isEditing}
                          />
                        </FormControl> */}
                        </Stack>

                        {formError && (
                          <Text color="red.500" mt={4}>
                            {formError}
                          </Text>
                        )}

                        <ModalFooter gap="10px">
                          <Button
                            bg="green"
                            type="submit"
                            isLoading={isSubmitting}
                            color="#ffffff"
                          >
                            Save
                          </Button>
                          {isEditing ? (
                            <Button
                              bg="red"
                              onClick={toggleEdit}
                              color="#ffffff"
                            >
                              Cancel
                            </Button>
                          ) : (
                            <Button
                              bg="red"
                              onClick={toggleEdit}
                              color="#ffffff"
                            >
                              Edit
                            </Button>
                          )}
                        </ModalFooter>
                      </Form>
                    )}
                  </Formik>
                </ModalBody>
              </ModalContent>
            </Modal>
          )}

          {/* Change Password */}
          <ChangePasswordModal
            isOpen={isChangePasswordOpen}
            onClose={onChangePasswordClose}
          ></ChangePasswordModal>

          {/* Wallet */}
          <WalletModal
            isOpen={isWalletOpen}
            onClose={onWalletClose}
          ></WalletModal>

          {/* ReferralModal details */}
          <ReferralModal
            isOpen={isReferralOpen}
            onClose={onReferralClose}
            referralCode={profileDetailsState?.referal_code}
          ></ReferralModal>

          {/* Bank Details Modal */}
          <BankDetailsModal isOpen={isBankOpen} onClose={onBankClose} />

          {/* Manage Address Modal */}
          <ManageAddress isOpen={isAddressOpen} onClose={onAddressClose} />

          <Modal
            blockScrollOnMount={false}
            isOpen={isEmployeesOpen}
            onClose={onEmployeesClose}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Manage Employees</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <VStack align="start" spacing={4}></VStack>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      )}
    </>
  );
};

export default Profile;
