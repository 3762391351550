import {
  // GET_ABOUT_FAILURE,
  // GET_ABOUT_REQUEST,
  // GET_ABOUT_SUCCESS,
  GET_FAQ_FAILURE,
  GET_FAQ_REQUEST,
  GET_FAQ_SUCCESS,
  SUBMIT_BANK_DETAILS_SUCCESS,
  SUBMIT_BANK_DETAILS_FAILURE,
  FETCH_BANK_DETAILS_SUCCESS,
  FETCH_BANK_DETAILS_FAILURE,
  SUBMIT_BANK_DETAILS_REQUEST,
  SUBMIT_PROFILE_DETAILS_REQUEST,
  FETCH_PROFILE_DETAILS_SUCCESS,
  FETCH_PROFILE_DETAILS_FAILURE,
} from "./actionTypes";
import {
  aboutUsApi,
  contactApi,
  faqApi,
  submitBankDetailsApi,
  fetchBankDetailsApi,
  fetchProfileDetailsAPI,
  fetchFreelancerDetailsApi,
  getCompanyBookings,
} from "../../api-services/users";

export const aboutUsAction = (userData) => async (dispatch) => {
  try {
    const data = await aboutUsApi(userData);
    // dispatch(signupSuccess(data));
  } catch (error) {
    // dispatch(signupFailure(error.message));
  }
};
export const faqAction = () => async (dispatch) => {
  try {
    const response = await faqApi();
    dispatch({
      type: GET_FAQ_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_FAQ_FAILURE,
    });
  }
};

export const contactAction = (payload) => async (dispatch) => {
  try {
    console.log("response=======payload", payload);

    const response = await contactApi(payload);
    console.log("response=======1111111111", response);
    dispatch({
      type: GET_FAQ_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_FAQ_FAILURE,
    });
  }
};

export const submitBankDetails = (payload) => async (dispatch) => {
  try {
    console.log("respon submit Bank details payload", payload);

    const response = await submitBankDetailsApi(payload);
    console.log("response Submit Bank details", response);
    dispatch({
      type: SUBMIT_BANK_DETAILS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: SUBMIT_BANK_DETAILS_FAILURE,
    });
  }
};

export const fetchBankDetails = () => async (dispatch) => {
  try {
    dispatch({
      type: SUBMIT_BANK_DETAILS_REQUEST,
      payload: null,
    });
    const response = await fetchBankDetailsApi(); // Your API call here
    dispatch({
      type: FETCH_BANK_DETAILS_SUCCESS,
      payload: response?.response, // Adjust according to your API response structure
    });
  } catch (error) {
    dispatch({
      type: FETCH_BANK_DETAILS_FAILURE,
      payload: error.message,
    });
  }
};

export const fetchProfileDetails = () => async (dispatch) => {
  try {
    dispatch({
      type: SUBMIT_PROFILE_DETAILS_REQUEST,
      payload: null,
    });
    const response = await fetchProfileDetailsAPI(); // Your API call here
    dispatch({
      type: FETCH_PROFILE_DETAILS_SUCCESS,
      payload: response?.response, // Adjust according to your API response structure
    });
  } catch (error) {
    dispatch({
      type: FETCH_PROFILE_DETAILS_FAILURE,
      payload: error.message,
    });
  }
};

export const setProfileDetails = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_PROFILE_DETAILS_SUCCESS,
      payload: payload, // Adjust according to your API response structure
    });
  } catch (error) {
    dispatch({
      type: FETCH_PROFILE_DETAILS_FAILURE,
      payload: error.message,
    });
  }
};

// export const getCompanyBookingsActions = () => async (dispatch) => {
//   try {
//     dispatch({
//       type: SUBMIT_FREELANCER_DETAILS_REQUEST,
//       payload: null,
//     });
//     const response = await getCompanyBookings(); // Your API call here
//     dispatch({
//       type: FETCH_FREELANCER_DETAILS_SUCCESS,
//       payload: response?.response, // Adjust according to your API response structure
//     });
//   } catch (error) {
//     dispatch({
//       type: FETCH_FREELANCER_DETAILS_FAILURE,
//       payload: error.message,
//     });
//   }
// };
