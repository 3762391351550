import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Flex,
  Text,
  Image,
  Heading,
  Container,
  SimpleGrid,
  Stack,
  InputGroup,
  FormControl,
  Input,
  Button,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../trider-logo.png";
import { FaArrowLeft } from "react-icons/fa6";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { verifyOtpAction } from "../redux/auth-redux/actions";

const OtpVarify = () => {
  const { secondryText, primaryText, footerColor, bodyTextColor } = useSelector(
    (state) => state.theme
  );
  const { forgotPassData } = useSelector((state) => state.auth);

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const boxStyle = {
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    maxWidth: "550px",
    padding: "30px",
    margin: "0 auto",
    borderRadius: "10px",
  };
  const backBtn = {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validationSchema = Yup.object({
    otp1: Yup.string().required("Required"),
    otp2: Yup.string().required("Required"),
    otp3: Yup.string().required("Required"),
    otp4: Yup.string().required("Required"),
  });

  const initialValues = {
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
  };

  const onSubmit = async (values) => {
    setErrorMessage("");
    setIsLoading(true);
    let obj = {
      user_id: forgotPassData?.id,
      otp: `${values.otp1 + values.otp2 + values.otp3 + values.otp4}`,
    };
    await dispatch(verifyOtpAction(obj)).then((res) => {
      if (res?.sucsess) {
        setIsLoading(false);
        navigate("/reset-password");
      } else {
        setIsLoading(false);
        setErrorMessage(res?.message);
      }
    });
  };
  return (
    <Box className="forget-password" as="footer" my="40px">
      <Container maxW="container.xl" p={4} color={footerColor}>
        <Box style={{ ...boxStyle }}>
          <Link as={NavLink} to="/forget-password" style={{ ...backBtn }}>
            <FaArrowLeft /> Back
          </Link>
          <Box>
          <Image src={logo} margin="0 auto" w="100px" mb="30px" />
          </Box>
          <Box mb="30px" textAlign="center">
            <Heading as="h6" fontSize="28px" color={bodyTextColor}>
              Verify OTP
            </Heading>
            <Text color={bodyTextColor}>
              Enter the OTP sent to the e-mail id {forgotPassData?.email}
            </Text>
          </Box>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Stack spacing={4}>
                  <FormControl display="flex" gap="10px">
                    <Field
                      name="otp1"
                      type="text"
                      as={Input}
                      placeholder="1"
                      color="#000000"
                      borderColor="#000000"
                      textAlign="center"
                      maxLength={1}
                    />
                    <Field
                      name="otp2"
                      type="text"
                      as={Input}
                      placeholder="1"
                      color="#000000"
                      borderColor="#000000"
                      textAlign="center"
                      maxLength={1}
                    />
                    <Field
                      name="otp3"
                      type="text"
                      as={Input}
                      placeholder="1"
                      color="#000000"
                      borderColor="#000000"
                      textAlign="center"
                      maxLength={1}
                    />
                    <Field
                      name="otp4"
                      type="text"
                      as={Input}
                      placeholder="1"
                      color="#000000"
                      borderColor="#000000"
                      textAlign="center"
                      maxLength={1}
                    />
                  </FormControl>
                  <ErrorMessage
                    name="otp1"
                    component="div"
                    style={{ color: "red" }}
                  />
                  <ErrorMessage
                    name="otp2"
                    component="div"
                    style={{ color: "red" }}
                  />
                  <ErrorMessage
                    name="otp3"
                    component="div"
                    style={{ color: "red" }}
                  />
                  <ErrorMessage
                    name="otp4"
                    component="div"
                    style={{ color: "red" }}
                  />
                  {errorMessage && (
                    <Text textAlign="center" style={{ color: "red" }}>
                      <p>{errorMessage}</p>
                    </Text>
                  )}
                  <Button
                    type="submit"
                    className="btnGreen"
                    isLoading={isLoading}
                    _hover={{
                      bg:"#eb7b00"
                    }}
                  >
                    Verify OTP
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </Box>
  );
};

export default OtpVarify;
