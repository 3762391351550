import React from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Button,
} from "@chakra-ui/react";

const PopoverConfirm = ({ onConfirm, message, children }) => {
  return (
    <Popover>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        {/* <PopoverHeader>Confirm Action</PopoverHeader> */}
        <PopoverBody>{message}</PopoverBody>
        <PopoverFooter display="flex" justifyContent="flex-end">
          <Button colorScheme="red" onClick={onConfirm}>
            Yes, Confirm
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

export default PopoverConfirm;
