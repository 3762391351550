import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Flex,
  Text,
  Image,
  Heading,
  Button,
  Container,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Icon,
  Stack,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  VStack,
  HStack,
  Checkbox,
  Input,
  PinInput,
  PinInputField,
  FormControl,
  FormErrorMessage,
  useToast,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import { IoStar } from "react-icons/io5";
import { FaUser, FaArrowLeft } from "react-icons/fa";
import { FaCalendarAlt, FaMapMarkerAlt } from "react-icons/fa";
import { BsStopwatch } from "react-icons/bs";
import { MdCurrencyRupee } from "react-icons/md";
import { FaMapLocationDot } from "react-icons/fa6";
import {
  getCompanyBookings,
  getCompanyBookingDetails,
  getBookingCompanyEmployeeLists,
  postAcceptAndReject,
  getCompanyEmployeeList,
  startJob,
  endJob,
} from "../../../api-services/users";
import {
  getChatList,
  getMessages,
  sendMessage,
} from "../../../api-services/index";

import SpinnerComponent from "../../../components/Spinner";
import Webcam from "react-webcam";
import { CloseIcon } from "@chakra-ui/icons";
// Provider Details
import provider from "../../../assets/images/provider/provider-img.svg";
import { useNavigate } from "react-router-dom";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import NodataFound from "../../../components/NoDataFound";
import moment from "moment";

const validationSchema = Yup.object({
  reason: Yup.string().required("Reason is required"),
});
const formatAddress = (address) => {
  const { address_line_one, address_line_two, city, state, pincode } = address;
  return `${address_line_one}${
    address_line_two ? `, ${address_line_two}` : ""
  }, ${city}, ${state} - ${pincode}`;
};

const BookingHistory = () => {
  const {
    secondryText,
    footerColor,
    activeBg,
    redDark,
    darkYellow,
    acitveColor,
    acitveBorder,
    lightBlue,
    bodyTextColor,
    blackmedium,
  } = useSelector((state) => state.theme);
  const toast = useToast();
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isSecondModalOpen,
    onOpen: onSecondModalOpen,
    onClose: onSecondModalClose,
  } = useDisclosure();
  const {
    isOpen: isThirdModalOpen,
    onOpen: onThirdModalOpen,
    onClose: onThirdModalClose,
  } = useDisclosure();

  const [checkedClients, setCheckedClients] = useState([]);
  const [currentModal, setCurrentModal] = useState("initial");

  const handleCheckboxChange = (id) => {
    setCheckedClients((prev) =>
      prev.includes(id)
        ? prev.filter((clientId) => clientId !== id)
        : [...prev, id]
    );
  };

  const handleAcceptClick = () => {
    onClose(); // Close the first modal
    setCurrentModal("second"); // Set the state to open the second modal
    onSecondModalOpen(); // Open the second modal
  };

  const handleBackClick = () => {
    if (currentModal === "second") {
      onSecondModalClose();
      onOpen(); // Go back to the first modal
      setCurrentModal("initial");
    } else if (currentModal === "third") {
      onThirdModalClose();
      onSecondModalOpen(); // Go back to the second modal
      setCurrentModal("second");
    }
  };

  const handleStartNowClick = () => {
    onSecondModalClose(); // Close the second modal
    onThirdModalOpen(); // Open the third modal
    setCurrentModal("third");
  };

  const [bookings, setBookings] = useState([]);
  const [bookingBetails, setBookingDetails] = useState(null);

  const [bookingType, setBookingType] = useState("new");
  const [employeeList, setEmployeeList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [errorSelectEmployee, setErrorSelectEmployee] = useState(null);

  const [detailError, setDetailsError] = useState(null);
  const [bookingStatusMsg, setBookingStatusMsg] = useState("");
  const [isReasonOpen, setIsReasonOpen] = useState(false);
  const [bookingStatusPayload, setbookingStatusPayload] = useState(false);
  const [otp, setOtp] = useState("");
  const webcamRef = useRef(null);
  const [photo, setPhoto] = useState(null);
  const [startJobId, setStartJobId] = useState(null);
  const [endJobId, setEndJobId] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorJob, setErrorJob] = useState(""); // State for error messages
  const [success, setSuccess] = useState(""); // State for success messages

  // const [userType, setUserType] = useState("");
  const userType = localStorage.getItem("user_type");
  useEffect(() => {
    fetchBookings();
  }, [bookingType]);

  const fetchBookings = async () => {
    try {
      setLoading(true);
      setError(null);
      let isBookingType = bookingType;
      if (userType === "employee") {
        // isBookingType = "in_progress";
      }
      const data = await getCompanyBookings(isBookingType);
      if (data?.success === false) {
        setError(data?.message);
        setBookings([]);
      } else {
        setBookings(data?.response);
      }
      setLoading(false);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  const onDetails = async (id, status) => {
    try {
      setDetailsError(null);
      const data = await getCompanyBookingDetails(id);
      // console.log("data=========", data);
      if (data?.success === false) {
        setDetailsError(data?.message);
      } else {
        getEmployeeList();
        setBookingDetails(data?.response);
        status === "in_progress" ? onThirdModalOpen() : onOpen();
        // onThirdModalOpen()
        // onOpen();
      }
    } catch (err) {
      setDetailsError(err.message);
    } finally {
      setLoading(false);
    }
  };
  const getEmployeeList = async () => {
    try {
      const data = await getBookingCompanyEmployeeLists();
      console.log("data?.response", data?.response);

      if (data?.success === false) {
        // setDetailsError(data?.message);
      } else {
        setEmployeeList(data?.response);
        onOpen();
      }
    } catch (err) {
      // setDetailsError(err.message);
    } finally {
      setLoading(false);
    }
  };

  //handleAcceptClick
  const onAcceptAndReject = async (type, id) => {
    try {
      setErrorSelectEmployee(null);
      if (userType === "company" && type === "accept" && checkedClients.length === 0) {
        setErrorSelectEmployee("Please select employee!");
        return;
      }
      setIsSubmitting(true);
      const payload = {
        booking_id: id,
        booking_status: type === "accept" ? "in_progress" : "rejected",
        employee_id: checkedClients,
        // reason: type,
      };
      if (type === "rejected") {
        setbookingStatusPayload(payload);
        setIsReasonOpen(true);
        return;
      }

      const data = await postAcceptAndReject(payload);
      // setBookingStatusMsg(data?.message);
      if (data?.success) {
        toast({
          title: "Success",
          description: data?.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        fetchBookings();
      } else {
        toast({
          title: "Error",
          description: data?.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }

      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (err) {
      // setBookingStatusMsg(err.message);
      toast({
        title: "Error",
        description: err?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleSubmitReject = async (values, actions) => {
    try {
      setIsSubmitting(true);
      const newPayload = bookingStatusPayload;
      newPayload.reason = values?.reason;
      const data = await postAcceptAndReject(newPayload);
      // setBookingStatusMsg(data?.message);
      // actions.setSubmitting(false);
      toast({
        title: "Success",
        description: data?.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setTimeout(() => {
        onClose();
        setIsReasonOpen(false); // Close the modal
      }, 2000);
    } catch (err) {
      // setBookingStatusMsg(err.message);
      toast({
        title: "Error",
        description: err?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };
  const getFormattedAddress = (booking_address) => {
    if (!booking_address) return "";

    const { address_line_one, address_line_two, city, state, pincode } =
      booking_address;

    // Concatenate the address parts, filtering out any that are null or undefined
    const addressParts = [
      address_line_one,
      address_line_two,
      city,
      state,
      pincode,
    ].filter(Boolean);

    // Join the parts with a comma and space
    return addressParts.join(", ");
  };

  const onStartJob = async (id) => {
    setIsSubmitting(false);
    setStartJobId(id);
    setEndJobId(null);
    setErrorJob("");
    setSuccess("");
    setOtp("");
    setPhoto(null);
    onThirdModalOpen();
  };
  ////////

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setPhoto(imageSrc);
  }, [webcamRef, setPhoto]);

  const handleOtpChange = (value) => {
    setOtp(value);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setErrorJob(""); // Reset error message
    setSuccess(""); // Reset success message

    try {
      if (otp.length === 4 && photo) {
        const blob = await fetch(photo).then((res) => res.blob());
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append("booking_id", startJobId);
        formData.append("image", blob, "captured-image.jpg"); // Add the image Blob
        formData.append("otp", otp);
        const result = await startJob(formData);
        if (result.success) {
          setSuccess("Booking started successfully!");
          await fetchBookings();
          onThirdModalClose();
        } else {
          throw new Error(result.message || "Failed to submit the form.");
        }
      } else {
        setErrorJob("Please fill in the OTP and upload a picture.");
      }
    } catch (err) {
      setErrorJob(err.message || "An unexpected error occurred.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const onEndJob = async (id) => {
    setIsSubmitting(false);
    setStartJobId(null);
    setEndJobId(id);
    setErrorJob("");
    setSuccess("");
    setOtp("");
    setPhoto(null);
    onThirdModalOpen();
  };
  const handleEndJob = async () => {
    try {
      setIsSubmitting(true);
      if (otp.length === 4) {
        const result = await endJob({ otp, booking_id: endJobId });
        if (result.success) {
          setSuccess("Booking end successfully!");
          await fetchBookings();
          onThirdModalClose();
        } else {
          throw new Error(result.message || "Failed to submit the form.");
        }
      } else {
        setErrorJob("Please fill in the OTP.");
      }
    } catch (err) {
      setErrorJob(err.message || "An unexpected error occurred.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const onChat = (id, user) => {
    const newMessage = {
      booking_id: id,
      receiver_id: user?.user_id,
      message: `Hello , ${user?.user_name}`,
      chat_id: user?.chatID,
    };
    const chatMessage = async (newMessage) => {
      try {
        setLoading(true);
        const data = await sendMessage(newMessage); // Fetch about content
        console.log("initail message chat", data);
        navigate(`/chat?bookingId=${id}`);
      } catch (error) {
        setError("Failed to load content"); // Set error if the request fails
      } finally {
        setLoading(false); // Disable loading once data is fetched
      }
    };
    chatMessage(newMessage);
  };
  return (
    <Box p={4} className="bookingHistoryPage">
      <Container maxW="container.xl" p={4} color={footerColor}>
        <Box>
          <Tabs>
            <Box className="" border>
              <TabList
                className="tabsList"
                mx="auto"
                borderRadius="6px"
                border="0"
                gap="8px"
                p="8px"
                justifyContent="center"
                maxW="384px"
                bg={activeBg}
              >
                {/* {userType !== "employee" && ( */}
                <Tab border="0" onClick={() => setBookingType("new")}>
                  New Booking
                </Tab>
                {/* )} */}

                <Tab border="0" onClick={() => setBookingType("in_progress")}>
                  On Going
                </Tab>
                <Tab border="0" onClick={() => setBookingType("completed")}>
                  Completed
                </Tab>
              </TabList>
            </Box>
            <TabPanels>
              {/* {userType !== "employee" && ( */}
              <TabPanel py="30px" px="0">
                <SimpleGrid
                  columns={{ base: 1, sm: 2, md: 3, xl: 4 }}
                  spacing={4}
                  className="bookingCardWr"
                >
                  {loading ? (
                    <SpinnerComponent />
                  ) : (
                    <>
                      {bookings?.map((_, index) => {
                        return _?.booking_status === "new" ? (
                          <Box
                            onClick={() => onDetails(_?.id, _?.booking_status)}
                            key={index}
                            className="bookingCard"
                            pos="relative"
                          >
                            <Tag
                              size="md"
                              colorScheme="red"
                              borderRadius="full"
                              pos="absolute"
                              right="43%"
                              top="-11px"
                              background="#c7ef72"
                              color="#010101"
                              fontWeight="600"
                              letterSpacing="0.3px"
                            >
                              <TagLabel fontSize="11px"> NEW </TagLabel>
                            </Tag>
                            <Box
                              border="1px"
                              borderRadius="md"
                              p={4}
                              className="bookingCardInner"
                              borderColor={acitveBorder}
                              bg={lightBlue}
                              _hover={{
                                boxShadow:
                                  "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                              }}
                            >
                              <Flex
                                mb={6}
                                gap="10px"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Flex alignItems="center">
                                  <Image
                                    boxSize="50px"
                                    borderRadius="full"
                                    src={_?.booking_services[0]?.service_image} //{customer}
                                    alt="Customer Image"
                                    mr={4}
                                  />
                                  <Box flex="2">
                                    <Box>
                                      <Text
                                        fontWeight="bold"
                                        color={bodyTextColor}
                                      >
                                        {_?.booking_services[0]?.service_name}
                                      </Text>
                                    </Box>
                                    {/* <Rating
                                          total_rating={_?.business_rating}
                                          reviewsCount={_?.business_review}
                                        /> */}
                                  </Box>
                                </Flex>
                                <Flex
                                  className=""
                                  lineHeight="15px"
                                  fontWeight="700"
                                  color={redDark}
                                  mt="5px"
                                >
                                  <MdCurrencyRupee color="red" />
                                  <Text color="red">{_?.grand_amount}</Text>
                                </Flex>
                              </Flex>
                              <Stack spacing={2} mb={4}>
                                <Flex
                                  alignItems="center"
                                  justifyContent="space-between"
                                  flexWrap="wrap"
                                  gap="5px"
                                >
                                  <Flex gap="5px" alignItems="center">
                                    <Text fontSize="sm" color={blackmedium}>
                                      <Text fontWeight="600" as="span">
                                        {" "}
                                        ID :
                                      </Text>{" "}
                                      <Text
                                        as="span"
                                        fontWeight="400"
                                        color={blackmedium}
                                      >
                                        {_?.order_no}
                                      </Text>
                                    </Text>
                                  </Flex>
                                  <Flex gap="5px" alignItems="center">
                                    <BsStopwatch color={secondryText} />
                                    <Text fontSize="sm" color={bodyTextColor}>
                                      {_?.booking_services[0]?.days > 0 ? (
                                        <>
                                          {_?.booking_services[0]?.days} days{" "}
                                        </>
                                      ) : (
                                        <> {_?.booking_services[0]?.hours} hr</>
                                      )}
                                    </Text>
                                  </Flex>
                                  <Flex gap="5px" alignItems="center">
                                    <Icon
                                      as={FaCalendarAlt}
                                      mr={2}
                                      color={secondryText}
                                    />
                                    <Text color={bodyTextColor}>
                                      {_?.booking_date}{" "}
                                      {moment(
                                        _?.booking_time,
                                        "HH:mm:ss"
                                      ).format("h:mm A")}
                                    </Text>
                                  </Flex>
                                </Flex>
                                <Flex
                                  alignItems="center"
                                  justifyContent="space-between"
                                  flexWrap="wrap"
                                  gap="5px"
                                >
                                  <Flex gap="5px" alignItems="center">
                                    <Icon
                                      as={FaUser}
                                      mr={2}
                                      color={secondryText}
                                    />
                                    <Text color={bodyTextColor}>
                                      Manpower: {_?.booking_services[0]?.qty}
                                    </Text>
                                  </Flex>
                                  <Flex gap="5px" alignItems="center">
                                    <Icon
                                      as={FaMapMarkerAlt}
                                      mr={2}
                                      color={secondryText}
                                    />
                                    <Text color={bodyTextColor}>
                                      {getFormattedAddress(_?.booking_address)}
                                    </Text>
                                  </Flex>
                                </Flex>
                              </Stack>
                              {_?.booking_status === "new" && (
                                <Flex justifyContent="space-between">
                                  <Button colorScheme="green">Accept</Button>
                                  <Button colorScheme="red">Decline</Button>
                                </Flex>
                              )}
                            </Box>
                          </Box>
                        ) : (
                          <Box
                            key={index}
                            className="bookingCard"
                            pos="relative"
                          >
                            <Tag
                              size="md"
                              colorScheme="red"
                              borderRadius="full"
                              pos="absolute"
                              right="35%"
                              top="-11px"
                              background="#c7ef72"
                              color="#010101"
                              fontWeight="600"
                              letterSpacing="0.3px"
                            >
                              <TagLabel fontSize="11px"> UPCOMING </TagLabel>
                            </Tag>
                            <Box
                              border="1px"
                              borderRadius="md"
                              p={4}
                              className="bookingCardInner"
                              borderColor={acitveBorder}
                              bg={lightBlue}
                              _hover={{
                                boxShadow:
                                  "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                              }}
                            >
                              <Flex
                                mb={4}
                                gap="10px"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Flex alignItems="center">
                                  <Image
                                    boxSize="50px"
                                    borderRadius="full"
                                    src={_?.booking_services[0]?.service_image} //{customer}
                                    alt="Customer Image"
                                    mr={4}
                                  />
                                  <Box flex="1">
                                    <Box>
                                      <Text
                                        fontWeight="bold"
                                        color={bodyTextColor}
                                      >
                                        {_?.booking_services[0]?.service_name}
                                      </Text>
                                      {/* <Rating
                                          total_rating={_?.business_rating}
                                          reviewsCount={_?.business_review}
                                        /> */}
                                    </Box>
                                  </Box>
                                </Flex>
                                <Flex
                                  className=""
                                  lineHeight="15px"
                                  fontWeight="700"
                                  color={redDark}
                                  mt="5px"
                                >
                                  <MdCurrencyRupee color="red" />
                                  <Text color="red">{_?.grand_amount}</Text>
                                </Flex>
                              </Flex>
                              <Stack spacing={2} mb={4}>
                                <Flex gap="5px" alignItems="center">
                                  <Text fontSize="sm" color={blackmedium}>
                                    <Text fontWeight="600" as="span">
                                      {" "}
                                      ID :
                                    </Text>{" "}
                                    <Text
                                      as="span"
                                      fontWeight="400"
                                      color={blackmedium}
                                    >
                                      {_?.order_no}
                                    </Text>
                                  </Text>
                                </Flex>
                                <Flex
                                  alignItems="center"
                                  justifyContent="space-between"
                                  flexWrap="wrap"
                                  gap="5px"
                                >
                                  <Flex gap="5px" alignItems="center">
                                    <BsStopwatch color={secondryText} />
                                    <Text fontSize="sm" color={bodyTextColor}>
                                      {_?.booking_services[0].hours
                                        ? `${_?.booking_services[0].hours} hr`
                                        : `${_?.booking_services[0].days} day`}
                                    </Text>
                                  </Flex>
                                  <Flex gap="5px" alignItems="center">
                                    <Icon
                                      as={FaCalendarAlt}
                                      mr={2}
                                      color={secondryText}
                                    />
                                    <Text color={bodyTextColor}>
                                      {_?.booking_date} {""}
                                      {moment(
                                        _?.booking_time,
                                        "HH:mm:ss"
                                      ).format("h:mm A")}
                                    </Text>
                                  </Flex>
                                </Flex>
                                <Flex
                                  alignItems="center"
                                  justifyContent="space-between"
                                  flexWrap="wrap"
                                  gap="5px"
                                >
                                  <Flex gap="5px" alignItems="center">
                                    <Icon
                                      as={FaUser}
                                      mr={2}
                                      color={secondryText}
                                    />
                                    <Text color={bodyTextColor}>
                                      Manpower: {_?.booking_services[0].qty}
                                    </Text>
                                  </Flex>
                                  <Flex gap="5px" alignItems="center">
                                    <Icon
                                      as={FaMapMarkerAlt}
                                      mr={2}
                                      color={secondryText}
                                    />
                                    <Text color={bodyTextColor}>
                                      {getFormattedAddress(_?.booking_address)}
                                    </Text>
                                  </Flex>
                                </Flex>
                                <Text color={bodyTextColor}></Text>
                              </Stack>
                              <Flex justifyContent="space-between">
                                {userType !== "company" && (
                                  <>
                                    {_?.booking_status === "job_in" ? (
                                      <Button
                                        bg={acitveColor}
                                        onClick={() => {
                                          onEndJob(_?.id);
                                        }}
                                      >
                                        End Job
                                      </Button>
                                    ) : (
                                      <Button
                                        bg={acitveColor}
                                        onClick={() => {
                                          onStartJob(_?.id);
                                        }}
                                      >
                                        Start Job
                                      </Button>
                                    )}
                                  </>
                                )}

                                {userType !== "company" && (
                                  <Button
                                    bg={acitveColor}
                                    onClick={() => onChat(_?.id, _)}
                                  >
                                    View Chat
                                  </Button>
                                )}
                                {/* <Button colorScheme="green">Accepted</Button> */}
                              </Flex>
                            </Box>
                          </Box>
                        );
                      })}
                    </>
                  )}
                </SimpleGrid>
              </TabPanel>
              {/* )} */}
              <TabPanel py="30px" px="0">
                <SimpleGrid
                  columns={{ base: 1, sm: 2, md: 3, xl: 4 }}
                  spacing={4}
                  className="bookingCardWr"
                >
                  {loading ? (
                    <SpinnerComponent />
                  ) : (
                    <>
                      {bookings?.map((_, index) => {
                        return (
                          <Box
                            key={index}
                            className="bookingCard"
                            pos="relative"
                          >
                            <Tag
                              size="md"
                              colorScheme="red"
                              borderRadius="full"
                              pos="absolute"
                              right="40%"
                              top="-11px"
                              background="#c7ef72"
                              color="#010101"
                              fontWeight="600"
                              letterSpacing="0.3px"
                            >
                              <TagLabel fontSize="11px"> JOB IN </TagLabel>
                            </Tag>
                            <Box
                              border="1px"
                              borderRadius="md"
                              p={4}
                              className="bookingCardInner"
                              borderColor={acitveBorder}
                              bg={lightBlue}
                              _hover={{
                                boxShadow:
                                  "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                              }}
                            >
                              <Flex
                                mb={4}
                                gap="10px"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Flex alignItems="center">
                                  <Image
                                    boxSize="50px"
                                    borderRadius="full"
                                    src={_?.booking_services[0]?.service_image} //{customer}
                                    alt="Customer Image"
                                    mr={4}
                                  />
                                  <Box flex="1">
                                    <Box>
                                      <Text
                                        fontWeight="bold"
                                        color={bodyTextColor}
                                      >
                                        {_?.booking_services[0]?.service_name}
                                      </Text>
                                      {/* <Rating
                                          total_rating={_?.business_rating}
                                          reviewsCount={_?.business_review}
                                        /> */}
                                    </Box>
                                  </Box>
                                </Flex>
                                <Flex
                                  className=""
                                  lineHeight="15px"
                                  fontWeight="700"
                                  color={redDark}
                                  mt="5px"
                                >
                                  <MdCurrencyRupee color="red" />
                                  <Text color="red">{_?.grand_amount}</Text>
                                </Flex>
                              </Flex>
                              <Stack spacing={2} mb={4}>
                                <Flex gap="5px" alignItems="center">
                                  <Text fontSize="sm" color={blackmedium}>
                                    <Text fontWeight="600" as="span">
                                      {" "}
                                      ID :
                                    </Text>{" "}
                                    <Text
                                      as="span"
                                      fontWeight="400"
                                      color={blackmedium}
                                    >
                                      {_?.order_no}
                                    </Text>
                                  </Text>
                                </Flex>
                                <Flex
                                  alignItems="center"
                                  justifyContent="space-between"
                                  flexWrap="wrap"
                                  gap="5px"
                                >
                                  <Flex gap="5px" alignItems="center">
                                    <BsStopwatch color={secondryText} />
                                    <Text fontSize="sm" color={bodyTextColor}>
                                      {_?.booking_services[0].hours} hr
                                    </Text>
                                  </Flex>
                                  <Flex gap="5px" alignItems="center">
                                    <Icon
                                      as={FaCalendarAlt}
                                      mr={2}
                                      color={secondryText}
                                    />
                                    <Text color={bodyTextColor}>
                                      {_?.booking_date} {""}
                                      {moment(
                                        _?.booking_time,
                                        "HH:mm:ss"
                                      ).format("h:mm A")}
                                    </Text>
                                  </Flex>
                                </Flex>
                                <Flex
                                  alignItems="center"
                                  justifyContent="space-between"
                                  flexWrap="wrap"
                                  gap="5px"
                                >
                                  <Flex gap="5px" alignItems="center">
                                    <Icon
                                      as={FaUser}
                                      mr={2}
                                      color={secondryText}
                                    />
                                    <Text color={bodyTextColor}>
                                      Manpower: {_?.booking_services[0].qty}
                                    </Text>
                                  </Flex>
                                  <Flex gap="5px" alignItems="center">
                                    <Icon
                                      as={FaMapMarkerAlt}
                                      mr={2}
                                      color={secondryText}
                                    />
                                    <Text color={bodyTextColor}>
                                      {getFormattedAddress(_?.booking_address)}
                                    </Text>
                                  </Flex>
                                </Flex>
                                <Text color={bodyTextColor}></Text>
                              </Stack>
                              <Flex justifyContent="space-between">
                                {userType !== "company" && (
                                  <>
                                    {_?.booking_status === "job_in" ? (
                                      <Button
                                        // bg={acitveColor}
                                        colorScheme="red"
                                        onClick={() => {
                                          onEndJob(_?.id);
                                        }}
                                      >
                                        Finish Job
                                      </Button>
                                    ) : (
                                      <Button
                                        bg={acitveColor}
                                        onClick={() => {
                                          onStartJob(_?.id);
                                        }}
                                      >
                                        Start Job
                                      </Button>
                                    )}
                                  </>
                                )}
                                {userType !== "company" && (
                                  <Button
                                    bg={acitveColor}
                                    onClick={() => onChat(_?.id, _)}
                                  >
                                    View Chat
                                  </Button>
                                )}

                                {/* <Button colorScheme="green">Accepted</Button> */}
                              </Flex>
                            </Box>
                          </Box>
                        );
                      })}
                    </>
                  )}
                </SimpleGrid>
              </TabPanel>
              <TabPanel py="30px" px="0">
                <SimpleGrid
                  columns={{ base: 1, sm: 2, md: 3, xl: 4 }}
                  spacing={4}
                  className="bookingCardWr"
                >
                  {loading ? (
                    <SpinnerComponent />
                  ) : (
                    <>
                      {bookings?.map((_, index) => {
                        return (
                          <Box
                            key={index}
                            className="bookingCard"
                            pos="relative"
                          >
                            <Tag
                              size="md"
                              colorScheme="red"
                              borderRadius="full"
                              pos="absolute"
                              right="33.5%"
                              top="-11px"
                              background="#c7ef72"
                              color="#010101"
                              fontWeight="600"
                              letterSpacing="0.3px"
                            >
                              <TagLabel fontSize="11px"> COMPLETED </TagLabel>
                            </Tag>
                            <Box
                              border="1px"
                              borderRadius="md"
                              p={4}
                              className="bookingCardInner"
                              borderColor={acitveBorder}
                              bg={lightBlue}
                              _hover={{
                                boxShadow:
                                  "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                              }}
                            >
                              <Flex
                                mb={4}
                                gap="10px"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Flex alignItems="center">
                                  <Image
                                    boxSize="50px"
                                    borderRadius="full"
                                    src={_?.booking_services[0]?.service_image} //{customer}
                                    alt="Customer Image"
                                    mr={4}
                                  />
                                  <Box flex="1">
                                    <Box>
                                      <Text
                                        fontWeight="bold"
                                        color={bodyTextColor}
                                      >
                                        {_?.booking_services[0]?.service_name}
                                      </Text>
                                      {/* <Rating
                                          total_rating={_?.business_rating}
                                          reviewsCount={_?.business_review}
                                        /> */}
                                    </Box>
                                  </Box>
                                </Flex>
                                <Flex
                                  className=""
                                  lineHeight="15px"
                                  fontWeight="700"
                                  color={redDark}
                                  mt="5px"
                                >
                                  <MdCurrencyRupee color="red" />
                                  <Text color="red">{_?.grand_amount}</Text>
                                </Flex>
                              </Flex>
                              <Stack spacing={2} mb={4}>
                                <Flex gap="5px" alignItems="center">
                                  <Text fontSize="sm" color={blackmedium}>
                                    <Text fontWeight="600" as="span">
                                      {" "}
                                      ID :
                                    </Text>{" "}
                                    <Text
                                      as="span"
                                      fontWeight="400"
                                      color={blackmedium}
                                    >
                                      {_?.order_no}
                                    </Text>
                                  </Text>
                                </Flex>
                                <Flex
                                  alignItems="center"
                                  justifyContent="space-between"
                                  flexWrap="wrap"
                                  gap="5px"
                                >
                                  <Flex gap="5px" alignItems="center">
                                    <BsStopwatch color={secondryText} />
                                    <Text fontSize="sm" color={bodyTextColor}>
                                      {_?.booking_services[0]?.days > 0 ? (
                                        <>
                                          {_?.booking_services[0]?.days} days{" "}
                                        </>
                                      ) : (
                                        <> {_?.booking_services[0]?.hours} hr</>
                                      )}
                                    </Text>
                                  </Flex>
                                  <Flex gap="5px" alignItems="center">
                                    <Icon
                                      as={FaCalendarAlt}
                                      mr={2}
                                      color={secondryText}
                                    />
                                    <Text color={bodyTextColor}>
                                      {" "}
                                      {_?.booking_date}
                                    </Text>
                                  </Flex>
                                </Flex>
                                <Flex
                                  alignItems="center"
                                  justifyContent="space-between"
                                  flexWrap="wrap"
                                  gap="5px"
                                >
                                  <Flex gap="5px" alignItems="center">
                                    <Icon
                                      as={FaUser}
                                      mr={2}
                                      color={secondryText}
                                    />
                                    <Text color={bodyTextColor}>
                                      Manpower: {_?.booking_services[0].qty}
                                    </Text>
                                  </Flex>
                                  <Flex gap="5px" alignItems="center">
                                    <Icon
                                      as={FaMapMarkerAlt}
                                      mr={2}
                                      color={secondryText}
                                    />
                                    <Text color={bodyTextColor}>
                                      {getFormattedAddress(_?.booking_address)}
                                    </Text>
                                  </Flex>
                                </Flex>
                              </Stack>
                              <Flex justifyContent="space-between">
                                {userType !== "company" && (
                                  <Button
                                    bg={acitveColor}
                                    onClick={() => onChat(_?.id, _)}
                                  >
                                    View Chat
                                  </Button>
                                )}
                                <Button disabled>Completed</Button>
                              </Flex>
                            </Box>
                          </Box>
                        );
                      })}
                    </>
                  )}
                </SimpleGrid>
              </TabPanel>
            </TabPanels>
          </Tabs>

          {/* First Modal */}
          <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Booking Details</ModalHeader>
              <ModalCloseButton />
              {error !== null ? (
                <ModalBody>
                  <Box p={4} className="bookingHistoryPage">
                    <Text color="red.500" fontSize="lg" textAlign="center">
                      {/* {error} */}
                    </Text>
                  </Box>
                </ModalBody>
              ) : (
                <ModalBody>
                  <Image
                    src={bookingBetails?.booking_services[0]?.service_image}
                    m="0 auto"
                    height="100px"
                  ></Image>
                  <Box>
                    <Heading
                      as="h6"
                      fontSize="20px"
                      textAlign="center"
                      mt="10px"
                      mb="15px"
                    >
                      {bookingBetails?.user_name}
                    </Heading>
                  </Box>
                  <Flex
                    justifyContent="space-between"
                    borderBottom="1px solid #e99c0c42"
                    pb="5px"
                    alignItems="center"
                    mb="10px"
                  >
                    <Flex alignItems="center" gap="5px">
                      <FaMapMarkerAlt size="22" />
                      <Text>
                        {" "}
                        {bookingBetails?.booking_address &&
                          formatAddress(bookingBetails?.booking_address)}
                      </Text>
                    </Flex>
                    <FaMapLocationDot size="22" />
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    borderBottom="1px solid #e99c0c42"
                    pb="5px"
                    mb="10px"
                  >
                    <Text fontWeight="600">Booking Id</Text>
                    <Text color="#a6a6a6">{bookingBetails?.order_no}</Text>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    borderBottom="1px solid #e99c0c42"
                    pb="5px"
                    mb="10px"
                  >
                    <Text fontWeight="600">Date</Text>
                    <Text color="#a6a6a6">{bookingBetails?.booking_date}</Text>

                    {moment(bookingBetails?.booking_time, "HH:mm:ss").format(
                      "h:mm A"
                    )}
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    borderBottom="1px solid #e99c0c42"
                    pb="5px"
                    mb="10px"
                  >
                    <Text fontWeight="600">
                      {" "}
                      {bookingBetails?.booking_services[0]?.service_name}
                    </Text>
                    <Text color="#a6a6a6">
                      Qty : {bookingBetails?.booking_services[0].qty}
                    </Text>
                  </Flex>

                  {bookingBetails?.booking_services[0].days !== 0 ? (
                    <Flex
                      justifyContent="space-between"
                      borderBottom="1px solid #e99c0c42"
                      pb="5px"
                      mb="10px"
                    >
                      <Text fontWeight="600">No. of days</Text>
                      <Flex alignItems="center" color="#a6a6a6">
                        {bookingBetails?.booking_services[0].days}
                      </Flex>
                    </Flex>
                  ) : (
                    <Flex
                      justifyContent="space-between"
                      borderBottom="1px solid #e99c0c42"
                      pb="5px"
                      mb="10px"
                    >
                      <Text fontWeight="600">No. of hours </Text>
                      <Flex alignItems="center" color="#a6a6a6">
                        {" "}
                        {bookingBetails?.booking_services[0].hours} Hr
                      </Flex>
                    </Flex>
                  )}

                  <Flex
                    justifyContent="space-between"
                    borderBottom="1px solid #e99c0c42"
                    pb="5px"
                    mb="10px"
                  >
                    <Text fontWeight="600">Per Hour </Text>
                    <Flex alignItems="center" color="#a6a6a6">
                      {" "}
                      <MdCurrencyRupee />
                      {bookingBetails?.overtime_price}
                    </Flex>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    borderBottom="1px solid #e99c0c42"
                    pb="5px"
                    mb="10px"
                  >
                    <Text fontWeight="600" fontSize="20px">
                      Grand Total
                    </Text>
                    <Flex
                      alignItems="center"
                      fontWeight="600"
                      fontSize="20px"
                      color="red"
                    >
                      {" "}
                      <MdCurrencyRupee color="red" />
                      Rs {bookingBetails?.grand_amount}
                    </Flex>
                  </Flex>
                  {userType === "company" && (
                    <>
                      <Heading as="h6" fontSize="22px" mt="25px" mb="20px">
                        Assign Job To
                      </Heading>
                      <VStack align="start" spacing={4}>
                        {employeeList?.map((client) => (
                          <HStack
                            key={client.id}
                            p={4}
                            borderWidth={1}
                            borderRadius="md"
                            width="100%"
                            justifyContent="space-between"
                          >
                            <HStack>
                              <Image
                                src={client?.image}
                                alt={client?.name}
                                boxSize="50px"
                                borderRadius="full"
                              />
                              <Box>
                                <Text fontWeight="bold">{client.name}</Text>
                                <Text fontSize="sm">{client.id}</Text>
                              </Box>
                            </HStack>
                            <Checkbox
                              isChecked={checkedClients.includes(client.id)}
                              onChange={() => handleCheckboxChange(client.id)}
                            />
                          </HStack>
                        ))}
                      </VStack>
                      {errorSelectEmployee && (
                        <Text color="red">{errorSelectEmployee}</Text>
                      )}
                    </>
                  )}
                </ModalBody>
              )}
              <ModalFooter gap="10px">
                <Button
                  colorScheme="green"
                  // onClick={handleAcceptClick}
                  flex="1"
                  loading={isSubmitting}
                  onClick={() => {
                    onAcceptAndReject("accept", bookingBetails?.id);
                  }}
                >
                  Accept
                </Button>
                <Button
                  colorScheme="red"
                  // onClick={onClose}
                  loading={isSubmitting}
                  onClick={() => {
                    onAcceptAndReject("rejected", bookingBetails?.id);
                  }}
                  flex="1"
                >
                  Decline
                </Button>
              </ModalFooter>
              <Text color="red">{bookingStatusMsg}</Text>
            </ModalContent>
          </Modal>

          {/* Second Modal */}
          <Modal isOpen={isSecondModalOpen} onClose={onSecondModalClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <Button variant="link" onClick={handleBackClick}>
                  <Icon as={FaArrowLeft} />
                </Button>
                <Text as="span" fontSize="18px">
                  More Details
                </Text>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Image src={provider} m="0 auto"></Image>
                <Box>
                  <Heading
                    as="h6"
                    fontSize="20px"
                    textAlign="center"
                    mt="10px"
                    mb="15px"
                  >
                    John Doe
                  </Heading>
                </Box>
                <Flex
                  justifyContent="space-between"
                  pb="5px"
                  alignItems="center"
                  mb="10px"
                >
                  <Flex alignItems="center" gap="5px">
                    <FaMapMarkerAlt size="22" />
                    <Text>Sec 14 Gurgaon</Text>
                  </Flex>
                  <FaMapLocationDot size="22" />
                </Flex>
                <Box pb="15px">
                  <Text fontWeight="600">Instructions</Text>
                  <Text color="#a6a6a6">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Duis nec mi nec mi feugiat tincidunt fringilla nec nisi. Nam
                    vitae mi id dolor dignissim condimentum. Vestibulum a tortor
                    eu ex gravida elementum.
                  </Text>
                </Box>
                <Box mb="20px" p="5px" bg="#0000000d">
                  <Flex justifyContent="space-between" pb="5px">
                    <Text fontWeight="600">Booking Id</Text>
                    <Text color="#a6a6a6">#56566464646</Text>
                  </Flex>
                  <Flex justifyContent="space-between" pb="5px">
                    <Text fontWeight="600">Date</Text>
                    <Text color="#a6a6a6">25-05-2024</Text>
                  </Flex>
                </Box>
                <Box>
                  <Text fontWeight="700" pb="5px" color={darkYellow}>
                    Service
                  </Text>
                  <Box mb="20px" p="5px" bg="#0000000d">
                    <Flex justifyContent="space-between" pb="5px">
                      <Text fontWeight="600">Body Guard</Text>
                      <Text fontWeight="600">
                        Qty : {bookingBetails?.booking_services[0].qty}
                      </Text>
                    </Flex>
                    <Flex justifyContent="space-between" pb="5px">
                      <Text fontWeight="600" fontSize="12px">
                        No. of days : 4
                      </Text>
                      <Flex
                        alignItems="center"
                        fontWeight="600"
                        fontSize="12px"
                      >
                        {" "}
                        <MdCurrencyRupee />
                        Per Hour : 4000
                      </Flex>
                    </Flex>
                  </Box>
                </Box>
                <Box mb="20px" p="5px" bg="#0000000d">
                  <Flex justifyContent="space-between" pb="5px">
                    <Text fontWeight="600">Security</Text>
                    <Text fontWeight="600">
                      Qty : {bookingBetails?.booking_services[0].qty}
                    </Text>
                  </Flex>
                  <Flex justifyContent="space-between" pb="5px">
                    <Text fontWeight="600" fontSize="12px">
                      Qty : {bookingBetails?.booking_services[0].qty}
                    </Text>
                    <Flex alignItems="center" fontWeight="600" fontSize="12px">
                      {" "}
                      <MdCurrencyRupee />
                      Per Hour : 4000
                    </Flex>
                  </Flex>
                </Box>
                <Box>
                  <Text fontWeight="700" pb="5px" color={darkYellow}>
                    Amount
                  </Text>
                </Box>
                <Box mb="20px" p="5px" bg="#0000000d">
                  <Flex justifyContent="space-between" pb="5px">
                    <Text fontWeight="600">Total Amount</Text>
                    <Text fontWeight="600">Rs 16000</Text>
                  </Flex>
                  <Flex justifyContent="space-between" pb="5px">
                    <Text fontWeight="600">Tax</Text>
                    <Flex alignItems="center" fontWeight="600" color={redDark}>
                      {" "}
                      <MdCurrencyRupee color={secondryText} />
                      8% (1280)
                    </Flex>
                  </Flex>
                </Box>
                <Flex
                  justifyContent="space-between"
                  borderBottom="1px solid #e99c0c42"
                  pb="5px"
                  mb="10px"
                >
                  <Text fontWeight="600" fontSize="20px">
                    Grand Total
                  </Text>
                  <Flex alignItems="center" fontWeight="600" fontSize="20px">
                    {" "}
                    <MdCurrencyRupee color={secondryText} />
                    Rs 17280
                  </Flex>
                </Flex>
              </ModalBody>
              <ModalFooter>
                <Button
                  colorScheme="green"
                  onClick={handleStartNowClick}
                  flex="1"
                >
                  Start Now
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* Third Modal === Start Job*/}
          <Modal isOpen={isThirdModalOpen} onClose={onThirdModalClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <Button variant="link" onClick={handleBackClick}>
                  <Icon as={FaArrowLeft} />
                </Button>
                <Text as="span" fontSize="18px">
                  OTP Verification
                </Text>
              </ModalHeader>
              <ModalCloseButton />

              <ModalBody>
                <Text mb="5px">Enter your OTP here.</Text>
                <HStack>
                  <PinInput otp onChange={handleOtpChange}>
                    <PinInputField flex="1" />
                    <PinInputField flex="1" />
                    <PinInputField flex="1" />
                    <PinInputField flex="1" />
                  </PinInput>
                </HStack>
                {!endJobId && (
                  <Box p="4">
                    {photo ? (
                      <Box mt="4">
                        <Button
                          mt="2"
                          onClick={() => setPhoto(null)}
                          leftIcon={<CloseIcon />}
                          colorScheme="red"
                        ></Button>{" "}
                        <Image
                          src={photo}
                          alt="Captured"
                          maxW="100%"
                          borderRadius="md"
                        />
                      </Box>
                    ) : (
                      <div>
                        <Text mb="4">Capture a photo using the webcam:</Text>
                        <Webcam
                          audio={false}
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          width="100%"
                          height="auto"
                          videoConstraints={{ facingMode: "environment" }} // Use the back camera on mobile devices
                        />
                        <Button mt="4" colorScheme="blue" onClick={capture}>
                          Capture Photo
                        </Button>
                      </div>
                    )}
                  </Box>
                )}
              </ModalBody>
              <ModalFooter>
                {!endJobId ? (
                  <Button
                    colorScheme="green"
                    flex="1"
                    isLoading={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Start Now
                  </Button>
                ) : (
                  <Button
                    colorScheme="green"
                    flex="1"
                    isLoading={isSubmitting}
                    onClick={handleEndJob}
                  >
                    End Now
                  </Button>
                )}
              </ModalFooter>
              <ModalFooter>
                {success && (
                  <Box mt="4">
                    <Text mb="2">{success}</Text>
                  </Box>
                )}

                {errorJob && (
                  <Box mt="4">
                    <Text mb="2" color="red">
                      {errorJob}
                    </Text>
                  </Box>
                )}
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Modal isOpen={isReasonOpen} onClose={() => setIsReasonOpen(false)}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <Button
                  variant="link"
                  onClick={() => {
                    /* Handle back action */
                  }}
                >
                  <Icon as={FaArrowLeft} />
                </Button>
                <Text as="span" fontSize="18px">
                  Reason
                </Text>
              </ModalHeader>
              <ModalCloseButton />
              <Formik
                initialValues={{ reason: "" }}
                validationSchema={validationSchema}
                onSubmit={(values) => handleSubmitReject(values)}
              >
                {({ isSubmitting, errors, touched }) => (
                  <Form>
                    <ModalBody>
                      <FormControl isInvalid={touched.reason && errors.reason}>
                        <Text mb="5px">
                          Enter Reason for rejecting the booking
                        </Text>
                        <Field
                          as={Input}
                          name="reason"
                          placeholder="Enter your reason"
                        />
                        <FormErrorMessage>{errors.reason}</FormErrorMessage>
                      </FormControl>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        colorScheme="green"
                        type="submit"
                        isLoading={isSubmitting}
                        flex="1"
                      >
                        Submit
                      </Button>
                    </ModalFooter>
                  </Form>
                )}
              </Formik>
            </ModalContent>
          </Modal>
        </Box>
        {error !== null && <NodataFound message={error} />}
      </Container>
    </Box>
  );
};

export default BookingHistory;
